import { actionTypes } from "./actions";
import { StateSchema } from "./schema";
import { mobileAuthSteps } from "../../helpers/mobileAuth";

/**
 * reducer
 **/
export default (state = { ...StateSchema }, action: Action) => {
  const { type, payload = {} } = action;
  switch (type) {
    case actionTypes.READY: {
      const { frontendIsReadyStatus } = payload;
      return {
        ...state,
        frontendIsReadyStatus,
      };
    }
    case actionTypes.AUTH_START: {
      const { username } = payload;
      return {
        ...state,
        authInProgress: true,
        username,
        authErrors: "",
      };
    }
    case actionTypes.AUTH_COMPLETE: {
      const { TOKEN } = action.payload.data;
      return {
        ...state,
        authInProgress: false,
        authorized: true,
        token: TOKEN,
      };
    }
    case actionTypes.AUTH_ERROR: {
      const {
        payload: { message = "" },
      } = action;
      return {
        ...state,
        authInProgress: false,
        authErrors: message,
      };
    }
    case actionTypes.LOG_OUT: {
      return {
        ...state,
        authorized: false,
        authInProgress: false,
        authError: "",
        username: "",
      };
    }
    case actionTypes.UPDATE_USERNAME: {
      const { username } = payload;
      return {
        ...state,
        username,
      };
    }
    case actionTypes.SET_TYPE_AUTH: {
      const { typeAuthStore } = payload;
      return {
        ...state,
        typeAuthStore,
      };
    }

    case actionTypes.SET_MOBILE_NUMBER: {
      const { mobileNumber } = payload;
      return {
        ...state,
        mobileNumber,
      };
    }

    case actionTypes.SET_UNIQUE_DEVICE_ID: {
      const { uniqueDeviceIdStore } = payload;
      return {
        ...state,
        uniqueDeviceIdStore,
      };
    }

    case actionTypes.OPEN_MOBILE_CODE_MODAL: {
      const { mobileAuthCodeStatusStore } = payload;
      return {
        ...state,
        mobileAuthCodeStatusStore,
      };
    }

    case actionTypes.SET_CURRENT_STEP: {
      const { currentStepStore } = payload;
      return {
        ...state,
        currentStepStore,
      };
    }

    case actionTypes.SET_USER_MOBILE_ADDRESSES_OPTIONS: {
      const { userMobileAddressesOptions } = payload;
      return {
        ...state,
        userMobileAddressesOptions,
      };
    }

    case actionTypes.SET_USER_MOBILE_ADDRESS: {
      const { userMobileAddress } = payload;
      return {
        ...state,
        userMobileAddress,
      };
    }

    case actionTypes.SET_AUTH_ID: {
      const { authId } = payload;
      return {
        ...state,
        authId,
      };
    }

    case actionTypes.SET_MOBILE_AUTH_CODE_REQUEST: {
      const { isInProgress } = payload;
      return {
        ...state,
        mobileAuthCodeRequestInProgress: isInProgress,
      };
    }

    case actionTypes.SET_MOBILE_AUTH_CODE_REQUEST_TIMEOUT_ERROR: {
      const { timeoutError } = payload;
      return {
        ...state,
        mobileAuthCodeRequestTimeoutError: timeoutError,
      };
    }

    case actionTypes.REOPEN_MOBILE_AUTH_CODE_FORM: {
      return {
        ...state,
        mobileAuthCodeStatusStore: true,
        currentStepStore: mobileAuthSteps.codeStep,
      };
    }

    default:
      return state;
  }
};
