import React, { useEffect, useState } from "react";

import {
  StyledReactModal,
  GlobalStyle,
  CloseBtn,
  Header,
  StyledHeading,
  Content,
} from "./styled-components";

import { getScrollbarWidth } from "../../../helpers/utilsFunc";

const Modal = ({
  children: childrenProps,
  title: titleProps,
  closeable: closeableProps,
  onClose: onCloseProps,
  zIndex: zIndexProps,
  ...props
}: ModalProps) => {
  const [widthScrollState, setWidthScrollState] = useState<string>("0px");

  useEffect(() => {
    if (props.isOpen) {
      const width = getScrollbarWidth();
      setWidthScrollState(`${width}px`);
    }
  }, [props.isOpen]);

  return (
    <StyledReactModal
      className="cameras-list__modal"
      contentLabel={titleProps || "Modal"}
      onRequestClose={onCloseProps}
      hasHeader={titleProps}
      zIndex={zIndexProps}
      {...props}
    >
      {/*@ts-ignore*/}
      <GlobalStyle widthScroll={widthScrollState} />
      {closeableProps && <CloseBtn onClick={onCloseProps}>×</CloseBtn>}
      {titleProps && (
        <Header>
          {/* @ts-ignore */}
          <StyledHeading level={2}>{titleProps}</StyledHeading>
        </Header>
      )}
      <Content>{childrenProps}</Content>
    </StyledReactModal>
  );
};

Modal.displayName = "Modal";
export default Modal;
