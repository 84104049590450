import apiMethods from ".";

import { call, race, delay } from "redux-saga/effects";

import sendErrorToSentry from "../sentry/index";
import { requestForSagaWorkerProps, WrapperIterator } from "./types";

//@todo убрать после переписывания api на ts
const apiMethodsTs: any = apiMethods;
const DEFAULT_TIMEOUT = 20000;

export const requestForSagaWorker = function*({
  requestRouteName,
  requestProps,
  requestTimeout,
}: requestForSagaWorkerProps): IterableIterator<WrapperIterator> {
  try {
    //@ts-ignore
    const { response, timeout } = yield race({
      response: call([apiMethodsTs, requestRouteName], requestProps),
      timeout: delay(requestTimeout || DEFAULT_TIMEOUT),
    });

    if (timeout) {
      throw new Error(
        `Request ${requestRouteName} timeout. More ${requestTimeout ||
          DEFAULT_TIMEOUT}`
      );
    }
    return {
      ...response,
    };
  } catch (e) {
    sendErrorToSentry(
      `requestForSagaWorker Catch. Method ${requestRouteName} failed with ${e}. RequestProps = ${JSON.stringify(
        requestProps
      )}`,
      {
        tags: {
          requestRouteName: requestRouteName,
        },
      }
    );

    let errorMsg;

    try {
      errorMsg = yield e.response.json();
    } catch (error) {
      sendErrorToSentry("Не удалось json разпарсить", null, true);
    }

    return {
      failed: true,
      error: e,
      errorMsg,
    };
  }
};
