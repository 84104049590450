import { createStore, applyMiddleware, compose } from "redux";
import { routerMiddleware } from "connected-react-router";
import createSagaMiddleware from "redux-saga";
import throttle from "lodash/throttle";
import * as Sentry from "@sentry/browser";
import createSentryMiddleware from "redux-sentry-middleware";

import history from "../helpers/history";
import rootReducer from "../reducers";

import rootSaga from "../sagas";
import { saveState, loadState } from "./persist";
import sendErrorToSentry from "../helpers/sentry";

//Подключение Sentry
Sentry.init({ dsn: process.env.REACT_APP_SENTRY_DSN });

const sagaMiddleware = createSagaMiddleware();
const initialState = loadState();
const enhancers = [];
const middleware = [sagaMiddleware, routerMiddleware(history)];

if (process.env.NODE_ENV === "development") {
  const devToolsExtension =
    window && (window as any).__REDUX_DEVTOOLS_EXTENSION__;
  if (typeof devToolsExtension === "function") {
    enhancers.push(devToolsExtension());
  }
}

//Подключение Sentry. бработчик не пойманных ошибок для saga в проде
if (process.env.NODE_ENV === "production") {
  const sentryReduxMiddleware = createSentryMiddleware(Sentry, {
    //@ts-ignore
    onError(err) {
      Sentry.captureException(err);
      sendErrorToSentry(`Global Saga error handler err = ${err}`);
    },
  });

  middleware.push(sentryReduxMiddleware);
}
/**
 * Обработчик не пойманных ошибок для saga
 */
if (process.env.NODE_ENV === "development") {
  const sentryReduxMiddleware = createSentryMiddleware(Sentry, {
    //@ts-ignore
    onError(err) {
      sendErrorToSentry(`Global Saga error handler err = ${err}`);
    },
  });

  middleware.push(sentryReduxMiddleware);
}

const composedEnhancers = compose(applyMiddleware(...middleware), ...enhancers);

const store = createStore(rootReducer, initialState, composedEnhancers);
sagaMiddleware.run(rootSaga);

store.subscribe(
  throttle(() => {
    saveState(store.getState());
  }, 1000)
);
export default store;

export type TRootState = ReturnType<typeof store.getState>;
