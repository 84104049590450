/**
 * Установить значение  hasSeenRedirectBannerVal в SessionStorage
 * @param {boolean} hasSeenRedirectBannerVal
 * @returns
 */
export const setHasSeenRedirectBannerValToSessionStorage = (
  hasSeenRedirectBannerVal: boolean
) => {
  return sessionStorage.setItem(
    "showRedirectBanner",
    `${hasSeenRedirectBannerVal}`
  );
};

/**
 * Записать выбор пользователя "Больше не показывать" на окне с предупреждение об удалении камер
 * @param {boolean}
 * @returns
 */
export const setDontShowModalAgainToSessionStorage = (
  dontShowModalAgain: boolean
) => {
  return sessionStorage.setItem("dontShowModalAgain", `${dontShowModalAgain}`);
};
