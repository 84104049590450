import { moduleName } from "./module";

const appName = process.env.REACT_APP_NAME;

export const actionTypes = {
  INIT_LIST_START: `${appName}/${moduleName}/INIT_LIST_START`,
  INIT_LIST_COMPLETE: `${appName}/${moduleName}/INIT_LIST_COMPLETE`,
  GET_GROUP_START: `${appName}/${moduleName}/GET_GROUP_START`,
  GET_GROUP_COMPLETE: `${appName}/${moduleName}/GET_GROUP_COMPLETE`,
  GET_GROUP_ERROR: `${appName}/${moduleName}/GET_GROUP_ERROR`,
  UPDATE_LIST: `${appName}/${moduleName}/UPDATE_LIST`,
  UPDATE_OBJECTS: `${appName}/${moduleName}/UPDATE_OBJECTS`,
  SET_ACTIVE_OBJECT_ID_START: `${appName}/${moduleName}/SET_ACTIVE_OBJECT_ID_START`,
  SET_ACTIVE_OBJECT_ID_COMPLETE: `${appName}/${moduleName}/SET_ACTIVE_OBJECT_ID_COMPLETE`,
  SELECT_TOGGLE_START: `${appName}/${moduleName}/SELECT_TOGGLE_START`,
  SELECT_TOGGLE_COMPLETE: `${appName}/${moduleName}/SELECT_TOGGLE_COMPLETE`,
  SET_SELECTED_OBJECTS: `${appName}/${moduleName}/SET_SELECTED_OBJECTS`,
  SET_SELECTED_OBJECTS_FILTER: `${appName}/${moduleName}/SET_SELECTED_OBJECTS_FILTER`,
  SET_FULL_SELECTED_GROUPS: `${appName}/${moduleName}/SET_FULL_SELECTED_GROUPS`,
  GET_OBJECT_START: `${appName}/${moduleName}/GET_OBJECT_START`,
  GET_OBJECT_COMPLETE: `${appName}/${moduleName}/GET_GROUP_COMPLETE`,
  GET_OBJECT_ERROR: `${appName}/${moduleName}/GET_GROUP_ERROR`,
  SET_ROOT_IS_LOADED: `${appName}/${moduleName}/SET_ROOT_IS_LOADED`,
  GET_LIMITED_INFO_START: `${appName}/${moduleName}/GET_LIMITED_INFO_START`,
  GET_LIMITED_INFO_FINISH: `${appName}/${moduleName}/GET_LIMITED_INFO_FINISH`,
  GET_LIMITED_INFO_ERROR: `${appName}/${moduleName}/GET_LIMITED_INFO_ERROR`,
  SAVE_CURRENT_GROUP_TO_LS: `${appName}/${moduleName}/SAVE_CURRENT_GROUP_TO_LS`,
  SET_CAMERAS_LIST_UPDATE_IN_PROGRESS: `${appName}/${moduleName}/SET_CAMERAS_LIST_UPDATE_IN_PROGRESS`,
};

/**
 * Получить  информацию об определенных камерах
 * @param {array} loadingLimitedIds
 * @param {array} loadingLimitedIdsForSelect
 *
 */
export const getLimitedInfoStartAction = (
  loadingLimitedIds: Array<any>,
  loadingLimitedIdsForSelect: Array<any>,
  logoutActon: boolean = false
) => ({
  type: actionTypes.GET_LIMITED_INFO_START,
  payload: {
    loadingLimitedIds,
    loadingLimitedIdsForSelect,
    logoutActon,
  },
});

/**
 * Вызвать допобработку при получении  информации об определенных камерах
 * @param {array} cameras
 *
 */
export const getLimitedInfoFinishAction = (cameras: any) => ({
  type: actionTypes.GET_LIMITED_INFO_FINISH,
  payload: {
    cameras,
  },
});

/**
 * Вызвать ошибку при запросе информации об определенных камерах
 * @param {array} failedLimitedIs
 */
export const getLimitedInfoErrorAction = (failedLimitedIs: Array<[]>) => ({
  type: actionTypes.GET_LIMITED_INFO_ERROR,
  payload: {
    failedLimitedIs,
  },
});

/**
 * Получить содержимое группы с сервера
 * @param {string} groupId - ID города
 * @param {string | null} parentId - Специальный ID склееный с родителем
 *
 * у parentObjectId -  если null это прям корень ?
 */
export const getGroupAction = (
  groupId: string,
  parentId: null | string = null
) => ({
  type: actionTypes.GET_GROUP_START,
  payload: {
    groupId,
    parentId,
  },
});

/**
 * Получить содержимое группы с сервера
 * @param Args
 */
export const groupLoadCompleteAction = (Args: groupLoadCompleteActionType) => {
  const { data, groupId, parentObjectId } = Args;
  return {
    type: actionTypes.GET_GROUP_COMPLETE,
    payload: {
      data,
      groupId,
      parentObjectId,
    },
  };
};

/**
 * Вызвать ошибку при запросе информации об содержимом группы
 * @param {string} message
 * @param {string} groupId
 * @param {string} parentObjectId
 */
export const getGroupErrorAction = (
  message: string,
  groupId: string,
  parentObjectId: string
) => ({
  type: actionTypes.GET_GROUP_ERROR,
  payload: {
    message,
    groupId,
    parentObjectId,
  },
});

/**
 * Обновить текущий список полностью выбранных групп
 * @param {array} fullSelectedGroups
 */
export const updateFullSelectedGroupsAction = (
  fullSelectedGroups: fullSelectedGroup[]
) => ({
  type: actionTypes.SET_FULL_SELECTED_GROUPS,
  payload: {
    fullSelectedGroups,
  },
});

/**
 * Обновить текущий список камер
 * @param {array} data
 */
export const updateListAction = (data: object) => ({
  type: actionTypes.UPDATE_LIST,
  payload: {
    data,
  },
});

/**
 * Установить текущий активный элемент
 * @param {string} id
 */
export const setActiveObjectIdAction = (id: string, init: boolean = false) => {
  return {
    type: actionTypes.SET_ACTIVE_OBJECT_ID_START,
    payload: {
      id,
      init,
    },
  };
};

/**
 * Занести в store текущий активный элемент
 * @param {string} id
 */
export const setActiveObjectIdCompleteAction = (id: string) => {
  return {
    type: actionTypes.SET_ACTIVE_OBJECT_ID_COMPLETE,
    payload: {
      id,
    },
  };
};

/**
 * Инициализация списка камер
 */
export const initListAction = () => ({
  type: actionTypes.INIT_LIST_START,
});

/**
 * Допобработка при Инициализации списка камер
 */
export const initListCompleteAction = () => ({
  type: actionTypes.INIT_LIST_COMPLETE,
});

/**
 * Перейти в корень всплывающего меню
 * @param {boolean} rootIsLoaded
 */
export const setRootIsLoadedAction = (rootIsLoaded: boolean) => ({
  type: actionTypes.SET_ROOT_IS_LOADED,
  payload: {
    rootIsLoaded,
  },
});
