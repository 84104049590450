import { withRouter } from "react-router";
import { connect } from "react-redux";
import { Dispatch } from "redux";

import { initStartAction } from "@modules/init";
import { inProgressSelector, completeSelector } from "@modules/init";
import { TRootState } from "@store/store";

import AppView from "@views/App";

const mapDispatchToProps = (dispatch: Dispatch) => ({
  initStart: () => {
    dispatch(initStartAction());
  },
});

const mapStateToProps = (state: TRootState) => ({
  initInProgress: inProgressSelector(state),
  initComplete: completeSelector(state),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AppView)
);
