import React from "react";

import "../../../assets/css/rc-dropdown/index.css";

import {
  MenuStyled,
  MenuLinkStyled,
  MenuExitBtnStyled,
  BtnExitUsernameTextStyled,
  BtnExitBtnTextStyled,
} from "./styled-components";

import {
  ymSendAnalytics,
  yandexEvents,
} from "../../../helpers/yandex-analytics";

const HeaderMenu = (props: HeaderMenuProps) => {
  const {
    authorized: authorizedProps,
    username: usernameProps,
    authInProgress: authInProgressProps,
    logOut: logOutProps,
  } = props;

  /**
   * Обработка нажатия кнопка выйти
   */
  const logoutHandlerClick = () => {
    ymSendAnalytics(yandexEvents.clickLogOut);
    logOutProps();
  };

  return (
    <MenuStyled className="component-header-menu">
      {!authorizedProps && !authInProgressProps && (
        <MenuLinkStyled
          onClick={() => ymSendAnalytics(yandexEvents.clickSignInBtn)}
          to="/authorization"
          className="btn-login"
        >
          Войти
        </MenuLinkStyled>
      )}
      {authorizedProps && (
        <MenuExitBtnStyled className="btn-exit">
          <BtnExitUsernameTextStyled className="btn-exit__username-text">
            ({usernameProps ? usernameProps : "Пользователь"}){" "}
          </BtnExitUsernameTextStyled>
          <BtnExitBtnTextStyled
            className="btn-exit__btn-text"
            onClick={() => logoutHandlerClick()}
          >
            Выйти
          </BtnExitBtnTextStyled>
        </MenuExitBtnStyled>
      )}
    </MenuStyled>
  );
};

HeaderMenu.displayName = "HeaderMenu";
export default HeaderMenu;
