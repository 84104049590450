import apiMethods from "../api/apiMethods";
import { CameraData } from "@views/Archive/types";

/**
 * Отправить запрос на загрузку видео
 * @param data
 */
export const orderVideo = async (data: downloadVideoData): Promise<boolean> => {
  return await apiMethods.orderVideoForDownload(data);
};

const timer = (ms: number) => new Promise(res => setTimeout(res, ms));

/**
 * Проверить загруженное видео
 * @param data
 */
export const checkAndGetVideo = async (
  data: ICheckAndGetVideoArgs
): Promise<any> => {
  while (data.numberOfTries > 0) {
    const result = await apiMethods.getDownloadLink(data.query);
    const { READY, LINK, error } = result;
    if (result.hasOwnProperty("READY") && READY === true) {
      if (error && !LINK) {
        return { downloadLink: undefined, errorMsg: error };
      } else if (error && LINK) {
        return { downloadLink: LINK, errorMsg: error };
      } else if (typeof LINK === "string") {
        return { downloadLink: LINK, errorMsg: undefined };
      }
    } else if (error) {
      console.error("Video not ready yet");
      return { downloadLink: LINK, errorMsg: error };
    }
    await timer(data.timeoutInMs);
    data.numberOfTries--;
  }
};

/**
 * Форматировать время и вернуть в виде ${dd}.${mm}.${yyyy}
 * @param date
 */
export const formateDate = (date: Date): string => {
  const dd = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
  const mm =
    date.getMonth() + 1 < 10
      ? "0" + (date.getMonth() + 1)
      : date.getMonth() + 1;
  const yyyy = date.getFullYear();
  return `${dd}.${mm}.${yyyy}`;
};

/**
 * Форматировать время и вернуть в виде ${h}:${m}
 * @param dateForFormate
 */
export const formateDateTimeForUrl = (dateForFormate: Date): string => {
  const h =
    dateForFormate.getHours() > 9
      ? dateForFormate.getHours()
      : "0" + dateForFormate.getHours();
  const m =
    dateForFormate.getMinutes() > 9
      ? dateForFormate.getMinutes()
      : "0" + dateForFormate.getMinutes();
  return `${h}:${m}`;
};

/**
 * Сгенерировать ссылку для получения плейлиста типа m3u8 для hls плеера
 * @param cameraData
 * @param {number} startDate
 */
export const generateLoadUrl = (
  cameraData: CameraData,
  startDate: number
): string => {
  return `${cameraData.MEDIA.HLS.ARCHIVE}&start-date=${new Date(
    startDate
  ).toISOString()}`;
};

/**
 * Форматировать данные для запросов к API
 * @param {Date} date
 */
export const generateDownloadTime = (date: Date): string => {
  return `${formateDate(date)} ${formateDateTimeForUrl(date)}`;
};

/**
 * Сгенерировать данные для получения плейлиста типа m3u8
 * @param baseURL
 * @param startdate
 * @param enddate
 */
export const generateIntervalUrl = (
  baseURL: string,
  startdate: Date,
  enddate: Date
): string => {
  return `${baseURL}&start-date=${startdate.toISOString()}&stop-date=${enddate.toISOString()}`;
};

/**
 * Функция для CachedImage
 * @param {number} min
 * @param {number} max
 */
export const getRandomIntInclusive = (min: number, max: number) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

/**
 * Конвертировать время из миллисекунд в минут - округляем до большего
 * @param time
 */
export const convertMsToMin = (time: any) => {
  return Math.floor(((time / 1000 / 60) * 10) / 10);
};

/**
 * Проверить то что видео больше часа по длительности
 *
 * @param {string} startTime
 * @param {string} endTime
 * @returns {Boolean}
 */
export const isVideoMoreThanHour = (
  startTime: string,
  endTime: string
): Boolean => {
  const startTimeInMs = Date.parse(startTime);
  const endTimeInMs = Date.parse(endTime);
  return convertMsToMin(endTimeInMs - startTimeInMs) > 60;
};
