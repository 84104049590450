import { useState, useEffect } from "react";

/**
 * Расстояние от начала window до прокрутки
 * на котором начинает показываться кнопка "Наверх"
 */
export const HEIGHT_FOR_VISIBLE_SCROLL = 300;

const useToggleVisibleHook = (): any => {
  const [visibleTopState, setVisibleTopState] = useState(false);
  const [currentScrollState, setCurrentScrollState] = useState(0);

  /**
   * Сделать видимой кнопку наверх
   */
  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > HEIGHT_FOR_VISIBLE_SCROLL) {
      setVisibleTopState(true);
    } else if (scrolled <= HEIGHT_FOR_VISIBLE_SCROLL) {
      setVisibleTopState(false);
    }
    setCurrentScrollState(scrolled);
  };

  /**
   * Вешаем обработчик для скролла
   */
  useEffect(() => {
    window.addEventListener("scroll", toggleVisible);

    return () => {
      window.removeEventListener("scroll", toggleVisible);
    };
  });

  return {
    visibleTopState,
    currentScrollState,
  };
};

export default useToggleVisibleHook;
