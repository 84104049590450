/**
 * Вспомогательная функция, которая позволяет без ошибок TS взять необходимое поле объекта по строковому ключу
 * @param {string | number} key поле объекта которое нужно взять
 * @param {any} obj объект, в котором необходимо взять поле
 * @returns obj[key] - поле key объекта obj
 */
export const getKeyValue = <
  T extends Record<string | number, any>,
  U extends keyof T
>(
  key: U
) => (obj: T): any => obj[key];

/**
 * Вспомогательная функция, которая позволяет без ошибок TS задать значение поля объекта по строковому ключу
 * @param {string | number} key поле объекта которому нужно задать значение
 * @param {any} value значение поля объекта
 * @param {any} obj объект, в котором необходимо задать поле
 */
export const setKeyValue = <
  T extends Record<string | number, any>,
  U extends keyof T
>(
  key: U,
  value: any
) => (obj: T) => (obj[key] = value);
