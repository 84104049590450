import styled from "styled-components";
import { ifProp } from "styled-tools";

import device from "@helpers/device";
import { mediaQueries, widthSidebar } from "@helpers/styled-components";
import { ISidebarOverlayStyled, IRootWrapperStyled } from "../types";

export const Wrapper = styled.div`
  display: flex;
  position: relative;
`;

export const SidebarOverlay = styled.div<ISidebarOverlayStyled>`
  display: ${ifProp("showOverlay", "block", "none")};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #f2f2f2;
  opacity: 0.3;
  cursor: pointer;
`;

/*

  Обратить особое внимание на padding-left (ширина бокового меню + погрешность в 2px)
   RootWrapper - что отступ меняется в зависимости от открытого бокового меню
  это нужно чтобы избежать скачка ширины при открытиии и закрытии меню
*/
export const RootWrapper = styled.div<IRootWrapperStyled>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: content-box;
  min-height: ${() => {
    if (device.isAny()) {
      return `${window.innerHeight}px`;
    }
    return "100vh;";
  }};
  width: 100%;
  padding-left: ${ifProp("show", "calc(36px + 88px)", "36px")};
  padding-right: 36px;
  margin: auto;

  ${mediaQueries("xl")`
      width: 100%;
      padding-right: 24px;
    `};

  @media (max-width: 1140px) {
    padding-left: ${ifProp("show", `calc(26px + ${widthSidebar})`, "24px")};
  }

  ${mediaQueries("sm")`
      width: 100%;
      min-width: 320px;
      padding-left: 10px;
      padding-right: 10px;
    `};
`;

export const Main = styled.main`
  display: flex;
  flex: 1 0 auto;
  width: 100%;
  justify-content: center;
`;
export const FooterStyled = styled.footer`
  flex: 0 0 auto;
  width: 100%;
  margin-top: 3rem;
`;
