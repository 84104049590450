import { moduleName } from "./module";
import { TTab } from "@globalTypes/search";

const appName = process.env.REACT_APP_NAME;

export const actionTypes = {
  /* Для обработки поиска в хедере */
  CHANGE_SEARCH_STRING: `${appName}/${moduleName}/CHANGE_SEARCH_STRING`,
  SEARCH_START: `${appName}/${moduleName}/SEARCH_START`,
  SEARCH_UPDATE: `${appName}/${moduleName}/SEARCH_UPDATE`,
  SEARCH_COMPLETE: `${appName}/${moduleName}/SEARCH_COMPLETE`,
  SEARCH_ERROR: `${appName}/${moduleName}/SEARCH_ERROR`,
  SET_SEARCH_MODE: `${appName}/${moduleName}/SET_SEARCH_MODE`,
  CHANGE_SEARCH_TYPE: `${appName}/${moduleName}/CHANGE_SEARCH_TYPE`,
};

/**
 * Изменение строки поиска
 * @param {string} searchString Строка поиска
 * @param {boolean} searchStart Начать поиск
 */
export const changeSearchStringAction = (
  searchString: string,
  searchStart: boolean = true
) => ({
  type: actionTypes.CHANGE_SEARCH_STRING,
  payload: {
    searchString,
    searchStart,
  },
});

/**
 * Выполнить поиск по элементам
 * @param {string} searchString Строка поиска
 */
export const searchStartAction = (searchString: string) => ({
  type: actionTypes.SEARCH_START,
  payload: {
    searchString,
  },
});

/**
 * Внести дополнительные данные при поиске
 * @param {Array<number>} cameras Список камер
 * @param {Array<any>} groups Список групп
 * @param {Array<any>} searchedData Список найденных элементов
 */
export const searchUpdateAction = (
  cameras: Array<number>,
  groups: Array<any>,
  searchedData: Array<any>
) => ({
  type: actionTypes.SEARCH_UPDATE,
  payload: {
    cameras,
    groups,
    searchedData,
  },
});

/**
 * Вызвать доп обработку при поиске
 * @param data Данные поиска
 * @param forceSetActiveId Принудительно выставить активный элемент
 */
export const searchCompleteAction = (
  data: object,
  forceSetActiveId = true
) => ({
  type: actionTypes.SEARCH_COMPLETE,
  payload: {
    data,
    forceSetActiveId: !!forceSetActiveId,
  },
});

/**
 * Вызвать ошибку при поиске
 * @param {string} message Сообщение об ошибке
 */
export const searchErrorAction = (message: string) => ({
  type: actionTypes.SEARCH_ERROR,
  payload: {
    message,
  },
});

/**
 * Поменять тип поиска
 */
export const tabTypeAction = (tabType: TTab) => ({
  type: actionTypes.CHANGE_SEARCH_TYPE,
  payload: {
    tabType,
  },
});
