import { moduleName } from "./module";
import { createSelector } from "reselect";
import moment from "moment";

export const archiveState = (state: any): archiveSagaSchema =>
  state[moduleName];

export const currentVideoOffsetInSecondsSelector = createSelector(
  [archiveState],
  state => state.currentVideoOffsetInSeconds
);

export const archiveDaysDurationSelector = createSelector(
  [archiveState],
  state => {
    const archiveStartTime = state.cameraData.ARCHIVE.START_TIME;

    const startTime = archiveStartTime.split(" ")[1];

    const archiveStartTimestamp = moment(
      archiveStartTime,
      "DD-MM-YYYY HH:mm"
    ).unix();

    const difference = Date.now() / 1000 - archiveStartTimestamp;
    const days = Math.floor(difference / 60 / 60 / 24);

    return { days, startTime };
  }
);

export const timelineIntervalSelector = createSelector(
  [archiveState],
  state => {
    return {
      timelineStartPositionInMs:
        state.timelineInterval.timelineStartPositionInMs,
      timelineEndPositionInMs: state.timelineInterval.timelineEndPositionInMs,
    };
  }
);

export const newSelectedVideoOffsetSelector = createSelector(
  [archiveState],
  state => state.newSelectedVideoOffsetInSeconds
);

export const cameraNameSelector = createSelector(
  [archiveState],
  state => state.cameraName
);

export const cameraDataSelector = createSelector(
  [archiveState],
  state => state.cameraData
);

export const downloadModeSelector = createSelector(
  [archiveState],
  state => state.downloadMode
);

export const downloadRangeArraySelector = createSelector(
  [archiveState],
  state => state.downloadRangeArray
);

export const playerUrlSelector = createSelector(
  [archiveState],
  state => state.playerUrl
);

export const currentVideoTimestampSelector = createSelector(
  [archiveState],
  state => state.currentVideoTimestamp
);

export const userSeletedTimestampSelector = createSelector(
  [archiveState],
  state => state.userSeletedTimestamp
);

export const liveSelector = createSelector([archiveState], state => state.live);

export const isOpenModalStateSelector = createSelector(
  [archiveState],
  state => state.isOpenModalState
);

export const timelineZoomSelector = createSelector(
  [archiveState],
  state => state.timelineZoom
);

export const loadingProgressSelector = createSelector(
  [archiveState],
  state => state.loadingProgress
);

export const downloadLinkSelector = createSelector(
  [archiveState],
  state => state.downloadLink
);

export const downloadNameSelector = createSelector(
  [archiveState],
  state => state.name
);

export const canDownloadSelector = createSelector(
  [archiveState],
  state => state.canDownload
);

export const fsModeSelector = createSelector(
  [archiveState],
  state => state.fsMode
);

export const accessAudioSelector = createSelector(
  [archiveState],
  state => state.cameraData.ACCESS.ARCHIVE.AUDIO
);

export const rewindSelector = createSelector(
  [archiveState],
  state => state.rewind
);

export const lastArchiveTimeSelector = createSelector(
    [archiveState],
    state => state.lastArchiveTime
);
