import React from "react";

import { RootWrapper, Main } from "./styled-components";
import { IArchiveLayoutProps } from "./types";

const ArchiveLayout = ({ children: childrenProps }: IArchiveLayoutProps) => (
  <div>
    <div className="sidebar"></div>
    <RootWrapper className="root-wrapper">
      <Main>{childrenProps}</Main>
    </RootWrapper>
  </div>
);

export default ArchiveLayout;
