import { moduleName } from "./module";

const appName = process.env.REACT_APP_NAME;

export const actionTypes = {
  SET_VOLUME_LEVEL: `${appName}/${moduleName}/SET_VOLUME_LEVEL`,
  SET_MUTE_STATUS: `${appName}/${moduleName}/SET_MUTE_STATUS`,
  FIVE_SECONDS_BACK: `${appName}/${moduleName}/FIVE_SECONDS_BACK`,
  FIVE_SECONDS_FORWARD: `${appName}/${moduleName}/FIVE_SECONDS_FORWARD`,
  UPDATE_VIDEO_PROGRESS: `${appName}/${moduleName}/UPDATE_VIDEO_PROGRESS`,
  SET_NEED_USER_START_PLAY_MANUAL: `${appName}/${moduleName}/SET_NEED_USER_START_PLAY_MANUAL`,
  SET_SPEED_COEFFICIENT: `${appName}/${moduleName}/SET_SPEED_COEFFICIENT`,
  SET_HD_VIDEO: `${appName}/${moduleName}/SET_HD_VIDEO`,
  SET_IS_PLAYING_STATE: `${appName}/${moduleName}/SET_IS_PLAYING_STATE`,
  SET_ARCHIVE_PLAYER_WIDTH: `${appName}/${moduleName}/SET_ARCHIVE_PLAYER_WIDTH`,
  SET_VIDEO_ZOOM: `${appName}/${moduleName}/SET_VIDEO_ZOOM`,
  INCREASE_VIDEO_ZOOM: `${appName}/${moduleName}/INCREASE_VIDEO_ZOOM`,
  INCREASE_VIDEO_ZOOM_SLOWLY: `${appName}/${moduleName}/INCREASE_VIDEO_ZOOM_SLOWLY`,
  DECREASE_VIDEO_ZOOM: `${appName}/${moduleName}/DECREASE_VIDEO_ZOOM`,
  DECREASE_VIDEO_ZOOM_SLOWLY: `${appName}/${moduleName}/DECREASE_VIDEO_ZOOM_SLOWLY`,
};

export const setIsPlayingAction = (isPlaying: boolean) => {
  return {
    type: actionTypes.SET_IS_PLAYING_STATE,
    payload: {
      isPlaying,
    },
  };
};

export const setSpeedCoefficientAction = (speedCoefficient: number) => {
  return {
    type: actionTypes.SET_SPEED_COEFFICIENT,
    payload: {
      speedCoefficient,
    },
  };
};

export const setNeedUserStartPlayFlagAction = (status: boolean) => {
  return {
    type: actionTypes.SET_NEED_USER_START_PLAY_MANUAL,
    payload: {
      userNeedToStartPlayManual: status,
    },
  };
};

export const updateVideoProgressAction = (currentVideoTime: number) => ({
  type: actionTypes.UPDATE_VIDEO_PROGRESS,
  payload: {
    currentVideoTime,
  },
});

/**
 * Установить уровень громкости
 * @param {string} volumeLevel
 */
export const setVolumeLevelAction = (volumeLevel: string) => ({
  type: actionTypes.SET_VOLUME_LEVEL,
  payload: {
    volumeLevel,
  },
});

/**
 * Установить режим со звуком/без звука
 * @param {boolean} muteStatus
 */
export const setMuteStatusAction = (muteStatus: boolean) => ({
  type: actionTypes.SET_MUTE_STATUS,
  payload: {
    muteStatus,
  },
});

export const setHdStateAction = (hdState: boolean) => {
  return {
    type: actionTypes.SET_HD_VIDEO,
    payload: {
      hdState,
    },
  };
};

/**
 * Высота элементов вне плеера(нужна для определения максимальной высоты плеера)
 * @param {number} archivePlayerWidth
 */
export const setArchivePlayerWidthAction = (archivePlayerWidth: number) => {
  return {
    type: actionTypes.SET_ARCHIVE_PLAYER_WIDTH,
    payload: {
      archivePlayerWidth,
    },
  };
};

/*
 * Установить зум видео
 * @param {number} zoom зум видео
 */
export const setVideoZoomAction = (zoom: number) => {
  return {
    type: actionTypes.SET_VIDEO_ZOOM,
    payload: zoom,
  };
};

/**
 * Увеличить зум видео
 */
export const increaseVideoZoomAction = () => {
  return {
    type: actionTypes.INCREASE_VIDEO_ZOOM,
  };
};

/**
 * Уменьшить зум видео
 */
export const decreaseVideoZoomAction = () => {
  return {
    type: actionTypes.DECREASE_VIDEO_ZOOM,
  };
};

/**
 * Увеличить зум видео медленно (для колесика мыши)
 */
export const increaseVideoZoomSlowlyAction = () => {
  return {
    type: actionTypes.INCREASE_VIDEO_ZOOM_SLOWLY,
  };
};

/**
 * Уменьшить зум видео медленно (для колесика мыши)
 */
export const decreaseVideoZoomSlowlyAction = () => {
  return {
    type: actionTypes.DECREASE_VIDEO_ZOOM_SLOWLY,
  };
};
