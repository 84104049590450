import { connect } from "react-redux";
import { Dispatch } from "redux";

import MobileAuthAdressesStep from "../../views/MobileAuthAdressesStep";

import { chooseUserMobileAddressAction } from "../../../modules/user";

import {
  userMobileAddressesOptionsSelector,
  userMobileAddressSelector,
  mobileAuthCodeStatusStoreSelector,
  currentStepSelector,
} from "../../../modules/user";

const mapDispatchToProps = (dispatch: Dispatch) => ({
  chooseUserMobileAddress: (address: string) =>
    dispatch(chooseUserMobileAddressAction(address)),
});
const mapStateToProps = (state: any) => ({
  statusModal: mobileAuthCodeStatusStoreSelector(state),
  currentStep: currentStepSelector(state),
  userMobileAddressesOptions: userMobileAddressesOptionsSelector(state),
  userMobileAddress: userMobileAddressSelector(state),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MobileAuthAdressesStep);
