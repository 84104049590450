import { fork, takeLatest, all, put, call, select } from "redux-saga/effects";


import {
  actionTypes, initDefaultCameraEventsSettingsAction,
  setCameraEventsSettingsAction,
} from "./actions";

import {
  CameraEventsSettingsNewStateActionPayload,
  CameraEventsSettingsSchema, CameraEventsSettingsToggleCameraIdActionPayload
} from "./types";
import {setCameraEventsSettingsToLs, getCameraEventsSettingsFromLs} from "@helpers/cameraEventsSettings/ls";
import {AllEventTypes} from "@modules/CameraEventsSettings/consts";
import {makeDefaultCameraEventSettingsRecord} from "@modules/CameraEventsSettings/utils";
import {cameraEventsSettingsSelector} from "@modules/CameraEventsSettings/selectors";

export const saga = function*() {
  yield all([
    fork(submitEditWatcher),
    fork(initCameraEventsSettingsWatcher),
  ]);
};

export const initCameraEventsSettingsWatcher = function*() {
  yield all([
    takeLatest(actionTypes.INIT_FROM_LS, initFromLsSettingsWorker),
    takeLatest(actionTypes.INIT_DEFAULT, initDefaultSettingsWorker),
  ]);
}

export const submitEditWatcher = function*() {
  yield all([
    takeLatest(actionTypes.EDIT_SUBMIT, submitSettingsWorker),
    takeLatest(actionTypes.TOGGLE_DISABLED_CAMERA, toggleCameraDisabledWorker),
  ]);
};




// После сабмита формы настроек сохраняем в LS и redux
const submitSettingsWorker = function*(data: unknown) {
  const payload = (data as {payload: CameraEventsSettingsNewStateActionPayload}).payload;
  const state = yield select(cameraEventsSettingsSelector);
  const newState = {
    eventTypes: payload.newState.eventTypes,
    disabledCameraIds: state.disabledCameraIds,
  };
  yield setCameraEventsSettingsToLs(newState);
  yield put(setCameraEventsSettingsAction(newState));
};

// После изменения набора отключенных камер сохраняем в LS и redux
const toggleCameraDisabledWorker = function*(data: unknown) {
  const {toggleCameraId} = (data as {payload: CameraEventsSettingsToggleCameraIdActionPayload}).payload;
  console.log('saga ', toggleCameraId);
  const state: CameraEventsSettingsSchema = yield select(cameraEventsSettingsSelector);

  const newDisabledCameraIds = state.disabledCameraIds.includes(toggleCameraId)
        ? state.disabledCameraIds.filter(x => x !== toggleCameraId)
        : state.disabledCameraIds.concat([toggleCameraId]);

  const newState = {
    eventTypes: state.eventTypes,
    disabledCameraIds: newDisabledCameraIds,
  };
  yield setCameraEventsSettingsToLs(newState);
  yield put(setCameraEventsSettingsAction(newState));
};

// Устанавливаем дефолтные настройки в LS и redux
const initDefaultSettingsWorker = function*() {
  const newState: CameraEventsSettingsSchema = {
    eventTypes: AllEventTypes.map(makeDefaultCameraEventSettingsRecord),
    disabledCameraIds: [],
  };
  yield setCameraEventsSettingsToLs(newState);
  yield put(setCameraEventsSettingsAction(newState))
}


const initFromLsSettingsWorker = function*() {
  const lsValue: CameraEventsSettingsSchema | null = yield call(getCameraEventsSettingsFromLs);
  if (!lsValue) {
    yield put(initDefaultCameraEventsSettingsAction());
    return;
  }
  // Заполняем в eventTypes недостающие типы и удаляем лишние
  const requiredEventTypeIds = new Map(AllEventTypes.map(t => [t.id, t]));
  const correctEventTypes = lsValue.eventTypes.filter(e => requiredEventTypeIds.delete(e.id));
  for (const missingEventType of requiredEventTypeIds.values()) {
      correctEventTypes.push(makeDefaultCameraEventSettingsRecord(missingEventType));
  }
  const wasUpdated = lsValue.eventTypes.map(e => e.id).join(',') !== correctEventTypes.map(e => e.id).join(',');

  const newState: CameraEventsSettingsSchema = {
    eventTypes: correctEventTypes,
    disabledCameraIds: lsValue.disabledCameraIds,
  };

  if (wasUpdated) {
    yield setCameraEventsSettingsToLs(newState);
  }
  yield put(setCameraEventsSettingsAction(newState));

}