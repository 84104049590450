import { SagaIterator } from "redux-saga";
import {
  all,
  call,
  fork,
  put,
  takeEvery,
  takeLatest,
  select,
} from "redux-saga/effects";

import {
  actionTypes,
  searchSimpleStartAction,
  changeCurrentChainElementAction,
  setCurrentSelectOptionsAction,
  searchCurrentChainElementAction,
  getMoreOptionsOnScrollAction,
  setCurrentPageAction,
  setLastOptionsAction,
  resetParamsOptionsAction,
  setLoadingScrollAction,
} from "./actions";
import apiMethods from "@helpers/api/apiMethods";
import sendErrorToSentry from "@helpers/sentry";
import {
  ELEMENTS_WHEN_START_SEARCH,
  mapSelectData,
  MAX_CHAIN_ELEMENT,
} from "@helpers/searchSimple";
import { requestForSagaWorker } from "@helpers/api/requestWrappers";

import { setIsLoadingAction } from "@modules/newFlist";
import { queryClient } from "@helpers/reactQuery";
import { searchErrorAction, searchUpdateAction } from "@modules/newFlistSearch";
import {
  currentPageSelector,
  currentSelectOptionsSelector,
  lastOptionsSelector,
  perPageSelector,
} from "./selectors";

export const saga = function*() {
  yield all([fork(chainElementWatcher), fork(searchSagaWatcher)]);
};

export const chainElementWatcher = function*() {
  yield all([
    takeEvery(
      actionTypes.CHANGE_CURRENT_CHAIN_ELEMENT,
      changeCurrentChainElementWorker
    ),
    takeEvery(
      actionTypes.SEARCH_CURRENT_CHAIN_ELEMENT,
      searchCurrentChainElementWorker
    ),
    takeEvery(
      actionTypes.GET_INITIAL_OPTIONS_CHAIN_ELEMENT,
      getInitialOptionsChainElementWorker
    ),
    takeEvery(actionTypes.GET_MORE_OPTIONS_ON_SCROLL, getOptionsOnScrollWorker),
    takeEvery(actionTypes.RESET_PARAMS_OPTION, resetParamsOptionsWorker),
  ]);
};

export const searchSagaWatcher = function*() {
  yield all([
    takeLatest(actionTypes.SIMPLE_SEARCH_START, searchSimpleStartWorker),
  ]);
};

export const changeCurrentChainElementWorker = function*({
  payload,
}: ReturnType<typeof changeCurrentChainElementAction>) {
  const { currentChainElementState } = payload;

  const perPage = yield select(perPageSelector);

  /*Если это building - дом то дальше не идем для построения цепочки */
  if (currentChainElementState.value !== MAX_CHAIN_ELEMENT) {
    try {
      const response = yield call(apiMethods.getOptionsForNextChainElement, {
        ...currentChainElementState,
        perPage,
      });
      //Форматируем ответ для select
      const formattedResponse = response.map(mapSelectData);
      yield put(setCurrentSelectOptionsAction(formattedResponse));
      yield put(resetParamsOptionsAction());
    } catch (error) {
      sendErrorToSentry(`Ошибка при получении данных для select ${error}`);
    }
  }

  if (
    currentChainElementState.value &&
    ELEMENTS_WHEN_START_SEARCH.includes(currentChainElementState.value) &&
    currentChainElementState.id
  ) {
    yield put(
      searchSimpleStartAction({
        type: currentChainElementState.value,
        objectId: currentChainElementState.id,
      })
    );
  }
};

export const getOptionsOnScrollWorker = function*({
  payload,
}: ReturnType<typeof getMoreOptionsOnScrollAction>): SagaIterator {
  const { value, id } = payload;

  const [perPage, currentPage, currentSelectOptions, lastOptions] = yield all([
    select(perPageSelector),
    select(currentPageSelector),
    select(currentSelectOptionsSelector),
    select(lastOptionsSelector),
  ]);

  /**
   * Если кол-во новых опций меньше доступного кол-ва опций то больше получить нельзя
   */
  if (!lastOptions.length || lastOptions.length === perPage) {
    yield put(setLoadingScrollAction(true));
    const newPage = currentPage + 1;

    try {
      const response =
        value && id
          ? yield call(apiMethods.getOptionsOnScroll, {
              value,
              id,
              perPage,
              page: newPage,
            })
          : yield call(apiMethods.getInitialOptionsChainElement, {
              perPage,
              page: newPage,
            });

      //Форматируем ответ для select
      const formattedResponse = response.map(mapSelectData);

      yield put(setCurrentPageAction(newPage));
      yield put(setLastOptionsAction(formattedResponse));

      yield put(
        setCurrentSelectOptionsAction([
          ...currentSelectOptions,
          ...formattedResponse,
        ])
      );
    } catch (error) {
      yield put(resetParamsOptionsAction());
      sendErrorToSentry(`Ошибка при поиске данных для select ${error}`, {
        place: "src/app/modules/search/saga.js",
      });
    } finally {
      yield put(setLoadingScrollAction(false));
    }
  }
};

export const searchCurrentChainElementWorker = function*({
  payload,
}: ReturnType<typeof searchCurrentChainElementAction>): SagaIterator {
  const { searchChainElementState } = payload;
  try {
    const response = yield call(
      apiMethods.searchOptionsForNextChainElement,
      searchChainElementState
    );

    //Форматируем ответ для select
    const formattedResponse = response.map(mapSelectData);
    yield put(setCurrentSelectOptionsAction(formattedResponse));
    yield put(resetParamsOptionsAction());
  } catch (error) {
    sendErrorToSentry(`Ошибка при поиске данных для select ${error}`, {
      place: "src/app/modules/search/saga.js",
    });
  }
};

export const getInitialOptionsChainElementWorker = function*(): SagaIterator {
  const [perPage, currentPage] = yield all([
    select(perPageSelector),
    select(currentPageSelector),
  ]);

  const response = yield call(apiMethods.getInitialOptionsChainElement, {
    perPage,
    page: currentPage,
  });
  if (response) {
    const formattedResponse = response.map(mapSelectData);
    yield put(setCurrentSelectOptionsAction(formattedResponse));
  } else {
    sendErrorToSentry(
      `Ошибка при получении изначальных options Select поиска. Пожалуйста обратитесь к администратору`,
      {
        place: "src/app/modules/search/saga.js",
      }
    );
  }
};

export const searchSimpleStartWorker = function*({
  payload,
}: ReturnType<typeof searchSimpleStartAction>) {
  const { type, objectId } = payload;

  yield put(setIsLoadingAction(true));

  /**
   * Не запоминать кэш при поиске
   */
  queryClient.removeQueries({ queryKey: "searchAddress" });

  const searchedData: any = yield call(requestForSagaWorker, {
    requestRouteName: apiMethods.searchAddress,
    requestProps: { type, objectId },
  });

  if (searchedData.failed) {
    yield put(searchErrorAction("Не найдено камер"));
    yield put(searchUpdateAction([], [], []));
    yield put(setIsLoadingAction(false));
  } else {
    const camerasIds = Object.keys(searchedData.cameras).map(el => Number(el));
    yield put(searchUpdateAction(camerasIds, [], []));
  }
};

export const resetParamsOptionsWorker = function*() {
  yield put(setCurrentPageAction(1));
  yield put(setLastOptionsAction([]));
};
