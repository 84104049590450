import LocalStorage from "../storage/LocalStorage";
import localStorageKeys from "../storage/localStorageKeys";
import sendErrorToSentry from "../sentry/index";

export const getLoginFromLs = () => {
  const loginLs = localStorage.getItem(localStorageKeys.USER_LOGIN);
  if (loginLs) {
    try {
      return JSON.parse(loginLs);
    } catch (e) {
      sendErrorToSentry(`Error getting LS UserLogin ${e}`);
    }
    return null;
  } else return "";
};

export const setLoginToLs = async login => {
  return LocalStorage.set(localStorageKeys.USER_LOGIN, login);
};

/**
 * Сгенерировать uuid v-4 ключ
 * @returns
 */
export const generateUuidv4 = () => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function(c) {
    var r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

/**
 * Записать в localStorage время послденго запроса кода
 * @param {number} time время последнего запроса
 */
export const setLastCodeRequestTime = time => {
  return LocalStorage.set(
    localStorageKeys.LAST_MOBILE_AUTH_CODE_REQUEST,
    `${time}`
  );
};

/**
 * Получить из localStorage время последнего запроса кода
 * @returns {number} время последнего запроса кода
 */
export const getLastCodeRequestTime = () => {
  return Number(
    LocalStorage.get(localStorageKeys.LAST_MOBILE_AUTH_CODE_REQUEST)
  );
};
