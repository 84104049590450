import {
  fork,
  takeLatest,
  takeEvery,
  take,
  all,
  call,
  put,
  select,
  actionChannel,
} from "redux-saga/effects";
import _ from "lodash";

import { requestForSagaWorker } from "@helpers/api/requestWrappers";
import apiMethods from "@helpers/api/apiMethods";

import {
  getCurrentGroupFromLs,
  setCurrentGroupToLs,
  setSelectedCamsToLs,
  setCurrentPathModalCamlistToLs,
  getCurrentPathModalCamlistFromLs,
  removeCurrentPathModalCamlistFromLs,
  getVersionOfLsFromLs,
  getSelectedCamsFromLs,
} from "@helpers/newFlist";

import sendErrorToSentry from "@helpers/sentry";

import { getIdByObjectKey, getParentObjectKey } from "../flist";

import { initFailedAction, camerasReadyAction } from "../init";

import {
  actionTypes,
  getGroupErrorAction,
  getGroupStartAction,
  groupLoadCompleteAction,
  changeGroupFinishAction,
  setSelectedObjectsFinishAction,
  setCurrentSelectedGroupIdAction,
  setLimitedCamerasDataAction,
  addGroupToGroupsStoreAction,
  toggleSelectedFinishAction,
  setIsLoadingAction,
  addGroupToModalPathAction,
  initGroupRecoveryAction,
  initListAction,
  setSelectedObjectsStartAction,
  addLoadingIdAction,
  removeLoadingIdAction,
  changeGroupStartAction,
  toggleSelectedStartAction,
  checkCamerasForRightsAction, setSelectedCamerasIds,
} from "./actions";

import {
  getGroupContentById,
  currentSelectedGroupIdSelector,
  getGroupByGroupId,
  selectedCamerasSelector,
  currentGroupContentSelector,
  selectedCamerasContentSelector,
} from "./selectors";

import { IGroupPathModal, ILimitedInfoError } from "./types/newFlistSagasTypes";

// Для открытия модального окна с предупреждением
import {
  openWarningModalAction,
  actionTypes as WarningModalActionTypes,
} from "../WarningModal";
import { changeSearchStringAction } from "../newFlistSearch";

import { validateGroupId, validateAccessToGroup, addIdsToUrl } from "./helpers";
import { notifyInfoLoad } from "@helpers/toast";
import { queryClient } from "@helpers/reactQuery";
import { checkUrlArchive } from "@helpers/init";

export const saga = function*() {
  yield all([
    fork(groupsWatcher),
    fork(initWatcher),
    fork(selectionWatcher),
    fork(toggleObjectWatcher),
    fork(toggleObjectWatcherForLoading),
    fork(cleanWatcher),
  ]);
};

export const initWatcher = function*() {
  yield takeLatest(actionTypes.INIT_LIST_START, initListWorker);
};

export const groupsWatcher = function*() {
  yield all([
    takeEvery(actionTypes.GET_GROUP_START, getGroupStartWorker),
    takeLatest(actionTypes.SET_CURRENT_SELECTED_GROUP_START, changeGroupWorker),
    takeLatest(
      actionTypes.ADD_GROUP_TO_MODAL_PATH_CAMLIST,
      addGroupToModalPathWorker
    ),
    takeLatest(actionTypes.INIT_GROUP_RECOVERY, initGroupRecoveryWorker),
  ]);
};

export const selectionWatcher = function*() {
  yield all([
    takeEvery(actionTypes.SET_SELECTED_OBJECTS_START, setSelectedObjectsWorker),
    takeLatest(
      actionTypes.SELECT_ALL_CAMERAS_IN_CURRENT_GROUP,
      selectAllCamerasInCurrentGroupWorker
    ),

    takeLatest(
      actionTypes.CHECK_CAMERAS_FOR_RIGHTS,
      checkCamerasForRightsWorker
    ),
    takeLatest(
      actionTypes.SET_SELECTED_CAMERAS_IDS,
      setSelectedCamerasIdsWorker
    )
  ]);
};

export const toggleObjectWatcherForLoading = function*() {
  yield takeEvery(
    actionTypes.TOGGLE_SELECTED_IDS_START,
    toggleObjectWorkerForLoading
  );
};

export const toggleObjectWatcher = function*() {
  const channel = yield actionChannel(actionTypes.TOGGLE_SELECTED_IDS_START);
  while (true) {
    const { payload } = yield take(channel);

    yield call(toggleObjectWorker, { payload });
  }
};

export const cleanWatcher = function*() {
  yield all([takeLatest(actionTypes.CLEAN_ALL_SELECTED, cleanAllWorker)]);
};

export const toggleObjectWorkerForLoading = function*({
  payload,
}: ReturnType<typeof toggleSelectedStartAction>) {
  const { toggledIds } = payload;
  const id = toggledIds[0];

  const selectedIds: Array<number> = yield select(selectedCamerasSelector);

  if (!selectedIds.includes(id)) yield put(addLoadingIdAction(id));
};

export const toggleObjectWorker = function*({ payload }: any): any {
  const { toggledIds } = payload;
  const currentSelectedCamerasIds = yield select(selectedCamerasSelector);

  const selectedCamerasIds = _.xor(
    [...toggledIds],
    [...currentSelectedCamerasIds]
  );

  const idsForRequest = toggledIds.filter(
    (id: string) => !currentSelectedCamerasIds.includes(id)
  );

  if (selectedCamerasIds.length > 0 && idsForRequest.length) {
    // Берем с бэкенда данные о камерах в лс
    const camerasData: any = yield requestForSagaWorker({
      requestRouteName: apiMethods.getCamerasLimitedInfo,
      requestProps: {
        CAMERA_IDS: [...idsForRequest],
      },
    });
    if (camerasData.failed) {
      yield put(initFailedAction());
    } else {
      //Ставим статус что загружается
      const currentCamerasData = yield select(selectedCamerasContentSelector);

      yield put(setIsLoadingAction(true));
      yield put(
        setLimitedCamerasDataAction(
          _.concat(currentCamerasData, _.values(camerasData))
        )
      );
      yield put(setIsLoadingAction(false));
    }
  }

  setSelectedCamsToLs(selectedCamerasIds);

  addIdsToUrl(selectedCamerasIds);

  yield put(removeLoadingIdAction(toggledIds[0]));
  yield put(toggleSelectedFinishAction(selectedCamerasIds));
};

/**
 * Загрузка currentGroup для FCamList компонента
 */
export const initListWorker = function*(): any {
  //Проверку на восстановление LS selected elements мы прошли в модуле init
  // Выбираем текущую группу из лс и редакса.
  const currentGroupLs = yield call(getCurrentGroupFromLs);
  const versionOfLs = yield call(getVersionOfLsFromLs);
  //Проверяем есть ли у нас путь в LS
  const pathModalCamlistDetailLs = yield call(getCurrentPathModalCamlistFromLs);

  //Id группы
  let groupId;
  //Текущая группа
  let currentGroup;
  //Parent группы
  let parentId;

  //Проверяем наличие пути у пользователя если нет то кидаем его в корень
  if (!pathModalCamlistDetailLs && currentGroupLs) {
    groupId = 0;
    currentGroup = {
      ID: 0,
      NAME: "Выбрать камеру",
      PARENT_ID: null,
    };
    //Заносим данные в LS и redux
    setCurrentGroupToLs(currentGroup);
    yield put(setCurrentSelectedGroupIdAction(groupId));
  }

  //versionOfLs может быть установлены ранее
  else if (currentGroupLs) {
    //У нас ключ current group нового образца
    if (typeof currentGroupLs.ID !== "undefined" && versionOfLs) {
      //Если у нас есть версия LS и текущая группа в LS
      //то это LS нового образца соотвественно сразу складывам данные
      //по группе по currentGroup в соотв переменные
      groupId = currentGroupLs.ID;
      parentId = currentGroupLs.PARENT_ID;
      currentGroup = currentGroupLs;
    } else {
      //Если у нас нет версии LS но есть текущая группа в LS
      //то значит мы работаем со старыми LS ключами(склеенными)
      //и необходима некоторая манипуляция

      //Устанавливаем текщую ID группы - выпарсовываю ключ из склеено id и превращая его в число
      groupId = parseInt(getIdByObjectKey(currentGroupLs.activeObjectId));

      //Устанавливаем текщую ID родителя - выпарсовываю ключ из склеено id и превращая его в число
      parentId = parseInt(getParentObjectKey(currentGroupLs.activeObjectId));
      //Устанавливаем текущую группу которая нам в дальнейшем потребуется для установки в LS и redux
      currentGroup = {
        ID: groupId,
        NAME: currentGroupLs.NAME,
        PARENT_ID: parentId,
      };
    }

    //Нужно предварительно загрузить данные по нашей группе в groups store
    //Сначала проверяем не была ли она загружен ранее и только если не был загружаем его
    //Загружаем его используя наши данные из LS
    const selectedGroup = yield select(getGroupByGroupId, { groupId });

    if (!selectedGroup && currentGroupLs) {
      const groupContentFromLs = {
        groupId: { ID: groupId, NAME: currentGroupLs.NAME, OBJECT: "GROUP" },
      };
      yield put(
        addGroupToGroupsStoreAction({
          groupId,
          groupContent: groupContentFromLs,
          parentId,
        })
      );
    }

    //Запускаем инициализацию восстановления групп - если у нас есть данные в LS
    if (pathModalCamlistDetailLs) {
      yield put(initGroupRecoveryAction());
    }
  } else {
    //Если данных из LS нет - если в ключе пусто значит мы туда ничего не положили из LS
    // то грузим в лс и редакс дефолтный id группы (239 Челябинск)
    groupId = 239;
    parentId = 16;

    currentGroup = {
      ID: groupId,
      NAME: "Челябинск",
      PARENT_ID: parentId,
    };

    //TODO: Возможно ошибка нужно отслеживать
    if (currentGroupLs) {
      sendErrorToSentry(
        `Не получилось восстановить группу currentGroupLs = ${currentGroupLs}`
      );
    }
  }

  //заполняем список на рендеринг.
  const groupContent: any = yield requestForSagaWorker({
    requestRouteName: apiMethods.getGetGroup,
    requestProps: groupId,
  });

  //Проверяем что загрузка удалась
  if (groupContent.failed && typeof groupId !== "undefined") {
    //Если загрузка не удалась сообщаем об этом
    yield put(getGroupErrorAction());
    // и грузим корень (16 0)
    yield put(getGroupStartAction({ groupId: 16, parentId: 0 }));
  } else {
    //Если загрузка удалась то догружаем необходимые данные
    yield put(
      groupLoadCompleteAction({
        groupId: groupId,
        groupContent,
        parentId: parentId,
      })
    );
    yield put(changeGroupFinishAction(groupId));
  }
  //Заносим данные в LS и redux
  setCurrentGroupToLs(currentGroup);
  yield put(setCurrentSelectedGroupIdAction(groupId));
};

/**
 * Изменение группы
 * @param {{groupId: number; streetsMode: string}} payload - groupId Ид группы,
 *  streetsMode режим улиц бокового меню
 */
export const changeGroupWorker = function*({
  payload,
}: ReturnType<typeof changeGroupStartAction>) {
  const { groupId, streetsMode } = payload;

  const lastCurrentGroupId = yield select(currentSelectedGroupIdSelector);
  if (lastCurrentGroupId === "search") {
    yield put(changeSearchStringAction("", false));
  }

  /* Проверяем была ли уже группа загружена и закеширована. */
  const selectedGroupContent = yield select(getGroupContentById, { groupId });

  yield put(addGroupToModalPathAction(groupId));

  let parentId;

  if (+groupId === 16) {
    parentId = 0;
    //Если это корень то ставим null чтобы не было стрелки назад
  } else if (+groupId === 0) {
    parentId = null;
    //Если это вызов со стороны бокового меню со стороны умных улиц
  } else if (streetsMode) {
    parentId = 16;
  } else {
    parentId = yield select(currentSelectedGroupIdSelector);
  }

  // Если еще нет - загружаем.
  if (!selectedGroupContent) {
    const groupContent: any = yield requestForSagaWorker({
      requestRouteName: apiMethods.getGetGroup,
      requestProps: groupId,
    });

    if (groupContent.failed) {
      yield put(getGroupErrorAction());
    } else {
      yield put(groupLoadCompleteAction({ groupId, groupContent, parentId }));
    }
  }

  const selectedGroup = yield select(getGroupByGroupId, { groupId });

  //Если вдруг нет выбранной группы значит иерахия не успела подгрузиться то сохраняем корень
  if (selectedGroup) {
    const currentGroup = {
      ID: groupId,
      NAME: selectedGroup.content.NAME,
      PARENT_ID: parentId,
    };
    //Заносим данные в LS
    setCurrentGroupToLs(currentGroup);
  } else {
    const currentGroup = {
      ID: 0,
      NAME: "Выбрать камеру",
      PARENT_ID: null,
    };
    //Заносим данные в LS
    setCurrentGroupToLs(currentGroup);
    sendErrorToSentry(`Ошибка перехода в группу groupId ${groupId}`);
  }
  //Сохраняем данные в redux
  yield put(changeGroupFinishAction(groupId));
};

export const getGroupStartWorker = function*({
  payload,
}: ReturnType<typeof getGroupStartAction>) {
  const { groupId, parentId } = payload;
  const groupContent: any = yield requestForSagaWorker({
    requestRouteName: apiMethods.getGetGroup,
    requestProps: groupId,
  });
  if (groupContent.failed) {
    yield put(getGroupErrorAction());
  } else {
    yield put(groupLoadCompleteAction({ groupId, groupContent, parentId }));
  }
};

/**
 * Запускается  при перезагрузки страницы и при выборе камеры
 * @param {Array} selectedObjects
 */
export const setSelectedObjectsWorker = function*({
  payload,
}: ReturnType<typeof setSelectedObjectsStartAction>) {
  const { selectedObjects } = payload;

  //Установить данные в LS по ключу SELECTED_CAM_IDS
    if(getSelectedCamsFromLs() === null) yield setSelectedCamsToLs(Array.from(new Set(selectedObjects)));

  if (selectedObjects.length > 0) {
    // Берем с бэкенда данные о камерах в лс

    const camerasData: any = yield requestForSagaWorker({
      requestRouteName: apiMethods.getCamerasLimitedInfo,
      requestProps: {
        CAMERA_IDS: [...selectedObjects],
      },
    });
    if (camerasData.failed) {
      yield put(initFailedAction());
    } else {
      //Ставим статус что загружается
      yield put(setIsLoadingAction(true));
      yield put(setLimitedCamerasDataAction(camerasData));
      yield put(setIsLoadingAction(false));
    }
  }
  yield put(camerasReadyAction());
  //Установить данные в redux по ключу SELECTED_CAM_IDS
  yield put(
    setSelectedObjectsFinishAction(Array.from(new Set(selectedObjects)))
  );
};

/**
 * Очистить все
 */
const cleanAllWorker = function*() {
  // проверяем имеются ли сейчас камеры в LocalStorage
  const camerasFromLsRaw = getSelectedCamsFromLs();
  if (camerasFromLsRaw?.length) {
    //Получаем значение dontShowModalAgain из session storage
    const dontShowModalAgain = yield sessionStorage.getItem(
      "dontShowModalAgain"
    );

    if (!dontShowModalAgain || dontShowModalAgain === "false") {
      // Выводим модальное окно с предупреждением и дожидаемся ответа
      yield put(openWarningModalAction(true));
      // Дожидаемся ответа в предупреждающем об удалении камер модальном окне
      yield take(
        (action: Action) =>
          action.type === WarningModalActionTypes.DELETE_ALL_CAMERAS_FLAG &&
          action?.payload?.deleteAllCamerasFlagStore
      );
    }
    yield put(setSelectedObjectsFinishAction([]));
    setSelectedCamsToLs([]);
  }
};

/**
 * Выбрать все камеры в текущей группе
 */
export const selectAllCamerasInCurrentGroupWorker = function*(): any {
  const contentOfGroup = yield select(currentGroupContentSelector);
  const selectedCamerasStore = yield select(selectedCamerasSelector);

  const contentOfGroupValues = Object.values(contentOfGroup);

  const camerasInCurrentGroup: any[] = [];
  contentOfGroupValues.forEach((element: any) => {
    if (element.OBJECT === "CAMERA") {
      //@ts-ignore
      camerasInCurrentGroup.push(element.ID);
    }
  });

  //Занести все значения кроме тех что уже занесены в store
  const selectedWithout = _.without(
    camerasInCurrentGroup,
    ...selectedCamerasStore
  );

  const inFullSelected = selectedWithout.length === 0;

  const selectedCamerasIds: number[] = inFullSelected
    ? _.without(selectedCamerasStore, ...camerasInCurrentGroup)
    : _.union(selectedCamerasStore, camerasInCurrentGroup);

  yield put(setSelectedObjectsFinishAction(selectedCamerasIds));

  //Занести в LS
  setSelectedCamsToLs(selectedCamerasIds);
};

/**
 * Установить новые выделенные объекты после разлогинизации
 * @param {Array} selectedObjects
 */
export const checkCamerasForRightsWorker = function*({
  payload,
}: ReturnType<typeof checkCamerasForRightsAction>) {
  const { selectedObjects } = payload;

  let camerasData: Array<CameraSchema> & ILimitedInfoError & never[];
  //Почистить кэш перед получением камер
  queryClient.clear();
  if (selectedObjects.length) {
    const newSelectedCams = checkUrlArchive(selectedObjects);
    // Берем с бэкенда данные о камерах в лс
    camerasData = yield requestForSagaWorker({
      requestRouteName: apiMethods.getCamerasLimitedInfo,
      requestProps: {
        CAMERA_IDS: [...newSelectedCams],
      },
    });
    //Если у нас нету камер с возврата то либо массив пришел пустым либо это ошибка сервера
    if (camerasData.failed) {
      camerasData = [];
    }

    yield put(setLimitedCamerasDataAction(Object.keys(camerasData)));
    yield put(camerasReadyAction());
    /**
     * Необходимо обновить информацию
     * о камерах о том что мы провериили ID с нулевым токеном
     *  и получили список ID тех которые нам на данный момент д
     *
     */
    const normalizeIds = Object.keys(camerasData).map(el => Number(el));

    /**
     * Какие id не удалось загрузить
     */

    const ids = _.difference(selectedObjects, normalizeIds);
    if (ids.length)
      notifyInfoLoad(
        `Не удалось загрузить id камер ${ids.join(
          ","
        )} либо нет доступа либо нет таких id`
      );

    const newSelectedIdsObjects = selectedObjects.filter(el =>
      normalizeIds.includes(el)
    );
    yield setSelectedCamsToLs(newSelectedIdsObjects);

    yield put(setSelectedObjectsFinishAction(newSelectedIdsObjects));

    yield put(camerasReadyAction());
  }
};

export const setSelectedCamerasIdsWorker = function* ({
    payload
}: ReturnType<typeof setSelectedCamerasIds>) {
  setSelectedCamsToLs(payload.selectedCamerasIds);
};

/**
 * Установить путь групп в LS
 */
export const addGroupToModalPathWorker = function*({
  payload,
}: ReturnType<typeof addGroupToModalPathAction>) {
  const { groupId, fromSearchFlag } = payload;

  //Проверяем есть ли у нас путь в LS
  const pathModalCamlistLs = yield call(getCurrentPathModalCamlistFromLs);

  const selectedGroup = yield select(getGroupByGroupId, { groupId });

  if (fromSearchFlag) {
    //Заносим данные в подробную модель
    yield call(setCurrentPathModalCamlistToLs, [
      { ID: 0, NAME: "Выбрать камеру" },
      { ID: 16, NAME: "Все улицы онлайн" },
    ]);
  } else {
    try {
      if (pathModalCamlistLs) {
        const groupIdIncluded = pathModalCamlistLs.some(
          (el: IGroupPathModal) => el.ID === groupId
        );

        if (groupIdIncluded) {
          pathModalCamlistLs.pop();
        } else if (!groupIdIncluded) {
          pathModalCamlistLs.push({
            ID: groupId,
            NAME: selectedGroup.content.NAME,
          });
        }
        yield call(setCurrentPathModalCamlistToLs, pathModalCamlistLs);
      } else {
        yield call(setCurrentPathModalCamlistToLs, [
          { ID: 0, NAME: "Выбрать камеру" },
          { ID: groupId, NAME: selectedGroup.content.NAME },
        ]);
      }
    } catch (error) {
      sendErrorToSentry(
        error,
        "modules/newFlist/saga/addGroupModalPathWorker",
        true
      );
    }
  }
};

/**
 * Запустить восстановление групп
 */
export const initGroupRecoveryWorker = function*(): any {
  //Проверяем есть ли у нас путь в LS
  const pathModalCamlistLs = yield call(getCurrentPathModalCamlistFromLs);

  if (pathModalCamlistLs.length > 0) {
    for (let i = 0; i < pathModalCamlistLs.length; i++) {
      //Данный кусок связан с тем что мы ранее уже ранее запрашиваем
      //информацию о группе id 0, 16 и текущей группе (последней в списке)
      if (validateGroupId(pathModalCamlistLs, i)) {
        //Для начала нам нужно удостовериться что у пользователя есть доступы до этой группы
        const isAllowed = yield validateAccessToGroup(pathModalCamlistLs[i].ID);
        if (isAllowed) {
          //Отдельно заносим данные по внутренним элементам каждой группы
          yield put(
            getGroupStartAction({
              groupId: pathModalCamlistLs[i].ID,
              parentId: pathModalCamlistLs[i - 1].ID,
            })
          );

          //Отдельно заносим данные по названию группы
          const groupContentFromLs = {
            groupId: {
              ID: pathModalCamlistLs[i].ID,
              NAME: pathModalCamlistLs[i].NAME,
              OBJECT: "GROUP",
            },
          };
          yield put(
            addGroupToGroupsStoreAction({
              groupId: pathModalCamlistLs[i].ID,
              groupContent: groupContentFromLs,
              parentId: pathModalCamlistLs[i - 1].ID,
            })
          );
        } else {
          /***
           * Что то пошло не так
           * У нас нет доступа до одной из групп
           *  и нам нужно сбросить модальное окно на корень
           */
          //Очищаем наш путь папок
          yield call(removeCurrentPathModalCamlistFromLs);
          yield put(initListAction());
          //Выходим из цикла for
          break;
        }
      }
    }
  }
};
