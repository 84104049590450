/**
 * Выпарсить ID из объекта
 * @param obj
 */
export function parseIdsFromGroupContent(obj: any): number[] {
  let arr: number[] = [];

  for (let prop in obj) {
    if (obj[prop].OBJECT === "CAMERA") {
      arr.push(obj[prop].ID);
    }
  }

  return arr;
}

export function checkUrlArchive(selectedIds: number[]) {
  if (document.location.pathname.includes("/camera-archive/id/")) {
    const splittedValues = document.location.pathname.split("/");

    const newId = Number(splittedValues[splittedValues.length - 1]);

    return selectedIds.includes(newId) ? selectedIds : [...selectedIds, newId];
  } else {
    return selectedIds;
  }
}
