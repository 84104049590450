import { connect } from "react-redux";
import { Dispatch } from "redux";

import MobileAuthCodeModal from "../../views/MobileAuthCodeModal";

import { openMobileCodeModalAction } from "../../../modules/user";

import {
  mobileAuthCodeStatusStoreSelector,
  currentStepSelector,
} from "../../../modules/user";

const mapDispatchToProps = (dispatch: Dispatch) => ({
  openMobileCodeModal: (status: boolean) =>
    dispatch(openMobileCodeModalAction(status)),
});
const mapStateToProps = (state: any) => ({
  statusModal: mobileAuthCodeStatusStoreSelector(state),
  currentStep: currentStepSelector(state),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MobileAuthCodeModal);
