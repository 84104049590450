import { moduleName } from "./module";

const appName = process.env.REACT_APP_NAME;

/**`${appName}/${moduleName}/actionName`**/
export const actionTypes = {
  START_UPDATE_EVENTS: `${appName}/${moduleName}/START_UPDATE_EVENTS`,
  FINISH_UPDATE_EVENTS: `${appName}/${moduleName}/FINISH_UPDATE_EVENTS`,
  EVENTS_FAILED_OR_NOT_FOUND_ERROR: `${appName}/${moduleName}/EVENTS_FAILED_OR_NOT_FOUND_ERROR`,
  CLEAN_EVENTS_DATA: `${appName}/${moduleName}/CLEAN_EVENTS_DATA`,
  SET_START_EVENTS_DATE: `${appName}/${moduleName}/SET_START_EVENTS_DATE`,
  SET_END_EVENTS_DATE: `${appName}/${moduleName}/SET_END_EVENTS_DATE`,
};

export const cleanCameraEventsAction = () => {
  return {
    type: actionTypes.CLEAN_EVENTS_DATA,
  };
};

export const finishUpdateEventsAction = (cameraEvents: cameraEvent[]) => {
  return {
    type: actionTypes.FINISH_UPDATE_EVENTS,
    payload: {
      cameraEvents,
      eventsLoadingInProgress: false,
      eventsNotFoundOrFailed: false,
    },
  };
};

export const startUpdateEventsAction = (
  cameraId: number,
  userSelectedTimestamp: number
) => ({
  type: actionTypes.START_UPDATE_EVENTS,
  payload: {
    cameraId,
    userSelectedTimestamp,
  },
});
export const eventsLoadFailedOrNotFoundAction = () => {
  return {
    type: actionTypes.EVENTS_FAILED_OR_NOT_FOUND_ERROR,
    payload: {
      eventsLoadingInProgress: false,
      eventsNotFoundOrFailed: true,
    },
  };
};

/**
 * Установить начальный интервал событий
 * @param {number}startEventsDate
 */
export const setStartEventsAction = (startEventsDate: number) => {
  return {
    type: actionTypes.SET_START_EVENTS_DATE,
    payload: {
      startEventsDate,
    },
  };
};

/**
 * Установить конечный интервал событий
 * @param {number} endEventsDate
 */
export const setEndEventsAction = (endEventsDate: number) => {
  return {
    type: actionTypes.SET_END_EVENTS_DATE,
    payload: {
      endEventsDate,
    },
  };
};
