import React, { ComponentType } from "react";
import Loadable, { LoadingComponentProps } from "react-loadable";

import { TComponentProps } from "@views/App/types";
import ArchiveLayout from "@views/ArchiveLayout";

import { DefaultLayout, LayoutWithMenu } from "@layouts";

import ScrollTopWrapper from "@views/ScrollTopWrapper";

import Loader from "@UIKit/atoms/Loader";

import { IRouteItem } from "./types";

import { ArchiveProps } from "@views/Archive/types";

const LoginForm = Loadable({
  loader: () => import("@containers/LoginForm"),
  loading: Loader as ComponentType<LoadingComponentProps>,
});

const Page500 = Loadable({
  loader: () => import("@views/Page500"),
  loading: Loader as ComponentType<LoadingComponentProps>,
});

const Page404 = Loadable({
  loader: () => import("@views/Page404"),
  loading: Loader as ComponentType<LoadingComponentProps>,
});

const Archive = Loadable({
  loader: () => import("@containers/Archive"),
  loading: Loader as ComponentType<LoadingComponentProps>,
});

const StreetsOnline = Loadable({
  loader: () => import("@views/streetsOnline/StreetsOnline"),
  loading: Loader as ComponentType<LoadingComponentProps>,
});

export const routes: IRouteItem[] = [
  {
    path: "/authorization",
    name: "Авторизация",
    component: LoginForm,
    protectedRoute: false,
    exact: false,
  },
  {
    path: "/500",
    name: "Page 500",
    component: Page500,
    protectedRoute: false,
    exact: false,
  },
  {
    path: "/404",
    name: "Page 404",
    component: Page404,
    protectedRoute: false,
    exact: false,
  },
  {
    path: `/camera-archive/id/:cameraId`,
    name: "Архив",
    component: (props: TComponentProps & ArchiveProps) => (
      <ArchiveLayout>
        <Archive {...props} />
      </ArchiveLayout>
    ),
    protectedRoute: true,
    exact: false,
  },

  {
    path: `/video`,
    name: "Видеонаблюдение",
    component: (props: TComponentProps & ArchiveProps) => (
      <DefaultLayout>
        <StreetsOnline {...props} />
      </DefaultLayout>
    ),
    protectedRoute: false,
    exact: false,
  },

  {
    path: `/favourites`,
    name: "Избранное",
    component: (props: TComponentProps & ArchiveProps) => (
      <DefaultLayout>
        <StreetsOnline {...props} />
      </DefaultLayout>
    ),
    protectedRoute: false,
    exact: false,
  },

  {
    path: `/`,
    name: "Улицы онлайн",
    component: (props: TComponentProps & ArchiveProps) => (
      <LayoutWithMenu>
        <StreetsOnline {...props} />
        <ScrollTopWrapper />
      </LayoutWithMenu>
    ),
    protectedRoute: false,
    exact: false,
  },
];
