import { fork, takeLatest, all, put } from "redux-saga/effects";

import { actionTypes } from "./actions";
import {
  finishUpdateEventsAction,
  eventsLoadFailedOrNotFoundAction,
  setStartEventsAction,
  setEndEventsAction,
} from "./actions";

import apiMethods from "../../helpers/api/apiMethods";
import { requestForSagaWorker } from "../../helpers/api/requestWrappers";

import { generateDownloadTime } from "../../helpers/archive";
import { countOffsetFromNowAndGenerateDate } from "../../helpers/time";

export const saga = function*() {
  yield all([fork(EventsSagaWatcher)]);
};

export const EventsSagaWatcher = function*() {
  yield all([takeLatest(actionTypes.START_UPDATE_EVENTS, eventsUpdateWorker)]);
};

const eventsUpdateWorker = function*({ payload }: any): any {
  const { cameraId, userSelectedTimestamp } = payload;
  const backIntervalOffsetinMs = 10 * 60 * 60 * 1000;
  const forwardIntervalOffsetinMs = 10 * 60 * 1000;
  const newEndDateTime =
    new Date().valueOf() - userSelectedTimestamp > forwardIntervalOffsetinMs
      ? new Date(userSelectedTimestamp + forwardIntervalOffsetinMs)
      : new Date();
  const newStartDateTime = countOffsetFromNowAndGenerateDate(
    userSelectedTimestamp,
    -1 * backIntervalOffsetinMs
  );

  const requestResult = yield requestForSagaWorker({
    requestRouteName: apiMethods.getCameraEvents,
    requestProps: {
      CAMERA_ID: cameraId,
      START_DATE: generateDownloadTime(newStartDateTime),
      END_DATE: generateDownloadTime(newEndDateTime),
    },
    requestTimeout: 60000,
  });

  yield put(setStartEventsAction(newStartDateTime.valueOf()));
  yield put(setEndEventsAction(newEndDateTime.valueOf()));

  if (requestResult.failed || !requestResult.RESULT) {
    yield put(eventsLoadFailedOrNotFoundAction());
  } else {
    yield put(finishUpdateEventsAction(requestResult.RESULT.reverse()));
  }
};
