import { TypeCurrentStep } from "../../modules/user/types/userSchemaTypes";

export const mobileAuthSteps = {
  addressesStep: "addressesStep",
  codeStep: "codeStep",
};

/**
 * Проверка что текущий шаг является адресом
 * @param {TypeCurrentStep} currentStep
 * @returns {boolean}
 */
export const isAddressStep = (currentStep: TypeCurrentStep) =>
  currentStep === mobileAuthSteps.addressesStep;
/**
 * Проверка что текущий шаг является кодом
 * @param {TypeCurrentStep} currentStep
 * @returns {boolean}
 */
export const isCodeStep = (currentStep: TypeCurrentStep) =>
  currentStep === mobileAuthSteps.codeStep;
