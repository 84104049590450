import { requestForSagaWorker } from "@helpers/api/requestWrappers";
import apiMethods from "@helpers/api/apiMethods";
import { IGroupPathModal } from "../types/newFlistSagasTypes";

/**
 * Этот хелпер поможет хранить раздельно информацию о камерах и информацию о группах.
 *
 * @param currentCameras
 * @param currentGroups
 * @param rawGroup - то что внутри группы
 * @param parentId
 * @returns
 */
export const splitUserGroupsContentForRedux = (
  currentCameras: { [id: number]: CameraSchema },
  currentGroups: { [id in customGroupId]: GroupSchema },
  rawGroup: { [id: string]: CameraSchema | ContentSchema },
  parentId: number
) => {
  let cameras = { ...currentCameras };
  let groups = { ...currentGroups };

  const contentKeys = Object.keys(rawGroup);

  // eslint-disable-next-line
  contentKeys.map((id: string) => {
    //Находим объект по id внутри группы
    const currentObj = rawGroup[id];
    const IDOfCurrentObj = rawGroup[id].ID;

    if (currentObj.OBJECT === "CAMERA") {
      cameras = Object.assign(cameras, {
        [IDOfCurrentObj]: currentObj,
      });
    } else if (currentObj.OBJECT === "GROUP") {
      groups = Object.assign(groups, {
        [IDOfCurrentObj]: {
          parentId,
          content: {
            ...currentObj,
          },
        },
      });
    }
  });

  return { cameras, groups };
};

/**
 *
 * Хелпер нужен для мержа новых групп в схему.
 * @param {} currentSchema
 * @param newGroup
 */
export const mergeGroupsContentById = (
  currentGroupsContentById: any,
  newGroup: ContentSchema,
  groupId: number,
  parentId: number
) => {
  let groupsContentById = { ...currentGroupsContentById };

  groupsContentById[groupId] = { parentId: parentId, content: newGroup };

  return groupsContentById;
};

/**
 * Валидировать ID группы чтобы не запрашивать повторно данные по группам
 * @param {IGroupPathModal[]} pathModalCamlistLs
 * @param {number} i
 * @returns {boolean}
 */
export const validateGroupId = (
  pathModalCamlistLs: IGroupPathModal[],
  i: number
): boolean =>
  pathModalCamlistLs[i]?.ID !== 0 && pathModalCamlistLs[i]?.ID !== 16;

/**
 * Проверка есть ли у нас права для доступа в ту или иную группу
 * @param {number} groupId
 * @returns {boolean}
 */
export function* validateAccessToGroup(groupId: number): any {
  const groupContent: any = yield requestForSagaWorker({
    requestRouteName: apiMethods.getGetGroup,
    requestProps: groupId,
  });
  return Object.keys(groupContent).length > 0;
}

export const addIdsToUrl = (selectedIds: number[]) => {
  const searchURL = new URL(window.location.href);
  const newParams = `${selectedIds.join(",")}`;
  searchURL.searchParams.set("idCameras", newParams);

  window.history.pushState({}, "", searchURL.toString());

  /**
   * Чтобы избежать автоматического кодирования URL
   *
   * */
  const newUrl =
    window.location.protocol +
    "//" +
    window.location.host +
    window.location.pathname +
    decodeURIComponent(window.location.search);
  window.history.pushState({ path: newUrl }, "", newUrl);
};
