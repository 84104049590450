import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";

import initReducer, { moduleName as initModuleName } from "@modules/init";

import flistReducer, { moduleName as flistModuleName } from "@modules/flist";

import newFlistReducer, {
  moduleName as newFlistModuleName,
} from "@modules/newFlist";

import newFlistSearchReducer, {
  moduleName as newFlistSearchModuleName,
} from "@modules/newFlistSearch";

import userReducer, { moduleName as userModuleName } from "@modules/user";

import playerBoxWithStateReducer, {
  moduleName as playerBoxWithStateName,
} from "@modules/PlayerBoxWithState";

import archiveReducer, {
  moduleName as archiveModuleName,
} from "@modules/Archive";

import carouselReducer, {
  moduleName as carouselModuleName,
} from "@modules/Carousel";

import eventsReducer, { moduleName as eventsModuleName } from "@modules/events";

import streetsOnlineReducer, {
  moduleName as streetsOnlineModuleName,
} from "@modules/streetsOnline";

import sidemenuReducer, {
  moduleName as sidemenuModuleName,
} from "@modules/sidemenu";

import playerReducer, { moduleName as playerModuleName } from "@modules/player";

import timelineReducer, {
  moduleName as timelineModuleName,
} from "@modules/timeline";

import warningModalReducer, {
  moduleName as warningModalModuleName,
} from "@modules/WarningModal";

import searchSimpleReducer, {
  moduleName as searchSimpleModuleName,
} from "@modules/searchSimple";

import cameraEventsSettingsReducer, {
  moduleName as cameraEventsSettingsModuleName,
} from "@modules/CameraEventsSettings";

import history from "@helpers/history";

const rootReducer = combineReducers({
  [initModuleName]: initReducer,
  [userModuleName]: userReducer,
  [streetsOnlineModuleName]: streetsOnlineReducer,
  [flistModuleName]: flistReducer,
  [newFlistModuleName]: newFlistReducer,
  [newFlistSearchModuleName]: newFlistSearchReducer,
  [searchSimpleModuleName]: searchSimpleReducer,
  [playerBoxWithStateName]: playerBoxWithStateReducer,
  [archiveModuleName]: archiveReducer,
  [carouselModuleName]: carouselReducer,
  [eventsModuleName]: eventsReducer,
  [sidemenuModuleName]: sidemenuReducer,
  [playerModuleName]: playerReducer,
  [timelineModuleName]: timelineReducer,
  [warningModalModuleName]: warningModalReducer,
  [cameraEventsSettingsModuleName]: cameraEventsSettingsReducer,
  router: connectRouter(history),
});

export default rootReducer;
