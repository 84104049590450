export const StateSchema = {
  volumeLevelStore: "0",
  muteStatusStore: true,
  currentVideoTime: 0,
  newSelectedTime: 0,
  userNeedToStartPlayManual: false,
  speedCoefficient: 1.0,
  isPlaying: true,
  hdState: false,
  archivePlayerWidth: 0,
  videoZoom: 1,
};
