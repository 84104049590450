import styled from "styled-components";
// @ts-ignore-start
import { palette } from "styled-theme";

import { ReactComponent as HeaderBreadCrumbsDivider } from "../../../../assets/svgs/streetsOnline/RawSvg/headerBreadCrumbs-divider.svg";
import { Link } from "react-router-dom";

export const HeaderBreadcrumbsRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  font-size: 20px;
  line-height: 28px;
  font-weight: 700;
  padding: 0 21px;
`;

export const HeaderBreadCrumbsDividerIcon = styled(HeaderBreadCrumbsDivider)`
  margin: 0 9px;
  height: 14px;
`;

export const HeaderBreadcrumbsRowSpan = styled(Link)`
  text-decoration: none;
  color: ${palette("grayscale", 1)};

  &:hover {
    cursor: pointer;
    color: ${palette("hoverText", 0)};
  }
`;
