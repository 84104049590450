import { moduleName } from "./module";
import {CameraEventsSettingsSchema, SubmitSettingSchema} from "./types";

const appName = process.env.REACT_APP_NAME;

export const actionTypes = {
  SET_VALUE: `${appName}/${moduleName}/SET_VALUE`,
  EDIT_SUBMIT: `${appName}/${moduleName}/EDIT_SUBMIT`,
  INIT_DEFAULT: `${appName}/${moduleName}/INIT_DEFAULT`,
  INIT_FROM_LS: `${appName}/${moduleName}/INIT_FROM_LS`,
  TOGGLE_DISABLED_CAMERA: `${appName}/${moduleName}/TOGGLE_DISABLED_CAMERA`,
};

export const setCameraEventsSettingsAction = (newState: CameraEventsSettingsSchema) => ({
  type: actionTypes.SET_VALUE,
  payload: {
    newState
  },
});

export const submitEditCameraEventsSettingsAction = (newState: SubmitSettingSchema) => ({
  type: actionTypes.EDIT_SUBMIT,
  payload: {
    newState
  },
});

export const initDefaultCameraEventsSettingsAction = () => ({
  type: actionTypes.INIT_DEFAULT,
  payload: {},
});

export const initCameraEventsSettingsAction = () => ({
  type: actionTypes.INIT_FROM_LS,
  payload: {},
});

export const toggleDisabledCameraAction = (cameraId: string|number) => ({
  type: actionTypes.TOGGLE_DISABLED_CAMERA,
  payload: {toggleCameraId: cameraId},
});