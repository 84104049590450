import { connect } from "react-redux";

import SideMenu from "../../../UIKit/moleculs/SideMenu";

import {
  selectOptionsForStreetsSubMenu,
  selectShowExtendedSidebarStatus,
  chooseCityAction,
  setExtendedSidebarStatusAction,
  selectShowExtendedSubMenuCustomGroupsStatus,
  selectShowExtendedSubMenuStreetsStatus,
  setSubmenuStreetsStatusAction,
} from "@modules/sidemenu";

const mapDispatchToProps = dispatch => ({
  chooseCity: (id, name) => dispatch(chooseCityAction(id, name)),
  setExtendedSidebarStatus: status =>
    dispatch(setExtendedSidebarStatusAction(status)),
  setSubmenuStreetsStatus: status =>
    dispatch(setSubmenuStreetsStatusAction(status)),
});

const mapStateToProps = state => ({
  optionsForStreetsMenu: selectOptionsForStreetsSubMenu(state),
  showExtendedSidebarStatus: selectShowExtendedSidebarStatus(state),
  showExtendedSubMenuCustomGroupsStatus: selectShowExtendedSubMenuCustomGroupsStatus(
    state
  ),
  showExtendedSubMenuStreetsStatus: selectShowExtendedSubMenuStreetsStatus(
    state
  ),
});
export default connect(mapStateToProps, mapDispatchToProps)(SideMenu);
