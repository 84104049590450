/**
 * Проверка что объект не пустой
 * @param {object} data
 */
export const checkIsObjEmpty = function(data: object): boolean {
  return Object.keys(data).length === 0;
};

/**
 * Валидация строка имеет вид _ который нам нужен
 * @param {string} str - строка для
 */
export const validateStrForLs = function(str: string): boolean {
  const regExpToMatch = new RegExp("^[0-9]{1,10}_[0-9]{1,10}$");
  return regExpToMatch.test(str);
};

/**
 * Создать объект из массива
 * @param {Array} arr - массив
 */
export const createObjFromArr = function(arr: Array<any>): Object {
  const obj: any = {};

  for (let i = 0; i < arr.length; i++) {
    Object.assign(obj, arr[i]);
  }

  return obj;
};

/**
 * Создать массив из объекта
 * @param {Object} obj - объект
 */
export const createArrFromObj = function(obj: any): any[] {
  const arr: Array<any> = [];

  for (let prop in obj) {
    arr.push(obj[prop]);
  }

  return arr;
};

/**
 * Рассчитать ширину ползунка скролла
 */
export const getScrollbarWidth = () => {
  return window.innerWidth - document.documentElement.clientWidth;
};

/**
 * Создать массив из объекта и добавить параметр order в массив
 * @param {Object} obj - объект из которого будем делать массив
 * @param {Object} searchedData - объект из которого мы будем получать значение параметра order
 */
export const createArrFromObjSearch = function(
  obj: any,
  searchedData: any
): any[] {
  const arr: Array<any> = [];

  for (let prop in obj) {
    const currentEl = searchedData.find((el: any) => +el.ID === +prop);
    if (currentEl && currentEl?.order) {
      obj[prop].order = currentEl.order;
    }

    arr.push(obj[prop]);
  }

  return arr;
};
