import { createSelector } from "reselect";
import { moduleName } from "./module";
import { CameraEventsSettingsSchema } from "./types";

export const moduleSelector = (state: any) => state[moduleName];

export const cameraEventsSettingsSelector = createSelector(
  [moduleSelector],
  (state: CameraEventsSettingsSchema) => state
);

export const disabledEventsCameraListSelector = createSelector(
  [moduleSelector],
  (state: CameraEventsSettingsSchema) => state.disabledCameraIds,
);

export const isCameraEventsEnabledSelector = createSelector(
  [moduleSelector],
  (state: CameraEventsSettingsSchema) => !!state.eventTypes.find(t => t.enabled)
)