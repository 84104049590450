import { fork, takeLatest, all, put, select } from "redux-saga/effects";
import { Action } from "redux";

import {
  actionTypes,
  setMuteStatusAction,
  setVideoZoomAction,
  setVolumeLevelAction,
} from "./actions";
import {
  archivePlayerVideoZoomSelector,
  muteStatusSelector,
  volumeLevelSelector,
} from "./selectors";

import LocalStorage from "../../helpers/storage/LocalStorage";
import localStorageKeys from "../../helpers/storage/localStorageKeys";
import {
  PLAYER_DIGITAL_ZOOM_DEFAULT_STEP,
  PLAYER_DIGITAL_ZOOM_SMALL_STEP,
  PLAYER_MAX_DIGITAL_ZOOM,
  PLAYER_MIN_DIGITAL_ZOOM,
} from "./consts";

export const saga = function*() {
  yield all([
    fork(volumeLevelSagaWatcher),
    fork(muteSagaWatcher),
    fork(videoZoomWatcher),
  ]);
};

export const volumeLevelSagaWatcher = function*() {
  yield takeLatest(
    actionTypes.SET_VOLUME_LEVEL as any,
    setVolumeLevelSagaWorker
  );
};

export const muteSagaWatcher = function*() {
  yield takeLatest(actionTypes.SET_MUTE_STATUS as any, muteSagaWorker);
};

export const videoZoomWatcher = function*() {
  yield takeLatest(
    [
      actionTypes.INCREASE_VIDEO_ZOOM,
      actionTypes.DECREASE_VIDEO_ZOOM,
      actionTypes.INCREASE_VIDEO_ZOOM_SLOWLY,
      actionTypes.DECREASE_VIDEO_ZOOM_SLOWLY,
    ],
    videoZoomSagaWorker
  );
};

/**
 * Обработчик при изменении громкости
 */
export const setVolumeLevelSagaWorker = function*({
  payload,
}: volumeLevelSagaWorkerType): any {
  const { volumeLevel } = payload;

  const [muteStatusStore] = yield all([select(muteStatusSelector)]);

  if (!muteStatusStore && volumeLevel !== "0")
    LocalStorage.set(localStorageKeys.VOLUME_LEVEL, `${volumeLevel}`);

  /* 
    Если увеличилась громкость то нужно сделать
    режим выключить режим mute
  */
  // Проверяем что ранее уже не был выключен режим Mute
  if (+volumeLevel > 0 && muteStatusStore) {
    yield put(setMuteStatusAction(false));
  } else if (volumeLevel === "0" && !muteStatusStore) {
    yield put(setMuteStatusAction(true));
  }
};

/**
 * Обработчик при нажатии mute иконки
 */
export const muteSagaWorker = function*({
  payload,
}: volumeLevelSagaWorkerType): any {
  const { muteStatus } = payload;
  if (muteStatus) {
    const volume = yield all([select(volumeLevelSelector)]);

    if (volume[0] !== "0")
      LocalStorage.set(localStorageKeys.VOLUME_LEVEL, volume[0]);

    yield put(setVolumeLevelAction("0"));
  } else {
    const volume = +LocalStorage.get(localStorageKeys.VOLUME_LEVEL) || 1;

    yield put(setVolumeLevelAction(`${volume}`));
  }
};

export const videoZoomSagaWorker = function*(e: Action) {
  const currentZoom = yield select(archivePlayerVideoZoomSelector);

  const canIncrease = currentZoom < PLAYER_MAX_DIGITAL_ZOOM;
  const canDecrease = currentZoom > PLAYER_MIN_DIGITAL_ZOOM;

  const coefficient = (e.type as string).includes("SLOWLY")
    ? PLAYER_DIGITAL_ZOOM_SMALL_STEP
    : PLAYER_DIGITAL_ZOOM_DEFAULT_STEP;

  const shouldIncrease =
    (e.type === actionTypes.INCREASE_VIDEO_ZOOM ||
      e.type === actionTypes.INCREASE_VIDEO_ZOOM_SLOWLY) &&
    canIncrease;
  const shouldDecrease =
    (e.type === actionTypes.DECREASE_VIDEO_ZOOM ||
      e.type === actionTypes.DECREASE_VIDEO_ZOOM_SLOWLY) &&
    canDecrease;

  if (shouldIncrease)
    yield put(
      setVideoZoomAction(
        currentZoom + coefficient > PLAYER_MAX_DIGITAL_ZOOM
          ? PLAYER_MAX_DIGITAL_ZOOM
          : currentZoom + coefficient
      )
    );
  if (shouldDecrease)
    yield put(
      setVideoZoomAction(
        currentZoom - coefficient < PLAYER_MIN_DIGITAL_ZOOM
          ? PLAYER_MIN_DIGITAL_ZOOM
          : currentZoom - coefficient
      )
    );
};
