import React, { useState, useEffect } from "react";

import useToggleVisibleHook, {
  HEIGHT_FOR_VISIBLE_SCROLL,
} from "./useToggleVisibleHook";

import ScrollTopBtn from "../ScrollTopBtn";

const ScrollTopWrapper = () => {
  const [lastScrollPositionState, setLastScrollPositionState] = useState(0);

  const [visibleBackState, setVisibleBackState] = useState(false);

  const { visibleTopState, currentScrollState } = useToggleVisibleHook();

  /**
   * Перемещение наверх
   */
  const handleClickGoTop = () => {
    const scrolled = document.documentElement.scrollTop;
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    setLastScrollPositionState(scrolled);
  };

  /**
   * Переместится на последюю позицию
   */
  const handleClickGoBack = () => {
    window.scrollTo({
      top: lastScrollPositionState,
      behavior: "smooth",
    });
    setLastScrollPositionState(0);
    setVisibleBackState(false);
  };

  //Вешаем обработчик для назад
  useEffect(() => {
    if (lastScrollPositionState > 0 && currentScrollState === 0) {
      setVisibleBackState(true);
    } else if (
      currentScrollState > HEIGHT_FOR_VISIBLE_SCROLL &&
      lastScrollPositionState > 0 &&
      visibleBackState
    ) {
      setVisibleBackState(false);
      setLastScrollPositionState(0);
    }
  }, [lastScrollPositionState, currentScrollState, visibleBackState]);

  return (
    <ScrollTopBtn
      visibleBackStatus={visibleBackState}
      visibleTopStatus={visibleTopState}
      handleClickGoBack={() => handleClickGoBack()}
      handleClickGoTop={() => handleClickGoTop()}
    />
  );
};

ScrollTopWrapper.displayName = "ScrollTopWrapper";
export default ScrollTopWrapper;
