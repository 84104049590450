import { SagaIterator } from "redux-saga";
import { all, call, fork, put, select, takeEvery } from "redux-saga/effects";
import _ from "lodash";

import {
  actionTypes,
  searchStartAction,
  searchCompleteAction,
  searchErrorAction,
  searchUpdateAction,
} from "./actions";
import {
  addGroupToGroupsStoreAction,
  changeGroupStartAction,
  groupLoadCompleteAction,
  setCurrentSelectedGroupIdAction,
  setIsLoadingAction,
} from "../newFlist";
import {
  formatDataForSearchObjects,
  formatDataForSearchObjectsSimpleSearch,
  setCurrentGroupToLs,
} from "@helpers/newFlist";
import { requestForSagaWorker } from "@helpers/api/requestWrappers";
import apiMethods from "@helpers/api/apiMethods";
import { OBJECT_TYPES } from "../newFlist/consts";
import sendErrorToSentry from "@helpers/sentry";
import { createArrFromObjSearch } from "@helpers/utilsFunc";
import {
  TChangeSearchStringAction,
  TSearchCompleteAction,
  SearchDataType,
  TSearchStartAction,
  TSearchUpdateAction,
} from "./types/sagaTypes";
import { setKeyValue } from "@helpers/types";
import { queryClient } from "@helpers/reactQuery";
import { tabTypeSelector } from "@modules/newFlistSearch/selectors";

export const saga = function*() {
  yield all([fork(searchSagaWatcher)]);
};

export const searchSagaWatcher = function*() {
  yield all([
    takeEvery(actionTypes.CHANGE_SEARCH_STRING, changeSearchStringWorker),
    takeEvery(actionTypes.SEARCH_START, searchStartWorker),
    takeEvery(actionTypes.SEARCH_UPDATE, searchUpdateWorker),
    takeEvery(actionTypes.SEARCH_COMPLETE, searchCompleteWorker),
  ]);
};

/**
 * Поменять строку поиска. После смены строки начинается поиск.
 * @param {{searchString: string; searchStart: boolean}} payload Объект с данными. searchString - строка поиска, searchStart - начать поиск или нет.
 */
export const changeSearchStringWorker = function*({
  payload,
}: TChangeSearchStringAction): SagaIterator {
  const { searchString, searchStart } = payload;
  if (searchStart) {
    yield put(searchStartAction(searchString));
  }
};

/**
 * Начать поиск по строке поиска.
 * @param {{searchString: string}} payload Объект с данными. searchString - строка поиска.
 */
export const searchStartWorker = function*({
  payload,
}: TSearchStartAction): any {
  const { searchString } = payload;

  if (!searchString.length) {
    //Перекидывать на корень
    //Заносим данные в LS и redux
    const currentGroup = {
      ID: 16,
      NAME: "Все улицы онлайн",
      PARENT_ID: 0,
    };
    yield put(changeGroupStartAction(currentGroup.ID));
    setCurrentGroupToLs(currentGroup);
    yield put(setCurrentSelectedGroupIdAction(currentGroup.ID));
    return;
  }

  yield put(setIsLoadingAction(true));

  const arrFromObj = [];
  /**
   * Не запоминать кэш при поиске
   */
  queryClient.removeQueries({ queryKey: "search" });
  try {
    const searchedData: SearchDataType = yield call(requestForSagaWorker, {
      requestRouteName: apiMethods.search,
      requestProps: searchString,
    });

    let i = 1;

    //Переупаковываю объект в массив.
    for (const key in searchedData) {
      setKeyValue("order", i)(searchedData[key]);
      arrFromObj.push(searchedData[key]);
      i++;
    }

    const newArrayFromObj = [...arrFromObj];
    //Отдельно фильтрую данные по камерам и по группам.
    //Данные по камерам отдельно мне нужны чтобы запросить о них информацию
    //Данные по группам я фильтрую чтобы не смешивать их с камерами
    const filteredCamsIds = newArrayFromObj
      .filter(el => el.OBJECT === OBJECT_TYPES.CAMERA)
      .map(el => +el.ID);

    const filteredGroups = arrFromObj.filter(
      el => el.OBJECT === OBJECT_TYPES.GROUP
    );

    yield put(searchUpdateAction(filteredCamsIds, filteredGroups, arrFromObj));
  } catch (e) {
    yield all([
      put(searchErrorAction(e.message || "Ошибка поиска")),
      put(setIsLoadingAction(false)),
    ]);

    sendErrorToSentry(`Ошибка поиска ${e}, arrayFromObj= ${arrFromObj}`);
  }
};

/**
 * Получение данных о камерах для поиска
 * @param {TSearchUpdateActionPayload} payload
 */
export const searchUpdateWorker = function*({
  payload,
}: TSearchUpdateAction): SagaIterator {
  const { cameras, groups, searchedData } = payload;
  //Если камеры пусты то соответственно запрос делать не будем
  const camerasData =
    cameras.length > 0
      ? yield call(requestForSagaWorker, {
          requestRouteName: apiMethods.getCamerasLimitedInfo,
          requestProps: {
            CAMERA_IDS: [...cameras],
          },
        })
      : [];

  if (camerasData?.failed) {
    yield put(searchUpdateAction([], [], []));
  } else {
    const arrFromCamerasData = createArrFromObjSearch(
      camerasData,
      searchedData
    );

    const newSearchedData = _.concat(arrFromCamerasData, groups);

    yield put(searchCompleteAction(newSearchedData));
  }
};

/**
 * Завершение поиска
 *  @param {object} data, - Найденные объекты
 */
export const searchCompleteWorker = function*({
  payload,
}: TSearchCompleteAction): SagaIterator {
  const { data } = payload;

  const tabType = yield select(tabTypeSelector);

  const formattedData: SearchDataType =
    tabType === "old"
      ? formatDataForSearchObjects(data)
      : formatDataForSearchObjectsSimpleSearch(data);

  const currentGroup = {
    ID: "search",
    NAME: "Результаты поиска",
    PARENT_ID: 16,
  };

  const groupContentFromLs = {
    groupId: {
      ID: currentGroup.ID,
      NAME: currentGroup.NAME,
      OBJECT: OBJECT_TYPES.GROUP,
    },
  };
  yield put(
    addGroupToGroupsStoreAction({
      groupId: currentGroup.ID,
      groupContent: groupContentFromLs,
      parentId: currentGroup.PARENT_ID,
    })
  );

  yield put(
    groupLoadCompleteAction({
      groupId: currentGroup.ID,
      groupContent: formattedData,
      parentId: currentGroup.PARENT_ID,
    })
  );

  //Заносим то что текущая группа Результаты поиска
  //Заносим данные в redux;
  yield put(setCurrentSelectedGroupIdAction(currentGroup.ID));

  yield put(setIsLoadingAction(false));
};
