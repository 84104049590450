import { createGlobalStyle } from "styled-components";
import { palette } from "styled-theme";

export const GlobalStyle = createGlobalStyle`
  body {
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    font-family: "Roboto", "Arial", sans-serif;
    color: ${palette("grayscale", 1, true)}
    background-color: ${palette("white", 1, true)};
  }
`;
