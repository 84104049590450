import {
  fork,
  takeLatest,
  all,
  put,
  call,
  race,
  delay,
  select,
} from "redux-saga/effects";

import {
  orderVideo,
  checkAndGetVideo,
  isVideoMoreThanHour,
} from "../../helpers/archive";
import sendErrorToSentry from "../../helpers/sentry";

import {
  actionTypes,
  setLoadingProgressAction,
  setCameraDataAction,
  setCameraDataErrorNotFoundAction,
  setDownloadNameAction,
  setDownloadLinkAction,
  setStatusDownloadSuccessAction,
  setStatusDownloadErrorAction,
} from "./actions";

import { loadingProgressSelector } from "./selectors";

import apiMethods from "../../helpers/api/apiMethods";
import {
  notifyError,
  notifyDismiss,
  notifyLoadInfo,
} from "../../helpers/toast";

export const saga = function*() {
  yield all([fork(getDownloadVideoSagaWatcher)]);
};

export const getDownloadVideoSagaWatcher = function*() {
  yield all([
    takeLatest(actionTypes.START_DOWNLOAD_VIDEO, downloadVideoWorker),
    takeLatest(actionTypes.UPDATE_CAMERA_DATA_START, cameraDataUpdateWorker),
  ]);
};

const cameraDataUpdateWorker = function*({ payload }: any) {
  const { cameraId } = payload;
  try {
    const { data, timeout } = yield race({
      data: call([apiMethods, apiMethods.getCamerasLimitedInfo], {
        CAMERA_IDS: [cameraId],
      }),
      timeout: delay(10000),
    });
    if (timeout) {
      throw new Error(`Request getCameraEvents timeout. More ${10000}`);
    }
    const cameraData = data[cameraId];
    yield put(setCameraDataAction(cameraData));
  } catch (e) {
    yield put(setCameraDataErrorNotFoundAction());
  }
};

const downloadVideoWorker = function*(data: any): any {
  /**
   * Сначала нужно проверить что мы уже не находимся в статусе загрузка
   * иначе могут быть дубли скачивания
   */
  const loadingProgressStore = yield select(loadingProgressSelector);
  if (!loadingProgressStore) {
    try {
      /**
       * Очистим старые данные
       */
      yield put(setDownloadNameAction(""));
      yield put(setDownloadLinkAction(""));

      const orderResult = yield call(orderVideo, data.payload);

      const { uuid, error } = orderResult;

      const { startTimeAsString, stopTimeAsString } = data.payload;
      yield put(setLoadingProgressAction(true));
      const downloadSettings = {
        timeoutShortDownload: 2000,
        timeoutBigDownload: 10000,
        numberOfTriesDownload: 2000,
      };
      /**
       * Как часто мы будем уточнять готов ли промежуток для скачивания
       * Промежуток в миллисекундах
       * Если запрашиваемое видео для скачивания более
       * чем 1 час то делаем запрос раз в 10 сек иначе раз в 2сек
       */
      const timeoutInMs = isVideoMoreThanHour(
        startTimeAsString,
        stopTimeAsString
      )
        ? downloadSettings.timeoutBigDownload
        : downloadSettings.timeoutShortDownload;

      /**
       * Ошибка получения видео
       */
      if (!uuid && error) {
        yield put(setStatusDownloadErrorAction(error));
        notifyError(`${error}`);
        yield put(setLoadingProgressAction(false));
      } else if (!uuid) {
        notifyError(`Ошибка скачивания. Не удалось запустить скачивание.`);
        yield put(setLoadingProgressAction(false));
      } else {
        notifyLoadInfo();

        /**
         * Попытка получить видео
         */
        const { downloadLink, errorMsg } = yield call(checkAndGetVideo, {
          query: {
            uuid: uuid,
          },
          numberOfTries: downloadSettings.numberOfTriesDownload,
          timeoutInMs,
        });

        if (errorMsg && !downloadLink) {
          yield put(setStatusDownloadErrorAction("Ошибка загрузки видео"));
          notifyDismiss();
          notifyError(`${errorMsg}`);
        } else if (downloadLink && errorMsg) {
          yield put(setStatusDownloadSuccessAction(downloadLink));
          notifyDismiss();
          notifyError(`${errorMsg}`);
        } else if (downloadLink) {
          yield put(setStatusDownloadSuccessAction(downloadLink));
          notifyDismiss();
        } else {
          yield put(setStatusDownloadErrorAction("Ошибка загрузки видео"));
          notifyDismiss();
          notifyError(`Ошибка скачивания! Пожалуйста повторите скачивание`);
        }

        yield put(setLoadingProgressAction(false));
      }
    } catch (error) {
      yield put(setStatusDownloadErrorAction(error));
      sendErrorToSentry(`Ошибка при загрузке видео ${error}`);
      notifyDismiss();
      yield put(setLoadingProgressAction(false));
    }
  }
};
