import {
  fork,
  take,
  takeLatest,
  all,
  call,
  delay,
  race,
  put,
  select,
} from "redux-saga/effects";

import {
  actionTypes,
  groupLoadCompleteAction,
  getGroupErrorAction,
  updateListAction,
  getGroupAction,
  initListCompleteAction,
  setActiveObjectIdAction,
  getLimitedInfoStartAction,
  getLimitedInfoFinishAction,
  getLimitedInfoErrorAction,
  updateFullSelectedGroupsAction,
} from "./actions";

import { changeViewTypeAction, setCalculatedNumAction } from "../streetsOnline";

import {
  groupsSelector,
  camerasSelector,
  objectsStateSelector,
  objectStateSelector,
  fullSelectedGroupsSelector,
} from "./selectors";

import { viewTypes } from "../streetsOnline/schema";

import apiMethods from "../../helpers/api/apiMethods";
import { DEFAULT_FETCH_TIMEOUT } from "@helpers/api";

import {
  setSelectedCamsToLs,
  getSelectedCamsFromLs,
  setAllSelectedGroupsToLs,
  getAllSelectedGroupsFromLs,
  getCurrentGroupFromLs,
  setCurrentGroupToLs,
  getFullSelectedGroupsFromLs,
} from "@helpers/newFlist";

import {
  getSelectedViewFromLs,
  getCalculatedGridNumFromLs,
} from "@helpers/streetsOnlineElements/ls";

import {
  objectTypes,
  ObjectStateSchema,
  createFormattedActiveParentObject,
  getIdByObjectKey,
  getParentObjectKey,
} from "./schema";

import { mapObjectWithProperty } from "@helpers/collections";

import { validateActiveGroup, createNewArrayWithoutLink } from "./helpers";

import sendErrorToSentry from "../../helpers/sentry";

/**
 * SidemenuModule
 */

import { setCitiesAction } from "@modules/sidemenu";
import { prepareResult, setSubmenuOptionsToLs } from "@helpers/sidemenu";
import { createArrFromObj } from "@helpers/utilsFunc";

/*
  Город по умолчанию который будет загружаться если человек первый раз зашел на сайт
  16_239 это Челябинск
*/
const defaultParentCity = "16_239";
const defaultCity = "239";
const STREETS_ONLINE_CURRENT_GROUP = {
  ID: "0_16",
  activeObjectId: "0_16",
  NAME: "Улицы Онлайн",
};

export const saga = function*() {
  yield all([
    fork(getGroupSagaWatcher),
    fork(listInitSagaWatcher),
    fork(updateCameraWatcher),
  ]);
};

export const updateCameraWatcher = function*() {
  yield all([
    takeLatest(actionTypes.GET_LIMITED_INFO_START as any, updateCamerasWorker),
  ]);
};

export const getGroupSagaWatcher = function*() {
  yield all([
    takeLatest(actionTypes.GET_GROUP_START as any, getGroupStartWorker),
    takeLatest(actionTypes.GET_GROUP_COMPLETE as any, getGroupCompleteWorker),
  ]);
};

export const listInitSagaWatcher = function*() {
  yield all([
    takeLatest(actionTypes.INIT_LIST_START, listInitStartWorker),
    takeLatest(actionTypes.INIT_LIST_START, createSubMenuWorker),
  ]);
};

/**
 * Обработчик обновления списка камер
 */
export const updateCamerasWorker = function*({
  payload,
}: updateCamerasWorkerType): any {
  const {
    loadingLimitedIds,
    loadingLimitedIdsForSelect,
    logoutActon,
  } = payload;
  try {
    const { data, timeout } = yield race({
      data: call([apiMethods, apiMethods.getCamerasLimitedInfo], {
        CAMERA_IDS: loadingLimitedIds,
      }),
      timeout: delay(DEFAULT_FETCH_TIMEOUT),
    });
    if (timeout) {
      throw new Error(
        `Request getCamerasLimitedInfo timeout. More ${DEFAULT_FETCH_TIMEOUT}`
      );
    }
    const [currentCameras] = yield all([select(camerasSelector)]);
    if (!logoutActon) {
      const byId = currentCameras.byId;
      const newCamerasById = Object.assign(byId, data);
      const newCameras = Object.assign(currentCameras, {
        byId: newCamerasById,
      });

      yield put(getLimitedInfoFinishAction(newCameras));
    } else {
      const newCameras = {
        byId: data,
        allIds: loadingLimitedIds,
      };

      yield put(getLimitedInfoFinishAction(newCameras));
    }
  } catch (e) {
    yield put(getLimitedInfoErrorAction(loadingLimitedIdsForSelect));
    sendErrorToSentry(
      `Ошибка при получении информации о камерах ${e} loadingLimitedIdsForSelect = ${loadingLimitedIdsForSelect.join(
        "---"
      )}`
    );
  }
};

/**
 * Получение камер которые содержится в группе
 * либо получение групп которые находиться выше уровнем с сервера
 * @param {string} groupId
 * @param {string} parentObjectId
 */
export const getGroupStartWorker = function*({ payload }: any) {
  const { groupId, parentObjectId } = payload;
  try {
    const { data, timeout } = yield race({
      data: call(
        [apiMethods, apiMethods.getGetGroup],
        groupId === "0" ? "" : groupId
      ),
      timeout: delay(DEFAULT_FETCH_TIMEOUT),
    });
    if (timeout) {
      throw new Error(`Request timeout. More ${DEFAULT_FETCH_TIMEOUT}`);
    }
    yield put(groupLoadCompleteAction({ data, groupId, parentObjectId }));
  } catch (e) {
    yield put(
      getGroupErrorAction(
        e.message || "Ошибка получения списка",
        groupId,
        parentObjectId
      )
    );
    sendErrorToSentry(`Ошибка получения списка ${e}, getGroupErrorAction, `, {
      extra: `groupdId = ${groupId}, parentObjectId= ${parentObjectId}`,
    });
  }
};

/**
 * Формирование группы во всплывающем окне
 * @param {array} data
 * @param {string} groupId
 * @param {string} parentObjectId
 */
export const getGroupCompleteWorker = function*({
  payload,
}: getGroupCompleteWorkerType): any {
  const { data, groupId, parentObjectId } = payload;

  const [
    camerasStore,
    groupsStore,
    objectsStore,
    parentObjectSelect,
  ] = yield all([
    select(camerasSelector),
    select(groupsSelector),
    select(objectsStateSelector),
    /**
     * Тут отбор элемента в objectState в store по элементу id
     */
    select(objectStateSelector, { id: parentObjectId }),
  ]);

  /**
   * Занести полученные данные с сервера в store
   * в camerasStore
   *     в groupsStore
   *    в objectsStore
   */
  for (let i = 0; i < data.length; i++) {
    //Заносим папку в группы
    if (data[i].OBJECT === objectTypes.GROUP) {
      mapObjectWithProperty(groupsStore, data[i], "ID");
    }
    //Заносим камеру в камеры
    if (data[i].OBJECT === objectTypes.CAMERA) {
      mapObjectWithProperty(camerasStore, data[i], "ID");
    }

    //Выстраиваем корректный порядок в objectsStore
    mapObjectWithProperty(
      objectsStore,
      {
        ...ObjectStateSchema,
        id: createFormattedActiveParentObject(groupId, data[i].ID),
        relationId: data[i].ID,
        RELATION_OBJECT: data[i].OBJECT,
        parentId: parentObjectId,
      },
      "id"
    );

    /*
                                                                                                                                                                      Проверяем что родитель есть и что его св-во children является массивом
                                                                                                                                                                     */
    if (parentObjectSelect && Array.isArray(parentObjectSelect.children)) {
      parentObjectSelect.children = [
        ...parentObjectSelect.children,
        createFormattedActiveParentObject(groupId, data[i].ID),
      ];
      parentObjectSelect.isLoaded = true;
    }
  }

  yield put(
    updateListAction({
      cameras: { ...camerasStore },
      groups: { ...groupsStore },
      objectsState: { ...objectsStore },
    })
  );
};

/**
 * Запускается при обновлении страницы
 * или при первом запуске страницы
 */
const listInitStartWorker = function*(): any {
  // Здесь восстанавливаем последнюю выбранную группу.

  const [restoredParentData, selectedObjectsFromLs] = yield all([
    getCurrentGroupFromLs(),
    getSelectedCamsFromLs(),
  ]);

  // Флаг, что ничего не было восстанослено из ls
  const noRestoredParentData =
    restoredParentData == null || typeof restoredParentData === "undefined";

  /**
   * Выбранные объекты в LS
   * Проверяем что данный объект LS существует или если ключ не пустой то элементы там есть
   */

  const noSelectedObject =
    selectedObjectsFromLs === null || selectedObjectsFromLs.length === 0;

  const validatedRestoredParentData = restoredParentData
    ? restoredParentData
    : STREETS_ONLINE_CURRENT_GROUP;

  //тут потенциально крах и провал.
  if (noSelectedObject && noRestoredParentData) {
    const objectsState = yield select(objectsStateSelector);

    /**
     * Грузим 16 для имен групп в улицах
     */
    yield put(getGroupAction("0", null));
    //Дождаться выполнение всех действий и вернуться сюда

    yield take(
      //TODO: усилить типизацию в будущих коммитах
      (action: any) =>
        action.type === actionTypes.GET_GROUP_COMPLETE &&
        action.payload &&
        action.payload.groupId === "0"
    );

    /**
     * Грузим 16 для имен групп в улицах
     */
    yield put(getGroupAction("16", "0_16"));
    //Дождаться выполнение всех действий и вернуться сюда

    yield take(
      //TODO: усилить типизацию в будущих коммитах
      (action: any) =>
        action.type === actionTypes.GET_GROUP_COMPLETE &&
        action.payload &&
        action.payload.groupId === "16"
    );

    /**
     * @todo добавить контроллер определения города
     *  если ничего нет в localStorage то Челябинск
     *
     */
    yield put(getGroupAction(defaultCity, defaultParentCity));

    //Дождаться завершения формирования группы затем тут продолжить
    yield take(
      //TODO усилить типизацию в будущих коммитах
      (action: any) =>
        action.type === actionTypes.GET_GROUP_COMPLETE &&
        action.payload &&
        action.payload.groupId === defaultCity
    );
    /**
     * Устаналиваем активным родителем Челябинск
     */
    yield put(setActiveObjectIdAction(defaultParentCity));

    /**
     * Из тех значений что есть в objectsState в store значения объекта
     *  далее фильтруем те что значения в которых есть id и его значение начинается с 239__
     * В итоге мы складываем только отобранные idшники
     */
    const defaultObjectsIds: Array<string> = Object.values(objectsState.byId)
      .filter((item: any) => item.id && item.id.startsWith("239_"))
      .map((item: any) => item.id);

    yield setSelectedCamsToLs(defaultObjectsIds);

    const defaultData = { ID: 239, NAME: "Челябинск" };
    const { ID, NAME } = defaultData;

    yield put(updateFullSelectedGroupsAction([defaultData]));

    let activeObjectId = defaultParentCity;

    /**
     * Сохраняем текущую группу в LS
     */
    yield setCurrentGroupToLs({ activeObjectId, NAME });

    /**
     * Устаналиваем текущую группу в массив всех выделенных групп LS
     */
    const selectGroupLs = [];
    selectGroupLs.push({ ID, NAME });
    yield setAllSelectedGroupsToLs(selectGroupLs);
  } else {
    //Необходимо чтобы выводить вверху группу
    // Тут мы ставим активную группу в т.ч. с расчетом что ее нет в состоянии, при перезагрузки страницы.
    // Второй аргумент говорит о том, что логика ниже будет работать как для инициализации.

    yield put(
      setActiveObjectIdAction(validatedRestoredParentData.activeObjectId, true)
    );
    yield restoreSelectedFromLs();
  }
  yield put(initListCompleteAction());
};

/**
 * Создать подменю бокового меню
 */
const createSubMenuWorker = function*(): any {
  try {
    const { data, timeout } = yield race({
      data: call([apiMethods, apiMethods.getGetGroup], "16"),
      timeout: delay(DEFAULT_FETCH_TIMEOUT),
    });
    if (timeout) {
      throw new Error(
        `Request timeout in createSubMenuWorker. More ${DEFAULT_FETCH_TIMEOUT}`
      );
    }
    const preparedResult = prepareResult(data);

    yield put(setCitiesAction(preparedResult));
    yield setSubmenuOptionsToLs(preparedResult);
  } catch (e) {
    sendErrorToSentry(
      `Ошибка получения списка для подменю. СreateSubMenuWorker${e}`
    );
  }
};

/**
 * Восстановить данные из localStorage
 *
 * @param {*} currentGroupFromLs -текущая группа из ls
 * @param {*} selectedCamsFromLs - выбранные камеры из ls
 * @param {*} selectedViewLs
 * @param {*} calculatedGridNum
 * @param {*} groups
 * @param {*} objectsState
 *
 */
const restoreSelectedFromLs = function*(): any {
  const [
    currentGroupFromLs,
    selectedCamsFromLsRaw,
    allSelectedGroupLs,
    fullSelectedGroupLs,
    groups,
    objectsState,
    cameras,
  ] = yield all([
    getCurrentGroupFromLs(),
    getSelectedCamsFromLs(),
    getAllSelectedGroupsFromLs(),
    getFullSelectedGroupsFromLs(),
    select(groupsSelector),
    select(objectsStateSelector),
    select(camerasSelector),
  ]);

  /**
   * Получаем все данные по выставлению визуала.
   */
  const selectedViewLs: Promise<string | number> = getSelectedViewFromLs();
  const calculatedGridNum: Promise<number> = getCalculatedGridNumFromLs();

  yield put(changeViewTypeAction(selectedViewLs));

  yield put(setCalculatedNumAction(calculatedGridNum));

  //Если по какой то причине данных по количеству ячеек в калькуляторе нет то ставим по умолчанию FOUR_PANEL
  const defaultGridNum = 3;
  if (calculatedGridNum === null) {
    yield put(setCalculatedNumAction(defaultGridNum));
    yield put(changeViewTypeAction(viewTypes.FOUR_PANEL));
  }

  let { activeObjectId, NAME } = currentGroupFromLs || {};
  if (!activeObjectId) {
    return false;
  }

  //Только группы камер
  const newGroups: Array<string> = [];
  //Только ID камер
  const realIds: Array<string> = [];
  //Собираем ID камер и определяем какие группы камер у нас лежат в LS

  const selectedCamsFromLs = selectedCamsFromLsRaw || [];
  selectedCamsFromLs.forEach((el: string) => {
    let groupOfCamera = getParentObjectKey(el);

    if (
      !newGroups.includes(groupOfCamera) &&
      getIdByObjectKey(activeObjectId) !== groupOfCamera
    ) {
      newGroups.push(groupOfCamera);
    }

    realIds.push(getIdByObjectKey(el));
    mapObjectWithProperty(cameras, { ID: getIdByObjectKey(el) }, "ID");
  });

  let ID = getIdByObjectKey(activeObjectId);
  //Важно при восстановлении воссоздать структуру групп
  mapObjectWithProperty(groups, { ID, NAME, OBJECT: objectTypes.GROUP }, "ID");

  //Проверяем что массив новых групп не пустой и только тогда добавляем их в redux
  if (newGroups.length !== 0 && allSelectedGroupLs) {
    newGroups.forEach(el => {
      let elName = allSelectedGroupLs.filter(
        (currentObject: restoreSelectedFromLsWorkerAllSelectedGroupLsType) => {
          return currentObject.id !== el.toString();
        }
      );
      mapObjectWithProperty(
        groups,
        { ID: el.toString(), NAME: elName, OBJECT: objectTypes.GROUP },
        "ID"
      );
    });
  }

  //Активный родитель не может равняться undefined
  if (currentGroupFromLs.activeObjectId !== "undefined") {
    //Важно при восстановлении структуру текущего объекта
    mapObjectWithProperty(
      objectsState,
      {
        ...ObjectStateSchema,
        id: currentGroupFromLs.activeObjectId,
        relationId: ID,
        RELATION_OBJECT: objectTypes.GROUP,
        parentId: "0_16",
      },
      "id"
    );
  } else {
    //@todo почему это не может?
    sendErrorToSentry(`Активный родитель не может равняться undefined`, {
      place: "src/modules/flist/saga.js",
    });
  }

  //Проверяем что массив новых групп не пустой и только тогда добавляем их в redux
  if (newGroups.length !== 0) {
    newGroups.forEach(el => {
      mapObjectWithProperty(
        objectsState,
        {
          ...ObjectStateSchema,
          id: createFormattedActiveParentObject("16", el),
          relationId: ID,
          RELATION_OBJECT: objectTypes.GROUP,
          parentId: "0_16",
        },
        "id"
      );
    });
  }

  //Получить все корневую директорию
  yield put(getGroupAction("0", "null"));
  //Вернуться сюда после окончания действия вверху
  yield take(
    //TODO усилить типизацию в будущих коммитах
    (action: any) =>
      action.type === actionTypes.GET_GROUP_COMPLETE &&
      action.payload &&
      action.payload.groupId === "0"
  );

  //Получить все основные группы городов
  yield put(getGroupAction("16", "0_16"));
  //Вернуться сюда после окончания действия вверху
  yield take(
    //TODO усилить типизацию в будущих коммитах
    (action: any) =>
      action.type === actionTypes.GET_GROUP_COMPLETE &&
      action.payload &&
      action.payload.groupId === "16"
  );
  yield put(getGroupAction(ID, currentGroupFromLs.activeObjectId));
  // eslint-disable-next-line
  //Пробуем получить данные
  const { success, error } = yield race({
    success: take(
      //TODO усилить типизацию в будущих коммитах
      (action: any) =>
        action.type === actionTypes.GET_GROUP_COMPLETE &&
        action.payload &&
        action.payload.groupId === ID
    ),
    error: take(
      //TODO усилить типизацию в будущих коммитах
      (action: any) =>
        action.type === actionTypes.GET_GROUP_ERROR &&
        action.payload &&
        action.payload.groupId === ID
    ),
  });
  if (success && ID) {
    yield take(actionTypes.UPDATE_LIST);
    const updatedObjectsState = yield select(objectsStateSelector);
    if (selectedCamsFromLs === null) return true;
    if (updatedObjectsState && updatedObjectsState.byId) {
      const selectedObjects = Object.values(updatedObjectsState.byId)
        .filter(
          //TODO усилить типизацию в будущих коммитах
          (item: any) =>
            selectedCamsFromLs[item.id] &&
            selectedCamsFromLs[item.id].relationId === item.relationId
        )
        //TODO усилить типизацию в будущих коммитах
        .map((item: any) => item.id);
      if (Array.isArray(selectedObjects) && selectedObjects.length > 0) {
        yield put(setActiveObjectIdAction("0_16"));
        return true;
      }
    }
  } else if (error) {
    sendErrorToSentry(`Ошибка при восстановлении групп ${error}`);
  }

  if (realIds && realIds.length > 0) {
    yield put(getLimitedInfoStartAction(realIds, selectedCamsFromLs));
  }

  // Восстанавление списка полностью выбранных групп
  if (fullSelectedGroupLs && fullSelectedGroupLs.length > 0) {
    yield put(updateFullSelectedGroupsAction(fullSelectedGroupLs));
  }

  return false;
};

//@todo пилить на 2 селектора - для камеры и для группы.

/**
 * Срабатывает при выборе камеры
 * Обработчик actionа SELECT_TOGGLE_START
 * @param {string} id
 */
export const selectStartWorker = function*({
  payload,
}: selectStartWorkerType): any {
  const { id } = payload;

  const [
    objects,
    currentCameras,
    currentGroups,
    currentFullSelectedGroups,
  ] = yield all([
    select(objectsStateSelector),
    select(camerasSelector),
    select(groupsSelector),
    select(fullSelectedGroupsSelector),
  ]);

  let toggleIds: Array<string> = [];

  const [groupId, realId] = id.split("_");

  const groupAlreadySelected = currentFullSelectedGroups.find(
    (selectedObject: fullSelectedGroup) => selectedObject.ID === Number(realId)
  );
  const cameraInAlreadySelected = currentFullSelectedGroups.find(
    (selectedObject: fullSelectedGroup) => selectedObject.ID === Number(groupId)
  );

  //Если мы выбрали единичную камеру
  if (objects.byId[id].RELATION_OBJECT === objectTypes.CAMERA) {
    toggleIds.push(id);
    // Группа перестает быть полностью выбранной, если исключается элемент из этой группы
    if (cameraInAlreadySelected) {
      const filteredAllSelectedGroups = currentFullSelectedGroups.filter(
        (selectedObject: fullSelectedGroup) =>
          selectedObject.ID !== Number(groupId)
      );
      yield put(updateFullSelectedGroupsAction(filteredAllSelectedGroups));
    }
    //Проверяем нужно ли актуализаровать.
    if (currentCameras.byId[realId]) {
      yield put(getLimitedInfoStartAction([realId], [id]));
    }
  } else {
    // если это группа (Если мы выбрали в FCamListheader - все камеры которые в списке)
    if (
      objects.byId[id] &&
      objects.byId[id].RELATION_OBJECT === objectTypes.GROUP &&
      Array.isArray(objects.byId[id].children) &&
      objects.byId[id].children.length > 0
    ) {
      //Создаем новый массив чтобы не было ссылки на объект ссылки.
      const childrenIdsOfGroup = createNewArrayWithoutLink(
        objects.byId[id].children
      );

      //Здесь мы проверяем что в массив выбраннных для обновления камер не попали случайно группы иначе мы получим ошибка
      const filteredIds = childrenIdsOfGroup.filter((el: string) => {
        return objects.byId[el].RELATION_OBJECT === objectTypes.CAMERA;
      });

      toggleIds = toggleIds.concat(filteredIds);

      // если группа есть - исключаем
      if (groupAlreadySelected) {
        const filteredFullSelectedGroups = currentFullSelectedGroups.filter(
          (selectedObject: fullSelectedGroup) =>
            selectedObject.ID !== Number(realId)
        );
        yield put(updateFullSelectedGroupsAction(filteredFullSelectedGroups));
      } else {
        currentFullSelectedGroups.push({
          ID: Number(realId),
          NAME: currentGroups.byId[realId].NAME,
        });
        yield put(updateFullSelectedGroupsAction(currentFullSelectedGroups));
      }
    }
  }
};

/**
 * Проверить не требуется ли обновить список fullSelectedgroups
 * Может при выборе текущего id выбрались все камеры текущей группы.
 * @param {string} id
 */
export const checkFullSelectedGroupsWorker = function*({ payload }: any): any {
  const { selectedObjects } = payload;

  //Получаю текущий список элементов из objectsStateStore и Получаю текущий  список  fullSelectedGroups
  const [objectsStateStore, groupsStore] = yield all([
    select(objectsStateSelector),
    select(groupsSelector),
  ]);

  const allGroups: Set<string> = new Set();
  const newFullSelectedGroups: Set<string> = new Set();

  /**
   * Нам нужно собрать все группы из которых состоят наши выбранные элементы
   */
  selectedObjects.forEach((el: string) => {
    if (objectsStateStore?.byId[el]?.parentId) {
      allGroups.add(objectsStateStore.byId[el].parentId);
    }
  });

  const allGroupsArr: Array<string> = Array.from(allGroups.values());

  //Удобнее будет работать с массивом
  const objectsStateStoreArr = createArrFromObj(objectsStateStore.byId);

  allGroupsArr.forEach((element: string) => {
    //Получаю сколько должно всего камер должно быть в группе
    const childrenLength = objectsStateStoreArr.filter(
      (el: objectsState) =>
        el.parentId === element && el.RELATION_OBJECT === "CAMERA"
    ).length;

    const childrenOfGroup = objectsStateStore.byId[element].children;
    //Считаю сколько среди выбранных камер - камер которые принадлежат именно текущей группе
    const selectedLength = selectedObjects.filter((el: string) =>
      childrenOfGroup.includes(el)
    ).length;
    //Если выбраны все значит нужно добавить в полностью выбранные группы
    if (selectedLength === childrenLength) {
      newFullSelectedGroups.add(element);
    }
  });

  let newFullSelectedGroupsArr: fullSelectedGroup[] = [];

  newFullSelectedGroups.forEach((el: string) => {
    //TODO: Используем склееные id тут
    const idOfGroup = getIdByObjectKey(el);
    const nameOfGroup = groupsStore.byId[idOfGroup].NAME;
    if (idOfGroup && nameOfGroup) {
      newFullSelectedGroupsArr.push({ NAME: nameOfGroup, ID: +idOfGroup });
    }
  });

  //Если выбраны все тогда обновляю fullSelectedGroups - если количество группа полоностью заполнена
  yield put(updateFullSelectedGroupsAction(newFullSelectedGroupsArr));
  //Иначе группа не заполнена удаляю из fullSelectedGroups
};

/**
 * Сохранение текущей группы LS если вдруг мы до этого еше не сделала
 *  @param {string} id, - активный родитель
 */
export const saveCurrentGroupToLsWorker = function*({
  payload,
}: saveCurrentGroupToLsWorkerType): any {
  // это текущий id группы в формате "parent_id"
  const { id: activeObjectId } = payload;

  const groupsStore = yield select(groupsSelector);

  //null - корень.
  if (activeObjectId === null) {
    setCurrentGroupToLs(STREETS_ONLINE_CURRENT_GROUP);
  } else {
    //если поиск - пока не сохраняем.
    if (activeObjectId.startsWith("search")) return;
    try {
      if (validateActiveGroup(activeObjectId)) {
        // если переданная группа валидна - начинаем обработку.
        const [groupId] = activeObjectId.split("_");
        // если есть в сторе - обрабатываем
        if (groupsStore.byId[groupId]) {
          if (typeof groupsStore.byId[groupId].NAME == "string") {
            //если все нормально - сохраняем.
            const NAME = groupsStore.byId[groupId].NAME;
            setCurrentGroupToLs({ ID: activeObjectId, activeObjectId, NAME });
          } else {
            // если переданная группа не валидна ставим 0_16 группу и имя Список камер
            throw new Error(
              `Переданная для сохранения в LS содержит не корректные значения ${groupsStore.byId[groupId]}`
            );
          }
        } else {
          // если нужной группы нет в сторе - выставляем значение по умолчанию. В будущем может нужно сделать дозагрузку?
          throw new Error(
            `Переданная для сохранения в LS текущая группа отсутсвует в состоянии ${activeObjectId}`
          );
        }
      } else {
        // если переданная группа отсутствует в сторе - ставим 0_16, @Список камер@
        throw new Error(
          `Переданная для сохранения в LS текущая группа не валидна ${activeObjectId} -> `
        );
      }
    } catch (error) {
      setCurrentGroupToLs(STREETS_ONLINE_CURRENT_GROUP);
      sendErrorToSentry(error);
    }
  }
};
