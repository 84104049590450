import React from "react";

import {
  Button,
  ScrollTopIconStyled,
  ScrollBottomIconStyled,
} from "./styled-components";

const ScrollTopBtn = ({
  visibleBackStatus: visibleBackStatusProps,
  visibleTopStatus: visibleTopStatusProps,
  handleClickGoBack: handleClickGoBackProps,
  handleClickGoTop: handleClickGoTopProps,
}: ScrollTopBtnProps) => {
  return visibleBackStatusProps ? (
    <Button visible={visibleBackStatusProps} onClick={handleClickGoBackProps}>
      <ScrollBottomIconStyled />
      Назад
    </Button>
  ) : (
    <Button visible={visibleTopStatusProps} onClick={handleClickGoTopProps}>
      <ScrollTopIconStyled />
      Наверх
    </Button>
  );
};

ScrollTopBtn.displayName = "ScrollTopBtn";
export default ScrollTopBtn;
