import { NewFlistSearchSchema } from "./types/schemaTypes";

export const StateSchema: NewFlistSearchSchema = {
  searchString: "",
  buildIdState: 0,
  currentChainElementState: null,
  currentSelectOptionsState: [],
  currentChainSearchString: "",
  currentPage: 1,
  lastOptions: [],
  perPage: 15,
  statusScroll: false,
};
