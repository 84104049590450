import HeaderMenuView from "../../views/HeaderMenu";
import { connect } from "react-redux";
import {
  logOutAction,
  userNameSelector,
  authorizedSelector,
  authInProgressSelector,
} from "../../../modules/user";
import { Dispatch } from "redux";

const mapDispatchToProps = (dispatch: Dispatch) => ({
  logOut: () => dispatch(logOutAction()),
});

const mapStateToProps = (state: any) => ({
  authorized: authorizedSelector(state),
  authInProgress: authInProgressSelector(state),
  username: userNameSelector(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(HeaderMenuView);
