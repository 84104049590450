import {
  fork,
  takeLatest,
  put,
  all,
  call,
  race,
  delay,
  select,
} from "redux-saga/effects";
import { generateUuidv4 } from "@helpers/user";

import {
  actionTypes,
  authCompleteAction,
  authErrorAction,
  updateUsernameAction,
  frontendIsReadyStatusAction,
  openMobileCodeModalAction,
  setMobileNumberAction,
  setUniqueDeviceIdAction,
  getMobileAuthTokenAction,
  setCurrentStepAction,
  setUserMobileAddressesOptionsAction,
  setUserMobileAddressAction,
  setAuthIdAction,
  setMobileAuthCodeRequestInProgressAction,
  setMobileAuthCodeRequestTimeoutErrorAction,
} from "./actions";

import { setPlayAllAction, cleanActiveCameras } from "../streetsOnline";

//@ts-ignore
import { push } from "react-router-redux";

import { filterActiveCamerasAction } from "../streetsOnline";

import {
  userNameSelector,
  mobileNumberSelector,
  uniqueDeviceIdStoreSelector,
  userMobileAddressesOptionsSelector,
  authIdSelector,
  typeAuthSelector,
} from "./selectors";

import { getLimitedInfoStartAction } from "../flist";

import {getSelectedCamsFromLs, setSelectedCamsToLs} from "@helpers/newFlist";

import apiMethods, { setToken } from "@helpers/api/apiMethods";
import { DEFAULT_FETCH_TIMEOUT } from "@helpers/api";
import {
  setLoginToLs,
  getLoginFromLs,
  setLastCodeRequestTime,
} from "@helpers/user";
import { requestForSagaWorker } from "@helpers/api/requestWrappers";
import {
  getAuthTokenFromLs,
  isValidToken,
  setTokenToLs,
  removeTokenFromLs,
} from "@helpers/authTokens";
import { removeCurrentPathModalCamlistFromLs } from "@helpers/newFlist";

import sendErrorToSentry from "@helpers/sentry";

import { notifyError, notifySuccess } from "@helpers/toast";

/**
 * newFlist
 */
import {
  changeGroupStartAction,
  checkCamerasForRightsAction,
  setSelectedObjectsStartAction,
} from "../newFlist";
import { mobileAuthSteps } from "@helpers/mobileAuth";
import LocalStorage from "@helpers/storage/LocalStorage";
import LocalStorageKeys from "@helpers/storage/localStorageKeys";
import { AUTH_TYPES } from "./types/userActionsTypes";

export const saga = function*() {
  yield all([fork(authSagaWatcher), fork(authMobileSagaWatcher)]);
};

export const authSagaWatcher = function*() {
  yield all([
    takeLatest(actionTypes.AUTH_START as any, authStartWorker),
    takeLatest(actionTypes.AUTH_COMPLETE as any, authCompleteWorker),
    takeLatest(actionTypes.LOG_OUT as any, authLogOutWorker),
    takeLatest(actionTypes.UPDATE_TOKEN as any, updateTokenWorker),
  ]);
};

export const authMobileSagaWatcher = function*() {
  yield all([
    takeLatest(
      actionTypes.AUTH_TYPE_MOBILE_START as any,
      authTypeMobileStartWorker
    ),
    takeLatest(actionTypes.SEND_MOBILE_CODE as any, sendMobileCodeWorker),
    takeLatest(
      actionTypes.GET_MOBILE_AUTH_TOKEN as any,
      getMobileAuthTokenWorker
    ),
    takeLatest(
      actionTypes.CHOOSE_USER_MOBILE_ADDRESS as any,
      chooseUserMobileAddressWorker
    ),
    takeLatest(
      actionTypes.HANDLE_REPEAT_CALL_MOBILE_AUTH as any,
      handleRepeatCallMobileAuthWorker
    ),
  ]);
};

export const updateTokenWorker = function*({
  payload,
}: updateTokenWorkerType): any {
  const { token } = payload;
  try {
    const { timeoutCheck } = yield race({
      token: call([apiMethods, apiMethods.checkToken]),
      timeout: delay(DEFAULT_FETCH_TIMEOUT),
    });
    if (timeoutCheck) {
      const error = new Error("Превышено время ожидания для checkToken");
      sendErrorToSentry(`Превышено время ожидания для checkToken`);
      throw error;
    }

    // Если получили 200 значит токен валиден. Берем мета-дату.
    const tokenData = yield requestForSagaWorker({
      requestRouteName: apiMethods.getTokenInfo,
    });
    if (tokenData.failed) {
      // только если токен уже не валиден.
      const isValid = yield call(isValidToken, token);
      if (!isValid) {
        const error = new Error("Превышено время ожидания для getTokenInfo");
        sendErrorToSentry(token + `Превышено время ожидания для getTokenInfo`);
        throw error;
      } else {
        yield put(authCompleteAction(token));
      }
    } else {
      yield put(authCompleteAction(tokenData));
    }
  } catch (e) {
    yield put(authErrorAction("Не удалось обновить токен"));
    sendErrorToSentry(`Не удалось обновить токен ${e} `, { token: token });
  }
  yield put(authCompleteAction(token));
};

export const authStartWorker = function*({
  payload,
}: authStartWorkerType): any {
  const { username, password, days } = payload;

  const token = yield call(getAuthTokenFromLs);
  const isValid = yield call(isValidToken, token);
  if (isValid) {
    yield put(authCompleteAction(token));
    return;
  }
  yield call(removeTokenFromLs);
  try {
    const result = yield requestForSagaWorker({
      requestRouteName: apiMethods.auth,
      requestProps: {
        username,
        password,
        days,
      },
      requestTimeout: DEFAULT_FETCH_TIMEOUT,
    });

    if (result.failed) {
      sendErrorToSentry("Ошибка авторизации по логину", null, true);
      yield put(authErrorAction("Ошибка авторизации"));
      if (result?.errorMsg[0]?.message) {
        let errorMsg = result?.errorMsg[0]?.message;
        if (result?.errorMsg[0]?.message.indexOf("(err") > -1) {
          errorMsg = result?.errorMsg[0]?.message.slice(
            0,
            result?.errorMsg[0]?.message.indexOf("(err")
          );
        }
        notifyError(errorMsg);
      } else if (result?.errorMsg?.message) {
        let errorMsg = result?.errorMsg?.message;
        if (errorMsg.indexOf("(err") > -1) {
          errorMsg = errorMsg.slice(0, errorMsg.indexOf("(err"));
        }
        notifyError(errorMsg);
      }
    } else {
      //Если все успешно заносим данные по логину в localStorage
      yield setLoginToLs(username);
      yield put(authCompleteAction(result));
    }
  } catch (e) {
    let message = "Неизвестная ошибка";
    if (e.canShowUser && e.message) {
      message = e.message;
    }
    if (e && e.response && e.response.status === 422) {
      message = "Неверный логин или пароль";
    }
    sendErrorToSentry(`Ошибка при авторизации + ${e.message}`);
    yield put(authErrorAction(message));
  }
};

export const authLogOutWorker = function*(): any {
  //Обнуляем токен авторизации
  yield call(removeTokenFromLs);

  yield call(setToken);
  //Обнуляю localStorage с логином
  yield setLoginToLs("");

  //Убираем старые камеры, которые ранее были доступны зарегистрированному пользователю
  const selectedCamsFromLs = yield call(getSelectedCamsFromLs);
  if (selectedCamsFromLs.length) {
    yield put(checkCamerasForRightsAction(selectedCamsFromLs));
  } else {
    yield put(setSelectedObjectsStartAction([]));
  }

  //обновляем список доступных камер после выхода из личного кабинета
  yield put(changeGroupStartAction(0));
  yield put(frontendIsReadyStatusAction(false));

  //Скидываем путь CURRENT_PATH_MODAL_CAMLIST
  yield call(removeCurrentPathModalCamlistFromLs);

  const camerasFromLsRaw = getSelectedCamsFromLs();

  if (camerasFromLsRaw?.length) {
    yield put(setPlayAllAction(false));
  }
  yield put(cleanActiveCameras());

  yield put(push("/"));
};

export const authCompleteWorker = function*({
  payload,
}: authCompleteWorkerType): any {
  const { data } = payload;
  //Переназначаем логин с localStorage в store
  const userName = yield select(userNameSelector);

  const authMethod: AUTH_TYPES = yield select(typeAuthSelector);

  LocalStorage.set(LocalStorageKeys.LAST_AUTH_METHOD, authMethod);

  if (!userName) {
    let userNameLs = getLoginFromLs();
    let dataUser = { username: userNameLs };
    yield put(updateUsernameAction(dataUser));
  }

  yield call(setTokenToLs, data);
  yield call(setToken);

  //Очищаем все активные камеры
  yield put(filterActiveCamerasAction([]));

  if (getSelectedCamsFromLs() === null) setSelectedCamsToLs([]);
  const camerasFromLsRaw = getSelectedCamsFromLs();

  if (camerasFromLsRaw?.length) {
    const loadingLimitedIds: string[] = [];
    //Нам важно понять какой тип данных там лежит
    if (camerasFromLsRaw[0].toString().includes("_")) {
      camerasFromLsRaw.forEach((camera: string) => {
        if (camera) {
          const [rid] = camera.split("_");
          loadingLimitedIds.push(rid);
        }
      });
    } else {
      camerasFromLsRaw.forEach((camera: any) => {
        loadingLimitedIds.push(camera.toString());
      });
    }

    yield put(getLimitedInfoStartAction(loadingLimitedIds, camerasFromLsRaw));
  } else {
    yield put(setSelectedObjectsStartAction([]));
  }

  /**
   * yield put(setSelectedObjectsStartAction(selectedCamerasStore));
   */
  if (camerasFromLsRaw?.length)
    yield put(setSelectedObjectsStartAction(camerasFromLsRaw));
  yield put(frontendIsReadyStatusAction(true));
};

export const authTypeMobileStartWorker = function*({
  payload,
}: IAuthTypeMobileStartWorker): any {
  const { mobileNumber } = payload;

  // Запрос кода в процессе
  yield put(setMobileAuthCodeRequestInProgressAction(true));

  // Очистка ошибки по таймауту
  yield put(setMobileAuthCodeRequestTimeoutErrorAction(false));

  //Нужно очистить старую авторизацию
  //Обнуляем токен авторизации
  yield call(removeTokenFromLs);

  //Обнуляю localStorage с логином
  yield setLoginToLs("");

  const phone = mobileNumber.slice(-10);
  yield put(setMobileNumberAction(phone));

  let uniqueDeviceId;
  try {
    uniqueDeviceId = generateUuidv4().replaceAll("-", "");
  } catch (error) {
    sendErrorToSentry(error, "Ошибка генерации uuid токена");
  }

  if (uniqueDeviceId) {
    yield put(setUniqueDeviceIdAction(uniqueDeviceId));

    const result = yield requestForSagaWorker({
      requestRouteName: apiMethods.askForMobileCode,
      requestProps: {
        phone,
        uniqueDeviceId,
      },
    });

    yield put(setMobileAuthCodeRequestInProgressAction(false));

    if (result.failed) {
      sendErrorToSentry("Ошибка получения кода", null, true);
      if (result?.errorMsg[0]?.message.includes("2 минут")) {
        yield put(setCurrentStepAction(mobileAuthSteps.codeStep));
        yield put(openMobileCodeModalAction(true));
        yield put(setMobileAuthCodeRequestTimeoutErrorAction(true));
      }
      if (result?.errorMsg[0]?.message) {
        let errorMsg = result?.errorMsg[0]?.message;
        if (result?.errorMsg[0]?.message.indexOf("(err") > -1) {
          errorMsg = result?.errorMsg[0]?.message.slice(
            0,
            result?.errorMsg[0]?.message.indexOf("(err")
          );
        }
        notifyError(errorMsg);
      }
    } else {
      yield setLastCodeRequestTime(Date.now());
      yield put(setCurrentStepAction(mobileAuthSteps.codeStep));
      yield put(openMobileCodeModalAction(true));
      yield put(setMobileAuthCodeRequestTimeoutErrorAction(false));
    }
  } else {
    notifyError("Ошибка входа. Пожалуйста попробуйте еще раз");
  }
};

export const handleRepeatCallMobileAuthWorker = function*(): any {
  const [phoneStore, uniqueDeviceIdStore] = yield all([
    select(mobileNumberSelector),
    select(uniqueDeviceIdStoreSelector),
  ]);

  const result = yield requestForSagaWorker({
    requestRouteName: apiMethods.askForMobileCode,
    requestProps: {
      phone: phoneStore,
      uniqueDeviceId: uniqueDeviceIdStore,
    },
  });
  if (result.failed) {
    sendErrorToSentry("Ошибка получения кода", null, true);
    if (result?.errorMsg[0]?.message) {
      notifyError(result.errorMsg[0].message.replace("(error=4)", ""));
    }
  }
};

export const sendMobileCodeWorker = function*({
  payload,
}: ISendMobileCodeWorker): any {
  const { confirmCode } = payload;

  const mobileNumberStore = yield select(mobileNumberSelector);

  const response = yield requestForSagaWorker({
    requestRouteName: apiMethods.sendMobileCode,
    requestProps: {
      confirmCode,
      phone: mobileNumberStore,
    },
  });

  /**
   * Только если доступных адресов
   * больше одного выводим следующий шаг
   */

  if (response.failed) {
    sendErrorToSentry("sendMobileCodeWorker Ошибка кода", null, true);
    if (response?.errorMsg[0]?.message) {
      notifyError(response.errorMsg[0].message);
    }
  } else {
    const { authId, addresses } = response;
    if (authId && addresses.length > 1) {
      yield put(setUserMobileAddressesOptionsAction(addresses));
      yield put(setCurrentStepAction(mobileAuthSteps.addressesStep));
      yield put(setAuthIdAction(authId));
      //Если адрес тока один, то минуем выбор адреса
    } else if (authId && addresses.length === 1) {
      yield put(setUserMobileAddressAction(addresses[0].ADDRESS));
      yield put(getMobileAuthTokenAction(authId, addresses[0].USER_ID));
    } else if (authId && addresses.length === 1) {
      yield put(getMobileAuthTokenAction(authId));
    } else {
      sendErrorToSentry("Ошибка при входе через мобильный телефон");
    }
  }
};

export const getMobileAuthTokenWorker = function*({
  payload,
}: IGetMobileAuthTokenWorker): any {
  const { authId, userId } = payload;

  const uniqueDeviceId = yield select(uniqueDeviceIdStoreSelector);

  const response = yield requestForSagaWorker({
    requestRouteName: apiMethods.sendMobileAuthToken,
    requestProps: {
      uniqueDeviceId,
      authId,
      ...(userId && { userId }),
    },
  });
  if (response.failed) {
    notifyError("Ошибка получения токена. Пожалуйста авторизуйтесь еще раз");
  } else {
    if (response.PHONE) {
      notifySuccess("Успешно авторизованы");
      yield setLoginToLs(response.PHONE);
      yield put(authCompleteAction(response));
      yield put(openMobileCodeModalAction(false));
    } else {
      sendErrorToSentry(
        "Ошибка авторизации по телефону. Отсутствует ключ PHONE в ответе getMobileAuthTokenWorker"
      );
    }
  }
};

export const chooseUserMobileAddressWorker = function*({
  payload,
}: IChooseUserMobileAddressWorker): any {
  const { userMobileAddress } = payload;

  const [userMobileAddressesOptionsStore, authIdStore] = yield all([
    select(userMobileAddressesOptionsSelector),
    select(authIdSelector),
  ]);

  const newUserMobileAddressesOptions = [...userMobileAddressesOptionsStore];

  const filteredUserMobileAddress: IUserMobileAddressesOptions[] = newUserMobileAddressesOptions.filter(
    (el: IUserMobileAddressesOptions) => el.ADDRESS === userMobileAddress
  );

  if (filteredUserMobileAddress[0].USER_ID) {
    yield put(
      getMobileAuthTokenAction(
        authIdStore,
        filteredUserMobileAddress[0].USER_ID
      )
    );
    yield put(setUserMobileAddressAction(userMobileAddress));
  }
};
