import { connect } from "react-redux";
import { Dispatch } from "redux";

import MobileAuthCodeStep from "../../views/MobileAuthCodeStep";

import {
  sendMobileCodeAction,
  handleRepeatCallMobileAuthAction,
} from "../../../modules/user";

import {
  mobileAuthCodeStatusStoreSelector,
  currentStepSelector,
} from "../../../modules/user";

const mapDispatchToProps = (dispatch: Dispatch) => ({
  sendMobileCode: (code: string) => dispatch(sendMobileCodeAction(code)),
  handleRepeatCallMobileAuth: () =>
    dispatch(handleRepeatCallMobileAuthAction()),
});

const mapStateToProps = (state: any) => ({
  statusModal: mobileAuthCodeStatusStoreSelector(state),
  currentStep: currentStepSelector(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(MobileAuthCodeStep);
