import { createSelector } from "reselect";
import { moduleName } from "./module";
import { NewFlistSearchSchema } from "./types/schemaTypes";
import { TRootState } from "@store/store";

export const searchSimpleState = (state: TRootState) => state[moduleName];

export const searchStringSelector = createSelector(
  [searchSimpleState],
  (state: NewFlistSearchSchema) => state.searchString
);

export const currentSelectOptionsSelector = createSelector(
  [searchSimpleState],
  state => state.currentSelectOptionsState
);

export const perPageSelector = createSelector(
  [searchSimpleState],
  state => state.perPage
);

export const currentPageSelector = createSelector(
  [searchSimpleState],
  state => state.currentPage
);

export const lastOptionsSelector = createSelector(
  [searchSimpleState],
  state => state.lastOptions
);

export const isLoadingScrollSelector = createSelector(
  [searchSimpleState],
  state => state.statusScroll
);
