import { moduleName } from "./module";
import { IGroup } from "./types/newFlistSagasTypes";

const appName = process.env.REACT_APP_NAME;

export const actionTypes = {
  /* Инициализация списка. Тут будет происходить выгрузка списка id из localstorage и подгрузка инфо GetLimitedIds. */
  INIT_LIST_START: `${appName}/${moduleName}/INIT_LIST_START`,
  INIT_LIST_COMPLETE: `${appName}/${moduleName}/INIT_LIST_COMPLETE`,
  /* Экшены для работы с группами */
  GET_GROUP_START: `${appName}/${moduleName}/GET_GROUP_START`,
  GET_GROUP_COMPLETE: `${appName}/${moduleName}/GET_GROUP_COMPLETE`,
  GET_GROUP_ERROR: `${appName}/${moduleName}/GET_GROUP_ERROR`,
  ADD_GROUP_TO_GROUPS_STORE: `${appName}/${moduleName}/ADD_GROUP_TO_GROUPS_STORE`,
  //Добавить определенную группу в LS путь
  ADD_GROUP_TO_MODAL_PATH_CAMLIST: `${appName}/${moduleName}/ADD_GROUP_TO_MODAL_PATH_CAMLIST`,
  //Запустить инициализацию восстановления списка групп из "CURRENT_PATH_MODAL_CAMLIST"
  INIT_GROUP_RECOVERY: `${appName}/${moduleName}/INIT_GROUP_RECOVERY`,
  /* Установка группы как текущей */
  SET_CURRENT_SELECTED_GROUP_START: `${appName}/${moduleName}/SET_CURRENT_SELECTED_GROUP_START`,
  SET_CURRENT_SELECTED_GROUP_FINISH: `${appName}/${moduleName}/SET_CURRENT_SELECTED_GROUP_FINISH`,
  SET_CURRENT_SELECTED_GROUP_ID: `${appName}/${moduleName}/SET_CURRENT_SELECTED_GROUP_ID`,
  /* Экшен с передачей списком ids для переключения */
  TOGGLE_SELECTED_IDS_START: `${appName}/${moduleName}/TOGGLE_SELECTED_IDS_START`,
  TOGGLE_SELECTED_IDS_FINISH: `${appName}/${moduleName}/TOGGLE_SELECTED_IDS_FINISH`,
  /* Добавить ИД загружаемой камеры */
  ADD_LOADING_ID: `${appName}/${moduleName}/ADD_LOADING_ID`,
  /* Удалить ИД загружаемой камеры */
  REMOVE_LOADING_ID: `${appName}/${moduleName}/REMOVE_LOADING_ID`,
  /* Для обработки экшенов в хедере */
  CLEAN_ALL_SELECTED: `${appName}/${moduleName}/CLEAN_ALL_SELECTED`,
  SELECT_ALL_CAMERAS_IN_CURRENT_GROUP: `${appName}/${moduleName}/SELECT_ALL_CAMERAS_IN_CURRENT_GROUP`,
  SET_SELECTED_OBJECTS_START: `${appName}/${moduleName}/SET_SELECTED_OBJECTS_START`,
  SET_SELECTED_OBJECTS_FINISH: `${appName}/${moduleName}/SET_SELECTED_OBJECTS_FINISH`,
  /* Экшены по лимитированным данных о камерах */
  SET_LIMITED_CAMERAS_DATA: `${appName}/${moduleName}/SET_LIMITED_CAMERAS_DATA`,
  /*  Установка статуса загрузки */
  SET_IS_LOADING: `${appName}/${moduleName}/SET_IS_LOADING`,
  /* Установить новые выделенные объекты после разлогинизации*/
  CHECK_CAMERAS_FOR_RIGHTS: `${appName}/${moduleName}/CHECK_CAMERAS_FOR_RIGHTS`,
  /* Установить камеры после перемешивания */
  SET_SELECTED_CAMERAS_IDS: `${appName}/${moduleName}/SET_SELECTED_CAMERAS_IDS`,
};

export const setLimitedCamerasDataAction = (camerasData: any) => {
  return {
    type: actionTypes.SET_LIMITED_CAMERAS_DATA,
    payload: {
      camerasData,
    },
  };
};

export const cleanAllSelectedAction = () => {
  return {
    type: actionTypes.CLEAN_ALL_SELECTED,
  };
};

export const setSelectedCamerasIds = (selectedCamerasIds: number[]) => {
  return {
    type: actionTypes.SET_SELECTED_CAMERAS_IDS,
    payload: {
      selectedCamerasIds,
    }
  };
};

export const selectAllCamerasInCurrentGroup = (groupId: number) => {
  return {
    type: actionTypes.SELECT_ALL_CAMERAS_IN_CURRENT_GROUP,
    payload: {
      groupId,
    },
  };
};

export const initListAction = () => {
  return {
    type: actionTypes.INIT_LIST_START,
  };
};

/* Экшены для работы с группами */
export const getGroupStartAction = (Group: IGroup) => {
  return {
    type: actionTypes.GET_GROUP_START,
    payload: Group,
  };
};

export const groupLoadCompleteAction = ({
  groupId,
  groupContent,
  parentId,
}: any) => {
  return {
    type: actionTypes.GET_GROUP_COMPLETE,
    payload: {
      groupId,
      groupContent,
      parentId: parentId != null ? parentId : null,
    },
  };
};

export const getGroupErrorAction = () => {
  return {
    type: actionTypes.GET_GROUP_ERROR,
  };
};

/**
 * Установить новую группу в groups в store
 * @param groupId
 * @param groupContent
 * @param parentId
 *
 */
export const addGroupToGroupsStoreAction = ({
  groupId,
  groupContent,
  parentId,
}: any) => ({
  type: actionTypes.ADD_GROUP_TO_GROUPS_STORE,
  payload: {
    groupId,
    groupContent,
    parentId,
  },
});

/**
 * Восстановить путь групп в LS
 */
export const initGroupRecoveryAction = (): any => ({
  type: actionTypes.INIT_GROUP_RECOVERY,
});

/**
 * Установить путь групп в LS
 * @param groupId
 * @param fromSearchFlag
 */
export const addGroupToModalPathAction = (
  groupId: number,
  fromSearchFlag: boolean = false
) => ({
  type: actionTypes.ADD_GROUP_TO_MODAL_PATH_CAMLIST,
  payload: {
    groupId,
    fromSearchFlag,
  },
});

export const changeGroupStartAction = (
  groupId: number,
  streetsMode = false
) => {
  return {
    type: actionTypes.SET_CURRENT_SELECTED_GROUP_START,
    payload: {
      groupId,
      streetsMode,
    },
  };
};

export const changeGroupFinishAction = (groupId: number) => {
  return {
    type: actionTypes.SET_CURRENT_SELECTED_GROUP_FINISH,
    payload: {
      groupId,
    },
  };
};

/**
 * Установка текущей группы
 * @param {number | string} currentSelectedGroupId
 * @returns
 */
export const setCurrentSelectedGroupIdAction = (
  currentSelectedGroupId: number | string
) => {
  return {
    type: actionTypes.SET_CURRENT_SELECTED_GROUP_ID,
    payload: {
      currentSelectedGroupId,
    },
  };
};

/**
 * Переключение наличие камер.
 * @param {number[]} toggledIds ID камер для переключения.
 */
export const toggleSelectedStartAction = (toggledIds: number[]) => ({
  type: actionTypes.TOGGLE_SELECTED_IDS_START,
  payload: {
    toggledIds,
  },
});

/**
 * Окончание переключения камер.
 * @param {number[]} selectedCamerasIds ID камер которые были переключены.
 */
export const toggleSelectedFinishAction = (selectedCamerasIds: number[]) => {
  return {
    type: actionTypes.TOGGLE_SELECTED_IDS_FINISH,
    payload: {
      selectedCamerasIds,
    },
  };
};

/**
 * Добавить ИД загружающейся камеры
 * @param {number} id ИД камеры
 */
export const addLoadingIdAction = (id: number) => {
  return {
    type: actionTypes.ADD_LOADING_ID,
    payload: {
      id,
    },
  };
};

/**
 * Удалить ИД загружающейся камеры
 * @param {number} id ИД камеры
 */
export const removeLoadingIdAction = (id: number) => {
  return {
    type: actionTypes.REMOVE_LOADING_ID,
    payload: {
      id,
    },
  };
};

/**
 * Установить новые выделенные объекты
 * @param {array} selectedObjects
 */
export const setSelectedObjectsStartAction = (
  selectedObjects: Array<number>
) => ({
  type: actionTypes.SET_SELECTED_OBJECTS_START,
  payload: {
    selectedObjects,
  },
});

/**
 * Установить новые выделенные объекты в store
 * @param {array} selectedObjects
 */
export const setSelectedObjectsFinishAction = (selectedObjects: number[]) => ({
  type: actionTypes.SET_SELECTED_OBJECTS_FINISH,
  payload: {
    selectedObjects,
  },
});

/**
 * Установить статус загрузки
 *  @param {boolean} status
 */
export const setIsLoadingAction = (status: boolean) => {
  return {
    type: actionTypes.SET_IS_LOADING,
    payload: {
      status,
    },
  };
};

/**
 * Проверить и установить камеры с текущим токеном
 *  @param {array} selectedObjects
 */
export const checkCamerasForRightsAction = (
  selectedObjects: Array<number>
) => ({
  type: actionTypes.CHECK_CAMERAS_FOR_RIGHTS,
  payload: {
    selectedObjects,
  },
});
