import styled from "styled-components";
// @ts-ignore-start
import { palette } from "styled-theme";

import Button from "../../../../UIKit/atoms/Button";

export const HeaderContainer = styled.div`
  width: calc(100% - 2em);
  padding: 0 1em;
  text-align: center;
  font-weight: 600;
`;

export const ModalInnnerBody = styled.div`
  padding: 10px 30px;
  text-align: center;
`;

export const ModalInnerFooter = styled.div`
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const StyledButton = styled(Button)`
  width: 200px;
  border-radius: 4px;
  box-shadow: unset;
  padding: 0 1.5em;
  margin: 10px auto;
  background-color: ${palette("primary", 2)};
  color: ${palette("white", 0)};
`;
