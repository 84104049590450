import React, { useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { YMInitializer } from "react-yandex-metrika";
import { RouteComponentProps } from "react-router";

import ProtectedRoute from "../ProtectedRoute/ProtectedRoute";

import Loader from "@UIKit/atoms/Loader";
import ToastContainer from "@UIKit/atoms/ToastNotification";
import WarningModal from "@containers/WarningModal";
import MobileAuthCodeModal from "@containers/MobileAuthCodeModal";

import theme from "@helpers/themes/default";
import sendErrorToSentry from "@helpers/sentry";

import { GlobalStyle } from "./styled-components";
import { IApp } from "./types";
import { routes } from "@routes";

const App = ({ initStart, initComplete }: IApp) => {
  useEffect(() => {
    initStart();
    window.onerror = function(msg, url, lineNo, columnNo, error) {
      sendErrorToSentry(`Error GlobalListener`, {
        extra: `msg= ${msg}  url = ${url}  lineNo = ${lineNo}  columnNo = ${columnNo}  error = ${error}`,
      });
      return false;
    };
  }, [initStart]);

  return !initComplete ? (
    <Loader />
  ) : (
    <>
      <YMInitializer
        accounts={[Number(process.env.REACT_APP_YANDEX_METRICS)]}
      />
      <GlobalStyle />
      <ThemeProvider theme={theme}>
        <Switch>
          {routes.map(({ protectedRoute, path, name, component, exact }) =>
            protectedRoute ? (
              <ProtectedRoute
                path={path}
                name={name}
                component={component}
                exact={exact}
                key={path}
              />
            ) : (
              <Route
                path={path}
                name={name}
                component={
                  component as React.ComponentType<RouteComponentProps<any>>
                }
                exact={exact}
                key={path}
              />
            )
          )}
        </Switch>

        <WarningModal />
        <MobileAuthCodeModal />

        <ToastContainer />
      </ThemeProvider>
    </>
  );
};

export default App;
