import styled from "styled-components";
// @ts-ignore-start
import { palette } from "styled-theme";

export const ModalInner = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
  background-color: ${palette("grayscale", 0, true)};
  max-width: 90vw;
  width: 400px;
  @media (max-width: 575px) {
    width: 320px;
    height: 70vh;
  }

  &&& {
    box-sizing: border-box;
  }
`;
