import { moduleName } from "./module";
import { createSelector } from "reselect";

/**
 * селекторы
 */
export const initState = state => state[moduleName];

export const inProgressSelector = createSelector(
  [initState],
  state => !!state.inProgress
);

export const completeSelector = createSelector(
  [initState],
  state => !!state.complete
);

export const tokenUpdateIntervalValSelector = createSelector(
  [initState],
  state => !!state.tokenUpdateIntervalVal
);

export const hasSeenCopyrightBannerSelector = createSelector(
  [initState],
  state => state.hasSeenCopyrightBanner
);

export const hasSeenRedirectBannerSelector = createSelector(
  [initState],
  state => state.hasSeenRedirectBanner
);

export const selectShowSceleton = createSelector(
  [initState],
  state => state.showSceleton
);

export const dontShowModalAgainSelector = createSelector(
  [initState],
  state => state.dontShowModalAgain
);
