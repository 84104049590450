import { createSelector } from "reselect";
import { moduleName } from "./module";
import { NewFlistSearchSchema } from "./types/schemaTypes";

export const newFListSearchState = (state: any) => state[moduleName];

export const searchStringSelector = createSelector(
  [newFListSearchState],
  (state: NewFlistSearchSchema) => state.searchString
);

export const tabTypeSelector = createSelector(
  [newFListSearchState],
  (state: NewFlistSearchSchema) => state.tabType
);
