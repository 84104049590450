import { ISelectData } from "@globalTypes/search";

/**
 * Функция для .map - преобразовать данные с сервера для нашего select элемента
 *  */
export const mapSelectData = (el: ISelectData) => {
  el.value = el.object;
  // delete el.name;
  // delete el.object;
  // delete el.chain;
  // delete el.title;
  return el;
};

/**
 * Элемент цепочки - дальше которой не выдавать варианты для выбора
 */
export const MAX_CHAIN_ELEMENT = "porch";

export const ELEMENTS_WHEN_START_SEARCH = ["building", "porch"];

/**
 * plan, locality ??
 */
export const chainOrder: Record<string, any> = {
  region: 1,
  area: 2,
  locality: 3,
  city: 4,
  plan: 5,
  street: 6,
  building: 7,
  porch: 8,
};

export const generateCurrentChain = (chain: any) => {
  const newChain: Record<string, any> = {};
  for (const key in chain) {
    if (chain[key].name) {
      newChain[key] = { ...chain[key], object: key, order: chainOrder[key] };
    }
  }

  /**
   * Отсортировать массив
   */
  const currentFullChain = Object.values(newChain)
    .map(el => ({
      id: el.id,
      label: el.name,
      name: el.name,
      object: el.object,
      title: el.object + el.id,
      value: el.object,
      chain: chain,
    }))
    .sort((a: any, b: any) => (a.order > b.order ? -1 : 1));
  return currentFullChain;
};
