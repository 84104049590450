import { moduleName } from "./module";
import { createSelector } from "reselect";

/**
 * селекторы
 */
export const userState = (state: any) => state[moduleName];

export const authInProgressSelector = createSelector(
  [userState],
  state => !!state.authInProgress
);

export const authorizedSelector = createSelector(
  [userState],
  state => !!state.authorized
);

export const userNameSelector = createSelector(
  [userState],
  state => state.username
);

export const authErrorsSelector = createSelector(
  [userState],
  state => state.authErrors
);

export const authTokenSelector = createSelector(
  [userState],
  state => state.token
);

export const frontendIsReadySelector = createSelector(
  [userState],
  state => state.frontendIsReadyStatus
);

export const typeAuthSelector = createSelector(
  [userState],
  state => state.typeAuthStore
);

export const mobileNumberSelector = createSelector(
  [userState],
  state => state.mobileNumber
);

export const mobileAuthCodeStatusStoreSelector = createSelector(
  [userState],
  state => state.mobileAuthCodeStatusStore
);

export const uniqueDeviceIdStoreSelector = createSelector(
  [userState],
  state => state.uniqueDeviceIdStore
);

export const currentStepSelector = createSelector(
  [userState],
  state => state.currentStepStore
);

export const userMobileAddressesOptionsSelector = createSelector(
  [userState],
  state => state.userMobileAddressesOptions
);

export const userMobileAddressSelector = createSelector(
  [userState],
  state => state.userMobileAddress
);

export const authIdSelector = createSelector(
  [userState],
  state => state.authId
);

export const mobileAuthCodeRequestInProgressSelector = createSelector(
  [userState],
  state => state.mobileAuthCodeRequestInProgress
);

export const mobileAuthCodeRequestTimeoutErrorSelector = createSelector(
  [userState],
  state => state.mobileAuthCodeRequestTimeoutError
);
