import LocalStorage from "../storage/LocalStorage";
import localStorageKeys from "../storage/localStorageKeys";
import CheckLocalStorage from "../storage/CheckLocalStorage";
import { ILimitedInfoDataSchema } from "@modules/newFlistSearch/types/sagaTypes";
import {getLoginFromLs} from "@helpers/user";

/**
 * Установка новых камер в ключ "SELECTED_CAM_IDS" localStorage
 * @param {object} data - ID камер
 */
export const setSelectedCamsToLs = (data: number[] | string[]) => {
  return LocalStorage.set(localStorageKeys.SELECTED_CAM_IDS+getLoginFromLs(), data);
};

/**
 * Получение новых камер из ключа "SELECTED_CAM_IDS" localStorage
 * void
 */
export const getSelectedCamsFromLs = () => {
  return LocalStorage.get(localStorageKeys.SELECTED_CAM_IDS+getLoginFromLs());
};

/**
 * Очистить ключ "SELECTED_CAM_IDS" localStorage
 * void
 */
export const removeSelectedCamsFromLs = async () => {
  await LocalStorage.remove(localStorageKeys.SELECTED_CAM_IDS+getLoginFromLs());
};

/**
 * Установка текущего ключа "CURRENT_GROUP" localStorage
 * void
 */
export const setCurrentGroupToLs = (
  data: Partial<CurrentSelectedGroup> | Record<string, any>
) => {
  return LocalStorage.set(localStorageKeys.CURRENT_GROUP, data);
};

/**
 * Получение текущего ключа "CURRENT_GROUP" localStorage
 * void
 */
export const getCurrentGroupFromLs = () => {
  return LocalStorage.get(localStorageKeys.CURRENT_GROUP);
};

/**
 *  Очистить ключа "CURRENT_GROUP" localStorage
 * void
 */
export const removeCurrentGroupFromLs = () => {
  return LocalStorage.get(localStorageKeys.CURRENT_GROUP);
};

/**
 * Установка текущей группы в ключ "SELECTED_GROUP" localStorage
 * @param {object} data - ID и NAME группы
 */
export const setSelectedGroupToLs = async (data: any) => {
  return await LocalStorage.set(localStorageKeys.SELECTED_GROUP, data);
};

/**
 * Получение текущей группы из ключа "SELECTED_GROUP" localStorage
 * void
 */
export const getSelectedGroupFromLs = () => {
  return LocalStorage.get(localStorageKeys.SELECTED_GROUP);
};

/**
 * Очистить ключ "SELECTED_GROUP" localStorage
 * void
 */
export const removeSelectedGroupFromLs = async () => {
  return await LocalStorage.remove(localStorageKeys.SELECTED_GROUP);
};

/**
 * Установка активного родителя в ключ "ACTIVE_PARENT_ID" localStorage
 * @param {string} data - ID камер
 */
export const setActiveObjectIdToLs = async (data: any) => {
  return await LocalStorage.set(localStorageKeys.ACTIVE_PARENT_ID, data);
};

/**
 * Получение активного родителя из ключа "ACTIVE_PARENT_ID" localStorage
 * void
 */
export const getActiveObjectIdFromLs = async () => {
  return await LocalStorage.get(localStorageKeys.ACTIVE_PARENT_ID);
};

/**
 * Установка текущего массива групп в ключ "ALL_SELECTED_GROUPS" localStorage
 * @param {object} data - объект камер
 */
export const setAllSelectedGroupsToLs = (data: any) => {
  return LocalStorage.set(localStorageKeys.ALL_SELECTED_GROUPS, data);
};

/**
 * Получение  текущего массива групп из ключа "ALL_SELECTED_GROUPS" localStorage
 * void
 */
export const getAllSelectedGroupsFromLs = () => {
  return LocalStorage.get(localStorageKeys.ALL_SELECTED_GROUPS);
};

/**
 * Получение  текущего массива групп из ключа "ALL_SELECTED_GROUPS" localStorage
 * void
 */
export const removeAllSelectedGroupsFromLs = () => {
  return LocalStorage.remove(localStorageKeys.ALL_SELECTED_GROUPS);
};

// Работа с полностью выбранными группами
export const setFullSelectedGroupsToLs = (data: any) => {
  return LocalStorage.set(localStorageKeys.FULL_SELECTED_GROUPS, data);
};

export const getFullSelectedGroupsFromLs = () => {
  return LocalStorage.get(localStorageKeys.FULL_SELECTED_GROUPS);
};

export const removeFullSelectedGroupsFromLs = () => {
  return LocalStorage.remove(localStorageKeys.FULL_SELECTED_GROUPS);
};

/**
 * Установка текущего массива групп в ключ "ALL_OPENED_GROUPS" localStorage
 * @param {object} data - объект камер
 */
export const setAllOpenedGroupsToLs = (data: any) => {
  return LocalStorage.set(localStorageKeys.ALL_OPENED_GROUPS, data);
};

/**
 * Установка текущего ключа "VERSION_OF_LS" localStorage
 * void
 */
export const setVersionOfLsToLs = (data: any) => {
  return LocalStorage.set(localStorageKeys.VERSION_OF_LS, data);
};

/**
 * Получение текущего ключа "VERSION_OF_LS" localStorage
 * void
 */
export const getVersionOfLsFromLs = () => {
  return LocalStorage.get(localStorageKeys.VERSION_OF_LS);
};

/**
 * Проверка всех ключей localStorage
 * void
 */
export const checkAllKeysInLs = () => {
  return CheckLocalStorage.checkAllLsKeys();
};

/**
 * Форматировать данные для redux для ключа searchedObjects при simple search
 */
export function formatDataForSearchObjectsSimpleSearch(
  data: ILimitedInfoDataSchema[]
) {
  const res: Record<string, any> = {};
  data.forEach((curVal: ILimitedInfoDataSchema, i: number) => {
    res[i] = data[i];
  });

  return res;
}

/**
 * Форматировать данные для redux для ключа searchedObjects
 */
export function formatDataForSearchObjects(data: ILimitedInfoDataSchema[]) {
  let res: Record<string, any> = {};
  data.forEach((curVal: ILimitedInfoDataSchema, i: number) => {
    const { order } = curVal;
    res[order] = data[i];
  });

  return res;
}

/**
 * Установка текущего пути в ключ "CURRENT_PATH_MODAL_CAMLIST" localStorage
 * @param {object} data - массив пути группы
 */
export const setCurrentPathModalCamlistToLs = (data: any) => {
  return LocalStorage.set(localStorageKeys.CURRENT_PATH_MODAL_CAMLIST, data);
};

/**
 * Получение текущего пути из ключа "CURRENT_PATH_MODAL_CAMLIST" localStorage
 * void
 */
export const getCurrentPathModalCamlistFromLs = () => {
  return LocalStorage.get(localStorageKeys.CURRENT_PATH_MODAL_CAMLIST);
};

/**
 * Удаление текущего пути из ключа "CURRENT_PATH_MODAL_CAMLIST" localStorage
 * void
 */
export const removeCurrentPathModalCamlistFromLs = async () => {
  return await LocalStorage.remove(localStorageKeys.CURRENT_PATH_MODAL_CAMLIST);
};
