import { moduleName } from "./module";
import {
  AUTH_TYPES,
  authStartActionType,
  updateUsernameType,
} from "./types/userActionsTypes";

const appName = process.env.REACT_APP_NAME;

export const actionTypes = {
  AUTH_INIT: `${appName}/${moduleName}/AUTH_INIT`,
  AUTH_START: `${appName}/${moduleName}/AUTH_START`,
  AUTH_COMPLETE: `${appName}/${moduleName}/AUTH_COMPLETE`,
  AUTH_TYPE_MOBILE_START: `${appName}/${moduleName}/AUTH_TYPE_MOBILE_START`,
  AUTH_TYPE_MOBILE_COMPLETE: `${appName}/${moduleName}/AUTH_TYPE_MOBILE_COMPLETE`,
  AUTH_ERROR: `${appName}/${moduleName}/AUTH_ERROR`,
  LOG_OUT: `${appName}/${moduleName}/LOG_OUT`,
  UPDATE_TOKEN: `${appName}/${moduleName}/UPDATE_TOKEN`,
  UPDATE_USERNAME: `${appName}/${moduleName}/UPDATE_USERNAME`,
  READY: `${appName}/${moduleName}/READY`,
  SET_TYPE_AUTH: `${appName}/${moduleName}/SET_TYPE_AUTH`,
  SEND_MOBILE_CODE: `${appName}/${moduleName}/SEND_MOBILE_CODE`,
  OPEN_MOBILE_CODE_MODAL: `${appName}/${moduleName}/OPEN_MOBILE_CODE_MODAL`,
  SET_MOBILE_NUMBER: `${appName}/${moduleName}/SET_MOBILE_NUMBER`,
  SET_UNIQUE_DEVICE_ID: `${appName}/${moduleName}/SET_UNIQUE_DEVICE_ID`,
  GET_MOBILE_AUTH_TOKEN: `${appName}/${moduleName}/GET_MOBILE_AUTH_TOKEN`,
  //Установка текущего шага при авторизации по номеру телефона
  SET_CURRENT_STEP: `${appName}/${moduleName}/SET_CURRENT_STEP`,
  //Адреса при авторизации по мобильному номеру
  SET_USER_MOBILE_ADDRESSES_OPTIONS: `${appName}/${moduleName}/SET_USER_MOBILE_ADDRESSES_OPTIONS`,
  CHOOSE_USER_MOBILE_ADDRESS: `${appName}/${moduleName}/CHOOSE_USER_MOBILE_ADDRESS`,
  SET_USER_MOBILE_ADDRESS: `${appName}/${moduleName}/SET_USER_MOBILE_ADDRESS`,
  //Установка Auth Id
  SET_AUTH_ID: `${appName}/${moduleName}/SET_AUTH_ID`,
  // Повторно получить код для авторизации
  HANDLE_REPEAT_CALL_MOBILE_AUTH: `${appName}/${moduleName}/HANDLE_REPEAT_CALL_MOBILE_AUTH`,
  // Отправлен запрос на код для авторизации через телефон
  SET_MOBILE_AUTH_CODE_REQUEST: `${appName}/${moduleName}/SET_MOBILE_AUTH_CODE_REQUEST`,
  // Ошибка авторизации через телефон - код запрошен с интервалом меньше двух минут
  SET_MOBILE_AUTH_CODE_REQUEST_TIMEOUT_ERROR: `${appName}/${moduleName}/SET_MOBILE_AUTH_CODE_REQUEST_TIMEOUT_ERROR`,
  // Повторно открыть окно ввода кода
  REOPEN_MOBILE_AUTH_CODE_FORM: `${appName}/${moduleName}/REOPEN_MOBILE_AUTH_CODE_FORM`,
};

/**
 * Обновить токен
 *
 * @param {string} token
 */
export const updateTokenAction = (token: string) => {
  return {
    type: actionTypes.UPDATE_TOKEN,
    payload: {
      token,
    },
  };
};

/**
 * Запуск авторизации на сайте
 * @param {string} username
 * @param {string} password
 * @param {number} days
 */
export const authStartAction = ({
  username,
  password,
  days = 30,
}: authStartActionType) => {
  return {
    type: actionTypes.AUTH_START,
    payload: {
      username,
      password,
      days,
    },
  };
};

/**
 * Обновить логин
 * @param {string} username
 * @param {string} password
 *
 */
export const updateUsernameAction = ({ username }: updateUsernameType) => {
  return {
    type: actionTypes.UPDATE_USERNAME,
    payload: {
      username,
    },
  };
};

/**
 * Завершение авторизации
 *
 * @param {any} data
 *
 */
export const authCompleteAction = (data: any) => {
  return {
    type: actionTypes.AUTH_COMPLETE,
    payload: {
      data,
    },
  };
};

/**
 * Установка статуса фронтенда
 * @param {boolean} frontendIsReadyStatus -
 */
export const frontendIsReadyStatusAction = (frontendIsReadyStatus: boolean) => {
  return {
    type: actionTypes.READY,
    payload: {
      frontendIsReadyStatus,
    },
  };
};

/**
 * Вызвать ошибку при авторизации
 * @param {string} message
 */
export const authErrorAction = (message: string) => {
  return {
    type: actionTypes.AUTH_ERROR,
    payload: {
      message,
    },
  };
};

/**
 * Выйти из авторизации
 */
export const logOutAction = () => {
  return {
    type: actionTypes.LOG_OUT,
  };
};

/**
 * Поменять тип авторизации
 */
export const setTypeAuthAction = (typeAuthStore: AUTH_TYPES) => {
  return {
    type: actionTypes.SET_TYPE_AUTH,
    payload: {
      typeAuthStore,
    },
  };
};

/**
 * Запуск авторизации на сайте по телефону
 * @param {string} mobileNumber
 *
 */
export const authTypeMobileStartAction = (mobileNumber: string) => {
  return {
    type: actionTypes.AUTH_TYPE_MOBILE_START,
    payload: {
      mobileNumber,
    },
  };
};

/**
 * Завершение авторизации на сайте по телефону
 * @param {any} data
 *
 */
export const authTypeMobileCompleteAction = (data: any) => {
  return {
    type: actionTypes.AUTH_TYPE_MOBILE_COMPLETE,
    payload: {
      data,
    },
  };
};

/**
 * Отправить специальный код
 * @param {number} confirmCode - посл 4 цифры номера
 *
 */
export const sendMobileCodeAction = (confirmCode: string) => {
  return {
    type: actionTypes.SEND_MOBILE_CODE,
    payload: {
      confirmCode,
    },
  };
};

/**
 * Установить статус открытого или закрытого модального окна ввода кода
 * @param {boolean} mobileAuthCodeStatusStore
 */
export const openMobileCodeModalAction = (
  mobileAuthCodeStatusStore: boolean
) => {
  return {
    type: actionTypes.OPEN_MOBILE_CODE_MODAL,
    payload: {
      mobileAuthCodeStatusStore,
    },
  };
};

/**
 * Установить статус открытого или закрытого модального окна ввода кода
 * @param {string} mobileNumber
 */
export const setMobileNumberAction = (mobileNumber: string) => {
  return {
    type: actionTypes.SET_MOBILE_NUMBER,
    payload: {
      mobileNumber,
    },
  };
};

/**
 * Установить уникальный ID устройства
 */
export const setUniqueDeviceIdAction = (uniqueDeviceIdStore: string) => {
  return {
    type: actionTypes.SET_UNIQUE_DEVICE_ID,
    payload: {
      uniqueDeviceIdStore,
    },
  };
};

/**
 * Получить данные по AUTH_TOKEN при авторизации через телефон
 *
 */

export const getMobileAuthTokenAction = (authId: string, userId?: string) => {
  return {
    type: actionTypes.GET_MOBILE_AUTH_TOKEN,
    payload: {
      authId,
      userId,
    },
  };
};

/**
 * Установить текущий шаг при авторизации через телефон
 *
 */

export const setCurrentStepAction = (currentStepStore: string) => {
  return {
    type: actionTypes.SET_CURRENT_STEP,
    payload: {
      currentStepStore,
    },
  };
};

/**
 * Установить варианты адресов при авторизации через телефон
 *
 */

export const setUserMobileAddressesOptionsAction = (
  userMobileAddressesOptions: string[]
) => {
  return {
    type: actionTypes.SET_USER_MOBILE_ADDRESSES_OPTIONS,
    payload: {
      userMobileAddressesOptions,
    },
  };
};

/**
 * Выбрать адрес при авторизации через телефон
 */

export const chooseUserMobileAddressAction = (userMobileAddress: string) => {
  return {
    type: actionTypes.CHOOSE_USER_MOBILE_ADDRESS,
    payload: {
      userMobileAddress,
    },
  };
};

/**
 * Установить адрес пользователя при авторизации через телефон
 *
 */

export const setUserMobileAddressAction = (userMobileAddress: string) => {
  return {
    type: actionTypes.SET_USER_MOBILE_ADDRESS,
    payload: {
      userMobileAddress,
    },
  };
};

/**
 * Установить authId пользователя при авторизации через телефон
 *
 */

export const setAuthIdAction = (authId: string) => {
  return {
    type: actionTypes.SET_AUTH_ID,
    payload: {
      authId,
    },
  };
};

/**
 * Получить повторно код для авторизации
 *
 */

export const handleRepeatCallMobileAuthAction = () => {
  return {
    type: actionTypes.HANDLE_REPEAT_CALL_MOBILE_AUTH,
  };
};

/**
 * Установить статус запроса кода при авторизации через телефон
 * @param {boolean} isInProgress запрос на код в процессе выполнения
 */
export const setMobileAuthCodeRequestInProgressAction = (
  isInProgress: boolean
) => {
  return {
    type: actionTypes.SET_MOBILE_AUTH_CODE_REQUEST,
    payload: {
      isInProgress,
    },
  };
};

/**
 * Установить статус ошибки при авторизации через телефон при запросе нового
 * кода менее чем через две минуты после прошлого запроса
 * @param {boolean} timeoutError ошибка имеется
 */
export const setMobileAuthCodeRequestTimeoutErrorAction = (
  timeoutError: boolean
) => {
  return {
    type: actionTypes.SET_MOBILE_AUTH_CODE_REQUEST_TIMEOUT_ERROR,
    payload: {
      timeoutError,
    },
  };
};

/**
 * Повторно открыть окно ввода кода
 */
export const reopenMobileAuthCodeFormAction = () => {
  return {
    type: actionTypes.REOPEN_MOBILE_AUTH_CODE_FORM,
  };
};
