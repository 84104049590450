import { fork, take, takeLatest, all, select, put } from "redux-saga/effects";
//@ts-ignore
import { push } from "react-router-redux";

import { actionTypes } from "./actions";

/** NewFlist */
import {
  changeGroupStartAction,
  addGroupToGroupsStoreAction,
  selectAllCamerasInCurrentGroup,
  actionTypes as actionTypesNewFlist,
  cleanAllSelectedAction,
} from "../newFlist/actions";

/** WarningModal Module */
import { getGroupByGroupId } from "../newFlist";

import { actionTypes as WarningModalActionTypes } from "../WarningModal";

export const saga = function*() {
  yield all([fork(chooseCityWatcher)]);
};

export const chooseCityWatcher = function*() {
  yield all([takeLatest(actionTypes.CHOOSE_CITY, chooseCityWorker)]);
};

/**
 * Выбор города. При выборе города все камеры получаются и каждая становиться checked
 * @param {{groupId: number; groupName: string}} payload
 */
export const chooseCityWorker = function*({ payload }: any) {
  //Проверка что мы пришли не с избранного
  const isFromFavourites = window.location.pathname !== "/favourites";
  //Кидаем в корень проекта
  yield put(push("/"));

  if (isFromFavourites) {
    yield put(cleanAllSelectedAction());

    const dontShowModalAgain = yield sessionStorage.getItem(
      "dontShowModalAgain"
    );

    if (!dontShowModalAgain || dontShowModalAgain === "false")
      yield take(
        (action: Action) =>
          action.type === WarningModalActionTypes.DELETE_ALL_CAMERAS_FLAG &&
          action?.payload?.deleteAllCamerasFlagStore
      );
  }
  const { groupId, groupName } = payload;
  //Если вдруг по какой то причине группа не попала в state groups ее необходимо занести
  const selectedGroup = yield select(getGroupByGroupId, { groupId });

  const newGroup = {
    groupId: { ID: groupId, NAME: groupName, OBJECT: "GROUP" },
  };

  if (!selectedGroup) {
    yield put(
      addGroupToGroupsStoreAction({
        groupId,
        groupContent: newGroup,
        parentId: 16,
      })
    );
  }

  //Устаналиваем текущую группу
  yield put(changeGroupStartAction(groupId, true));

  yield take(
    //TODO усилить типизацию в будущих коммитах
    (action: Action) =>
      action.type === actionTypesNewFlist.SET_CURRENT_SELECTED_GROUP_FINISH &&
      action.payload &&
      action.payload.groupId === groupId
  );

  yield put(selectAllCamerasInCurrentGroup(groupId));
};
