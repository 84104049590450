import React, { useEffect, useCallback } from "react";
import Modal from "../../../UIKit/moleculs/Modal";

import {
  ModalInner,
  HeaderContainer,
  ModalInnerBody,
  ModalInnerFooter,
  StyledButton,
  DisagreedStyledButton,
  ShowModalInput,
  CameraLabel,
  CheckboxStyled,
} from "./styled-components";

const WarningModal = (props: WarningModalProps) => {
  const {
    closeWarningModal: closeWarningModalProps,
    openWarningModal: openWarningModalProps,
    deleteAllCamerasFlag: deleteAllCamerasFlagProps,
    dontShowModalAgain: dontShowModalAgainProps,
    changeDontShowModalAgain: changeDontShowModalAgainProps,
  } = props;

  /**
   * Отправление запроса в сагу разрешения на удаления камер
   * и одновременно закрытие модального окна
   */
  const deleteAllCamsAgree = useCallback(
    (status: boolean) => {
      deleteAllCamerasFlagProps(status);
      closeWarningModalProps(false);
    },
    [deleteAllCamerasFlagProps, closeWarningModalProps]
  );

  /**
   * Обработка нажатия enter при открытом модальном окне "Предупреждение"
   */
  const handleEnterPressed = useCallback(
    (e: KeyboardEvent) => {
      if (e.code === "Enter" && openWarningModalProps) {
        deleteAllCamsAgree(true);
      }
    },
    [deleteAllCamsAgree, openWarningModalProps]
  );

  const stopPropagation = (e: Event) => e.stopPropagation();

  const dontShowModalAgainHandler = () =>
    changeDontShowModalAgainProps(!dontShowModalAgainProps);

  //Вешаем обработчик для клавиши enter
  useEffect(() => {
    if (openWarningModalProps) {
      document.addEventListener("keypress", handleEnterPressed);
    }
    return () => document.removeEventListener("keypress", handleEnterPressed);
  }, [openWarningModalProps, handleEnterPressed]);

  return (
    <>
      <Modal
        className="warning__modal"
        isOpen={openWarningModalProps}
        closeable
        ariaHideApp={false}
        zIndex={10000}
        onClose={() => {
          deleteAllCamsAgree(false);
        }}
      >
        <ModalInner className="warning__inner-styled">
          <HeaderContainer className="warning__header_container">
            Предупреждение
          </HeaderContainer>
          <ModalInnerBody className="warning__body_description">
            Данное действие очистит текущий список отображаемых камер. Вы
            уверены, что хотите продолжить?
          </ModalInnerBody>
          <ModalInnerFooter className="warning__footer">
            <ShowModalInput className="warning__footer_input">
              <CameraLabel onClick={stopPropagation}>
                <CheckboxStyled
                  onChange={() => dontShowModalAgainHandler()}
                  checked={dontShowModalAgainProps}
                />
                Больше не показывать
              </CameraLabel>
            </ShowModalInput>
            <StyledButton
              className="warning__footer_btn"
              onClick={() => {
                deleteAllCamsAgree(true);
              }}
            >
              Продолжить
            </StyledButton>
            <DisagreedStyledButton
              className="warning__footer_btn"
              onClick={() => {
                deleteAllCamsAgree(false);
              }}
            >
              Отмена
            </DisagreedStyledButton>
          </ModalInnerFooter>
        </ModalInner>
      </Modal>
    </>
  );
};

WarningModal.displayName = "WarningModal";

export default WarningModal;
