import React from "react";
import { Row, Col } from "react-flexbox-grid";

import { ReactComponent as Vk2Icon } from "../../../assets/img/vk2.svg";
import { ReactComponent as TgIcon } from "../../../assets/img/tg.svg";
import { ReactComponent as YouIcon } from "../../../assets/img/you.svg";
import { ReactComponent as OkIcon } from "../../../assets/img/ok.svg";

import {
  GridStyled,
  SocialContainer,
  HrStyled,
  RowStyled,
  CopyRight,
  ContactContainer,
  ContactPhoneLink,
  ContactPhone,
  ColStyled,
  SocialIconContainer,
  SocialIconLink,
} from "./styled-components";

import {
  ymSendAnalytics,
  yandexEvents,
} from "../../../helpers/yandex-analytics";

const Footer = () => (
  <GridStyled className="component-footer">
    <HrStyled />
    <RowStyled between="xs" middle="xs">
      <Col md={4} xs={12}>
        <Row start="md" center="xs">
          <CopyRight className="copyright">
            © Видеонаблюдение Интерсвязь {new Date().getFullYear()}
          </CopyRight>
        </Row>
      </Col>
      <Col md={4} xs={12}>
        <ContactContainer className="contact-container">
          <ContactPhoneLink href="tel:+7 351 247 9 555">
            <ContactPhone>+7 351 247 9 555</ContactPhone>
          </ContactPhoneLink>
        </ContactContainer>
      </Col>
      <ColStyled md={4} sm={12}>
        <Row end="md" center="xs">
          <SocialContainer className="social-container">
            <SocialIconContainer
              onClick={() => ymSendAnalytics(yandexEvents.clickSocialNetwork)}
            >
              <SocialIconLink
                target="_blank"
                rel="noopener noreferrer"
                href="https://vk.com/intersvyaz"
              >
                <Vk2Icon />
              </SocialIconLink>
            </SocialIconContainer>
            <SocialIconContainer
              onClick={() => ymSendAnalytics(yandexEvents.clickSocialNetwork)}
            >
              <SocialIconLink
                target="_blank"
                rel="noopener noreferrer"
                href="https://t.me/intersvyaz"
              >
                <TgIcon />
              </SocialIconLink>
            </SocialIconContainer>
            <SocialIconContainer
              onClick={() => ymSendAnalytics(yandexEvents.clickSocialNetwork)}
            >
              <SocialIconLink
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.youtube.com/channel/UC4eru9eR_Ii8zSgj2xGVjqw"
              >
                <YouIcon />
              </SocialIconLink>
            </SocialIconContainer>
            <SocialIconContainer
              onClick={() => ymSendAnalytics(yandexEvents.clickSocialNetwork)}
            >
              <SocialIconLink
                target="_blank"
                rel="noopener noreferrer"
                href="https://ok.ru/intersvyaz"
              >
                <OkIcon />
              </SocialIconLink>
            </SocialIconContainer>
          </SocialContainer>
        </Row>
      </ColStyled>
    </RowStyled>
  </GridStyled>
);

Footer.defaultProps = {};
Footer.displayName = "Footer";
export default Footer;
