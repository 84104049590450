import LocalStorage from "../storage/LocalStorage";
import localStorageKeys from "../storage/localStorageKeys";

interface SettingsLsDecoded {
  eventTypes: {
    id: string;
    enabled: boolean;
    promote: boolean;
    highlight: boolean;
  }[];
  disabledCameraIds: (string|number)[];
}
interface SettingsLsEncoded {
  e?: {
    id?: string;
    h?: boolean;
    e?: boolean;
    p?: boolean;
  }[];
  c?: (string|number)[];
}

function encode(settings: SettingsLsDecoded): SettingsLsEncoded {
  return {
    e: settings.eventTypes.map(it => ({
      id: it.id,
      h: it.highlight,
      e: it.enabled,
      p: it.promote,
    })),
    c: settings.disabledCameraIds,
  };
}
function decode(encoded: SettingsLsEncoded): SettingsLsDecoded | null {
  if (!(
    encoded && typeof encoded === 'object'
  )) {
    return null;
  }
  if (!(
    'e' in encoded && Array.isArray(encoded.e)
  )) {
    throw new Error();
  }
  if ('c' in encoded && (!encoded.c || !Array.isArray(encoded.c))) {
    throw new Error();
  }
  return {
    eventTypes: encoded.e.map(it => {
      if (typeof it.id === 'string' && typeof it.p === 'boolean' && typeof it.h === 'boolean' && typeof it.e === 'boolean') {
        return {
          id: it.id,
          promote: it.p,
          enabled: it.e,
          highlight: it.h,
        };
      } else {
        throw new Error();
      }
    }),
    disabledCameraIds: encoded.c ?? [],
  }
}

export const setCameraEventsSettingsToLs = async (settings: SettingsLsDecoded) => {
  LocalStorage.set(localStorageKeys.CAMERA_EVENTS_SETTINGS, encode(settings));
  return Promise.resolve();
};

export const getCameraEventsSettingsFromLs = () => {
  try {
    return decode(LocalStorage.get(localStorageKeys.CAMERA_EVENTS_SETTINGS));
  } catch (e) {
    LocalStorage.remove(localStorageKeys.CAMERA_EVENTS_SETTINGS);
    return null;
  }
};