import { actionTypes } from "./actions";
import { StateSchema } from "./schema";

export default (state = { ...StateSchema }, action: Action) => {
  const { type, payload } = action;

  switch (type) {
    case actionTypes.SET_TIME_MULTIPLIER: {
      return { ...state, timeMultiplier: payload };
    }

    default:
      return state;
  }
};
