import React, { FC, PropsWithChildren, ReactNode } from "react";

import Header from "@containers/Header";
import Footer from "@views/Footer";

import { RootWrapper, Main, FooterStyled } from "./styled-components";

const DefaultLayout: FC<PropsWithChildren<ReactNode>> = ({ children }) => (
  <div>
    <div className="sidebar"></div>
    <RootWrapper className="root-wrapper">
      <Header />
      <Main>{children}</Main>
      <FooterStyled>
        <Footer />
      </FooterStyled>
    </RootWrapper>
  </div>
);

export default DefaultLayout;
