import { captureMessage } from "@sentry/browser";
class ErrorLog {
  static _isDebug: boolean = process.env.NODE_ENV === "development";

  static _logToConsole = (
    error: Error | null,
    message: String,
    type: "error" | "warning" = "warning"
  ) => {
    if (ErrorLog._isDebug) {
      switch (type) {
        case "error":
          console.error(error, "e");
          console.error(message, "Error message");
          break;
        case "warning":
          console.warn(message, "Warning message");
          break;

        default:
          console.warn(message, "Warning message");
          break;
      }
    }
    return;
  };

  /**
   * Отправки ошибки только для Dev
   */
  static showErrorForDev = (error: Error | null, message: string) => {
    ErrorLog._logToConsole(error, message, "error");
  };

  /**
   * Отправки ошибки
   * @param error
   * @param message
   * @param extra
   */
  static sendError = (error: Error | null, message: string, extra: any) => {
    ErrorLog._logToConsole(error, message, "error");
    if (!ErrorLog._isDebug)
      captureMessage(message, { extra: { extra: extra, error: error } });
  };

  /**
   * Отправки Warninga
   * @param message
   */
  static sendWarning = (message: string) => {
    ErrorLog._logToConsole(null, message, "warning");
  };
}

export default ErrorLog;
