import { actionTypes, StateSchema } from "./";
import { NewFlistSearchSchema } from "./types/schemaTypes";

export default (
  state: NewFlistSearchSchema = { ...StateSchema },
  action: Action
) => {
  const { type, payload } = action;

  switch (type) {
    case actionTypes.CHANGE_SEARCH_STRING: {
      const { searchString } = payload;

      return {
        ...state,
        searchString,
      };
    }

    case actionTypes.CHANGE_SEARCH_TYPE: {
      const { tabType } = payload;

      return {
        ...state,
        tabType,
      };
    }

    default:
      return state;
  }
};
