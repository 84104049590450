import { actionTypes, setCurrentFragmentInfoAction } from "./actions";
import { stateSchema } from "./schema";
import { generateIntervalUrl } from "../../helpers/archive";

/**
 * Это то время которое прибавляется и убавляется от текущего чтобы в далнейшем получать фрагменты
 */
const ThirtyMinutesAsMilliseconds = 30 * 60 * 1000;
const FiveMinutesAsMilliseconds = 5 * 60 * 1000;
const HourMinutesAsMilliseconds = 60 * 60 * 1000;

export default (state = { ...stateSchema }, action: archiveSagaAction) => {
  const { type, payload } = action;
  switch (type) {
    case actionTypes.SET_CURRENT_PLAYLIST_DURATION: {
      const { currentPlaylistDurarion } = payload;
      return { ...state, currentPlaylistDurarion };
    }
    case actionTypes.SET_LIVE: {
      const currentVideoTimestamp = Date.now();
      const timelineInterval = {
        timelineStartPositionInMs:
          currentVideoTimestamp - FiveMinutesAsMilliseconds,
        timelineEndPositionInMs: currentVideoTimestamp,
      };

      return {
        ...state,
        live: true,
        archivePlaylistStartTimestamp: 0,
        timelineInterval,
        currentVideoTimestamp,
        playerUrl: `${state.cameraData.MEDIA.HLS.LIVE.MAIN}`,
      };
    }

    case actionTypes.SET_CURRENT_VIDEO_TIMESTAMP_ON_SHIFT: {
      const { offset } = payload;

      const currentVideoOffsetInSeconds =
        state.currentVideoOffsetInSeconds + offset;

      const offsetInMs = offset * 1000;

      const timelineInterval = {
        timelineStartPositionInMs:
          state.timelineInterval.timelineStartPositionInMs + offsetInMs,
        timelineEndPositionInMs: Date.now(),
      };

      const currentVideoTimestamp = state.currentVideoTimestamp + offsetInMs;

      /* Если ушли слишком далеко назад или вперед - регенерируем */
      if (
        state.live ||
        currentVideoOffsetInSeconds < 0 ||
        currentVideoOffsetInSeconds > (state.currentPlaylistDurarion || 0)
      ) {
        const archivePlaylistStartTimestamp = new Date();
        const archivePlaylistEndTimestamp = new Date();
        const selectedTimestampAsDate = new Date(currentVideoTimestamp);
        selectedTimestampAsDate.setSeconds(0,0);

        archivePlaylistStartTimestamp.setTime(
          selectedTimestampAsDate.valueOf() - ThirtyMinutesAsMilliseconds
        );

        archivePlaylistEndTimestamp.setTime(
          selectedTimestampAsDate.valueOf() + ThirtyMinutesAsMilliseconds
        );

        const playerUrl = generateIntervalUrl(
          state.cameraData.MEDIA.HLS.ARCHIVE,
          archivePlaylistStartTimestamp,
          archivePlaylistEndTimestamp
        );

        const currentVideoOffsetInSeconds = Math.floor(
          (currentVideoTimestamp - archivePlaylistStartTimestamp.valueOf()) /
            1000
        );

        return {
          ...state,
          live: false,
          playerUrl,
          currentVideoOffsetInSeconds,
          archivePlaylistStartTimestamp: archivePlaylistStartTimestamp.valueOf(),
          newSelectedVideoOffsetInSeconds: currentVideoOffsetInSeconds,
          timelineInterval,
          currentVideoTimestamp,
          userRequestedTime: currentVideoTimestamp,
        };
      }

      return {
        ...state,
        timelineInterval,
        currentVideoOffsetInSeconds,
        currentVideoTimestamp,
        newSelectedVideoOffsetInSeconds: currentVideoOffsetInSeconds,
        userRequestedTime: currentVideoTimestamp,
      };
    }

    case actionTypes.UPDATE_PROGRESS_BAR: {
      const { currentVideoOffsetInSeconds } = payload;

      if (!state.live && state.userRequestedTime && state.currentPlaylistDurarion && (state.archivePlaylistStartTimestamp - state.userRequestedTime) / 1000 > state.currentPlaylistDurarion) {
        return {
          ...state,
          currentVideoTimestamp: state.userRequestedTime,
        };
      }

      let timelineStartPositionInMs, timelineInterval;

      const currentVideoTimestamp = (state.currentFragmentStartTime ?? 0) + 1000 * (currentVideoOffsetInSeconds - state.currentFragmentStartedAtSeconds);
      // вот тут еще посчитать смещение.

      if (state.live) {
        //@todo добавить костыль-смешение 10c?
        /**
         * если state.archivePlaylistStartTimestamp == undefined т.е. live
         */
        timelineInterval = {
          timelineStartPositionInMs:
            currentVideoTimestamp - FiveMinutesAsMilliseconds,
          timelineEndPositionInMs: currentVideoTimestamp,
        };
      } else {
        /**
         * если пользователь выбрал время, т.е. нe live
         */

        const timelineOffsetInMs =
          state.currentVideoTimestamp -
          state.timelineInterval.timelineStartPositionInMs;

        timelineStartPositionInMs =
          state.archivePlaylistStartTimestamp +
          currentVideoOffsetInSeconds * 1000 -
          timelineOffsetInMs;

        timelineInterval = {
          timelineStartPositionInMs: timelineStartPositionInMs,
          timelineEndPositionInMs: Date.now(),
        };

      }
      return {
        ...state,
        timelineInterval,
        currentVideoOffsetInSeconds,
        currentVideoTimestamp,
      };
    }

    case actionTypes.SET_CAMERA_DATA: {
      const { cameraData } = payload;
      return {
        ...state,
        cameraData,
        cameraName: cameraData.NAME,
        live: true,
        playerUrl: `${cameraData.MEDIA.HLS.LIVE.MAIN}`,
        canDownload: cameraData.ACCESS.DOWNLOAD.STATUS,
      };
    }

    /**
     * Этот редюсер применяется для стандартного перехода на следующий плейлист при
     * окончании предыдущего.
     * Для генерации плейлиста по щелчку используется другой лист.
     */
    case actionTypes.GENERATE_NEXT_PLAYLIST: {
      const needLive = state.currentVideoTimestamp + 10 * 1000 >= Date.now();

      if (needLive) {
        const currentVideoTimestamp = Date.now();
        const timelineInterval = {
          timelineStartPositionInMs:
            currentVideoTimestamp - FiveMinutesAsMilliseconds,
          timelineEndPositionInMs: currentVideoTimestamp,
        };
        return {
          ...state,
          live: true,
          archivePlaylistStartTimestamp: 0,
          timelineInterval,
          currentVideoTimestamp,
          playerUrl: `${state.cameraData.MEDIA.HLS.LIVE.MAIN}`,
        };
      } else {
        const selectedTimestampAsDate = new Date(state.currentVideoTimestamp);

        const archivePlaylistStartTimestamp = new Date();
        const archivePlaylistEndTimestamp = new Date();

        selectedTimestampAsDate.setSeconds(0,0);

        archivePlaylistStartTimestamp.setTime(
          selectedTimestampAsDate.valueOf() - ThirtyMinutesAsMilliseconds
        );
        archivePlaylistEndTimestamp.setTime(
          selectedTimestampAsDate.valueOf() + ThirtyMinutesAsMilliseconds
        );

        const playerUrl = generateIntervalUrl(
          state.cameraData.MEDIA.HLS.ARCHIVE,
          archivePlaylistStartTimestamp,
          archivePlaylistEndTimestamp
        );

        const currentVideoOffsetInSeconds = Math.floor(
          (state.currentVideoTimestamp -
            archivePlaylistStartTimestamp.valueOf()) /
            1000
        );

        const newSelectedVideoOffsetInSeconds = currentVideoOffsetInSeconds;

        const timelineOffsetInMs =
          state.currentVideoTimestamp -
          state.timelineInterval.timelineStartPositionInMs;

        const timelineStartPositionInMs =
          state.currentVideoTimestamp - timelineOffsetInMs;

        const timelineInterval = {
          timelineStartPositionInMs: timelineStartPositionInMs,
          timelineEndPositionInMs: Date.now(),
        };

        return {
          ...state,
          playerUrl,
          archivePlaylistStartTimestamp: archivePlaylistStartTimestamp.valueOf(),
          currentVideoOffsetInSeconds,
          live: false,
          newSelectedVideoOffsetInSeconds,
          timelineInterval,
        };
      }
    }

    case actionTypes.SET_CURRENT_VIDEO_TIMESTAMP_ON_CALENDAR_CHANGE: {
      //Получаем текущее время в миллисекундах(timestamp)
      const { currentVideoTimestamp } = payload;

      //Переводим текущее время в Date object
      const selectedTimestampAsDate = new Date(currentVideoTimestamp);

      //Инициализируем изначальные даннные для предпогруженнного отрезка
      const archivePlaylistStartTimestamp = new Date();
      const archivePlaylistEndTimestamp = new Date();

      //Обнулить секунды у текущего времени
      selectedTimestampAsDate.setSeconds(0,0);

      /**
       * Нужно подгрузить фрагменты для следующих видео
       * 30 минут назад и 30 минут вперед
       */

      archivePlaylistStartTimestamp.setTime(
        selectedTimestampAsDate.valueOf() - ThirtyMinutesAsMilliseconds
      );
      archivePlaylistEndTimestamp.setTime(
        selectedTimestampAsDate.valueOf() + ThirtyMinutesAsMilliseconds
      );

      const playerUrl = generateIntervalUrl(
        state.cameraData.MEDIA.HLS.ARCHIVE,
        archivePlaylistStartTimestamp,
        archivePlaylistEndTimestamp
      );

      const currentVideoOffsetInSeconds = Math.floor(
        (currentVideoTimestamp - archivePlaylistStartTimestamp.valueOf()) / 1000
      );

      const newSelectedVideoOffsetInSeconds = currentVideoOffsetInSeconds;

      const timelineOffsetInMs =
        state.currentVideoTimestamp -
        state.timelineInterval.timelineStartPositionInMs;

      const timelineStartPositionInMs =
        currentVideoTimestamp - timelineOffsetInMs;

      const timelineInterval = {
        timelineStartPositionInMs: timelineStartPositionInMs,
        timelineEndPositionInMs:
          timelineStartPositionInMs + FiveMinutesAsMilliseconds,
      };

      return {
        ...state,
        playerUrl,
        archivePlaylistStartTimestamp: archivePlaylistStartTimestamp.valueOf(),
        currentVideoOffsetInSeconds,
        currentVideoTimestamp,
        live: false,
        newSelectedVideoOffsetInSeconds,
        timelineInterval,
        userRequestedTime: currentVideoTimestamp,
      };
    }

    case actionTypes.SET_CURRENT_VIDEO_TIMESTAMP_ON_CLICK: {
      // Получаем выбранный timestamp, обновляем промежутки timeline
      const { currentVideoTimestamp } = payload;

      const selectedTimestampAsDate = new Date(currentVideoTimestamp);

      const secondsOffsetOfSelectedTimestamp = selectedTimestampAsDate.getSeconds();

      // Если человек жмет на timeline из live  или по какой-то причине не задан archivePlaylistStartTimestamp

      const [
        playerUrl,
        archivePlaylistStartTimestamp,
        currentVideoOffsetInSeconds,
        newSelectedVideoOffsetInSeconds,
      ] =
        state.live
          ? (() => {
              // это если играл live

              const archivePlaylistStartTimestamp = new Date();
              const archivePlaylistEndTimestamp = new Date();

              selectedTimestampAsDate.setSeconds(0,0);

              archivePlaylistStartTimestamp.setTime(
                selectedTimestampAsDate.valueOf() - ThirtyMinutesAsMilliseconds
              );
              archivePlaylistEndTimestamp.setTime(
                selectedTimestampAsDate.valueOf() + ThirtyMinutesAsMilliseconds
              );

              const playerUrl = generateIntervalUrl(
                state.cameraData.MEDIA.HLS.ARCHIVE,
                archivePlaylistStartTimestamp,
                archivePlaylistEndTimestamp
              );

              const currentVideoOffsetInSeconds = Math.floor(
                (currentVideoTimestamp -
                  archivePlaylistStartTimestamp.valueOf()) /
                  1000
              );

              return [
                playerUrl,
                archivePlaylistStartTimestamp.valueOf(),
                currentVideoOffsetInSeconds,
                currentVideoOffsetInSeconds,
              ];
            })()
          : (() => {
              // если уже не live

              // 1) проверяем нужно ли грузить новый фрагмент?
              // Грузим если он оказывается меньше чем начало загрузки плейлиста или больше чем начало плейлиста + длительность загруженного фрагмента.
              const needToUpdatePlaylist =
                state.archivePlaylistStartTimestamp > currentVideoTimestamp ||
                state.archivePlaylistStartTimestamp +
                  HourMinutesAsMilliseconds <
                  currentVideoTimestamp;

              const archivePlaylistStartTimestamp = new Date();
              // по умолчанию выставляем текущий timestamp
              archivePlaylistStartTimestamp.setTime(
                state.archivePlaylistStartTimestamp
              );

              // Смещение для плеера в секундах. Если новый лист - стандартный сдвиг, иначе считаем.

              const currentVideoOffsetInSeconds = needToUpdatePlaylist
                ? 30 * 60 + secondsOffsetOfSelectedTimestamp
                : state.currentVideoOffsetInSeconds + Math.floor(
                    (currentVideoTimestamp -
                      state.currentVideoTimestamp) /
                      1000
                  );

              const playerUrl = needToUpdatePlaylist
                ? (() => {
                    const archivePlaylistEndTimestamp = new Date();

                    selectedTimestampAsDate.setSeconds(0,0);

                    archivePlaylistStartTimestamp.setTime(
                      selectedTimestampAsDate.valueOf() -
                        ThirtyMinutesAsMilliseconds
                    );

                    archivePlaylistEndTimestamp.setTime(
                      selectedTimestampAsDate.valueOf() +
                        ThirtyMinutesAsMilliseconds
                    );

                    return generateIntervalUrl(
                      state.cameraData.MEDIA.HLS.ARCHIVE,
                      archivePlaylistStartTimestamp,
                      archivePlaylistEndTimestamp
                    );
                  })()
                : state.playerUrl;

              return [
                playerUrl,
                archivePlaylistStartTimestamp.valueOf(),
                currentVideoOffsetInSeconds,
                currentVideoOffsetInSeconds,
              ];
            })();
      return {
        ...state,
        playerUrl,
        archivePlaylistStartTimestamp,
        currentVideoOffsetInSeconds,
        currentVideoTimestamp,
        live: false,
        newSelectedVideoOffsetInSeconds,
        userRequestedTime: currentVideoTimestamp,
      };
    }

    case actionTypes.SET_DOWNLOAD_MODE: {
      const { downloadMode } = payload;
      return {
        ...state,
        downloadMode,
      };
    }

    case actionTypes.SET_DOWNLOAD_RANGE: {
      const { downloadRangeArray } = payload;
      return {
        ...state,
        downloadRangeArray,
      };
    }

    case actionTypes.START_DOWNLOAD_VIDEO: {
      return {
        ...state,
        errorMessage: null,
      };
    }
    case actionTypes.VIDEO_DOWNLOAD_FAILED: {
      const { errorMessage } = payload;
      return {
        ...state,
        errorMessage,
      };
    }

    case actionTypes.VIDEO_DOWNLOAD_SUCCESS: {
      const { downloadLink } = payload;
      return {
        ...state,
        errorMessage: null,
        downloadLink,
      };
    }
    case actionTypes.SET_DOWNLOAD_LINK: {
      const { downloadLink } = payload;
      return {
        ...state,
        downloadLink,
      };
    }
    case actionTypes.SET_DOWNLOAD_NAME: {
      const { name } = payload;
      return {
        ...state,
        name,
      };
    }

    case actionTypes.SET_LOADING_PROGRESS: {
      const { loadingProgress } = payload;
      return {
        ...state,
        loadingProgress,
      };
    }

    case actionTypes.SET_TIMELINE_ZOOM: {
      const zoom = payload;
      return {
        ...state,
        timelineZoom: zoom,
      };
    }

    case actionTypes.SET_FS_MODE: {
      const { fsMode } = payload;
      return {
        ...state,
        fsMode,
      };
    }

    case actionTypes.SET_REWIND: {
      return {
        ...state,
        rewind: payload,
      };
    }

    case actionTypes.SET_LAST_ARCHIVE_TIME: {
      const { lastArchiveTime } = payload;
      return {
        ...state,
        lastArchiveTime,
      };
    }
    case actionTypes.SET_CURRENT_FRAGMENT_INFO: {
      const { fragmentStartTime, currentVideoTs,fragmentStartTs, fragmentDuration, videoDuration, fragmentEndTime } = (payload as Parameters<typeof setCurrentFragmentInfoAction>[0]);
      const currentVideoOffsetInSeconds = currentVideoTs - fragmentStartTs;
      let newSelectedVideoOffsetInSeconds = undefined;
      let archivePlaylistStartTimestamp = fragmentStartTime - videoDuration * 1000;
      const currentVideoTimestamp = state.userRequestedTime ?? (fragmentStartTime  + 1000 * currentVideoOffsetInSeconds);
      let playerUrl = state.playerUrl;
      let resetUserSelected = true;
      if (!state.live && state.userRequestedTime){
        const diff = Math.floor(state.userRequestedTime / 1000) - Math.floor(fragmentStartTime / 1000) - currentVideoOffsetInSeconds;
        if (state.userRequestedTime > fragmentEndTime || state.userRequestedTime < fragmentStartTime) {
          resetUserSelected = false;
        }
        if (diff) {
          newSelectedVideoOffsetInSeconds = currentVideoTs + diff;
          if (newSelectedVideoOffsetInSeconds < 0) {
            archivePlaylistStartTimestamp = state.userRequestedTime - ThirtyMinutesAsMilliseconds;
            playerUrl = (() => {
              const selectedTimestampAsDate = new Date(state.userRequestedTime);
              newSelectedVideoOffsetInSeconds = 30 * 60 + selectedTimestampAsDate.getSeconds();

              const archivePlaylistEndTimestamp = new Date();

              selectedTimestampAsDate.setSeconds(0,0);
              const archivePlaylistStartTimestamp1 = new Date(archivePlaylistStartTimestamp);
              archivePlaylistEndTimestamp.setTime(
                selectedTimestampAsDate.valueOf() +
                  ThirtyMinutesAsMilliseconds
              );
              return generateIntervalUrl(
                state.cameraData.MEDIA.HLS.ARCHIVE,
                archivePlaylistStartTimestamp1,
                archivePlaylistEndTimestamp
              );
            })();
          }
        }
      }
      return {
        ...state,
        playerUrl,
        currentFragmentStartTime: fragmentStartTime,
        currentFragmentStartedAtSeconds: fragmentStartTs,
        currentVideoOffsetInSeconds: currentVideoTs,
        currentVideoTimestamp,
        newSelectedVideoOffsetInSeconds,
        userRequestedTime: resetUserSelected ? undefined : state.userRequestedTime,
        archivePlaylistStartTimestamp: state.live ? 0 : archivePlaylistStartTimestamp,
      };
    }

    default: {
      return state;
    }
  }
};
