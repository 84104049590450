import React, { PropsWithChildren, FC } from "react";
import { useDispatch, useSelector } from "react-redux";

import Header from "@containers/Header";
import SideMenu from "@containers/SideMenu";

import Footer from "@views/Footer";

import {
  RootWrapper,
  Main,
  FooterStyled,
  Wrapper,
  SidebarOverlay,
} from "./styled-components";
import {
  selectShowExtendedSidebarStatus,
  setExtendedSidebarStatusAction,
} from "@modules/sidemenu";

const LayoutWithMenu: FC<PropsWithChildren<React.ReactNode>> = ({
  children,
}) => {
  const dispatch = useDispatch();
  const showSidebarStatus = useSelector(selectShowExtendedSidebarStatus);

  const handleSidebarClick = () =>
    dispatch(setExtendedSidebarStatusAction(false));

  return (
    <Wrapper className="wrapper">
      <div className="sidebar-wrapper">
        <SideMenu />
      </div>
      <RootWrapper show={showSidebarStatus} className="root-wrapper">
        <Header />
        <Main>{children}</Main>
        <FooterStyled>
          <Footer />
        </FooterStyled>
      </RootWrapper>
      <SidebarOverlay
        className="sidebar__overlay"
        showOverlay={showSidebarStatus}
        onClick={handleSidebarClick}
      />
    </Wrapper>
  );
};

export default LayoutWithMenu;
