import React, { useState, useEffect } from "react";

import Modal from "../../../UIKit/moleculs/Modal";

import MobileAuthCodeStep from "../../containers/MobileAuthCodeStep";
import MobileAuthAdressesStep from "../../containers/MobileAuthAdressesStep";

import { ModalInner } from "./styled-components";

const MobileAuthCodeModal = (props: MobileAuthCodeModalProps) => {
  const {
    openMobileCodeModal: openMobileCodeModalProps,
    statusModal: statusModalProps,
    currentStep: currentStepProps,
  } = props;

  const [currentStepState, setCurrentStepState] = useState("");
  const [currentTabState, setCurrentTabState] = useState(null);

  const tabs: any = {
    codeStep: {
      component: <MobileAuthCodeStep />,
    },
    addressesStep: {
      component: <MobileAuthAdressesStep />,
    },
  };

  useEffect(() => {
    if (currentStepState !== currentStepProps) {
      setCurrentStepState(currentStepProps);
      setCurrentTabState(tabs[currentStepProps].component);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStepProps, tabs, currentStepState]);

  return (
    <>
      <Modal
        className="mobile-auth-code__modal"
        isOpen={statusModalProps}
        closeable
        ariaHideApp={false}
        onClose={() => {
          openMobileCodeModalProps(false);
        }}
      >
        <ModalInner>{currentStepState && currentTabState}</ModalInner>
      </Modal>
    </>
  );
};

MobileAuthCodeModal.displayName = "MobileAuthCodeModal";

export default MobileAuthCodeModal;
