export const getIdParams = (paramString: string) =>
  paramString
    .replaceAll("[", "")
    .replaceAll("]", "")
    .split(",")
    .map(el => Number(el));

/**
 * Получить параметры для вывода по ссылке
 */
export const getUrlParams = () => {
  const params = new URLSearchParams(document.location.search);
  const idCameras = params.get("idCameras");
  const gridNum = params.get("gridNum");

  return { idCameras, gridNum };
};
