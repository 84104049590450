import { moduleName } from "./module";

const appName = process.env.REACT_APP_NAME;

export const actionTypes = {
  SET_TIME_MULTIPLIER: `${appName}/${moduleName}/SET_TIME_MULTIPLIER`,
};

export const setCarouselTimeMultiplierAction = (multiplier: number) => {
  return {
    type: actionTypes.SET_TIME_MULTIPLIER,
    payload: multiplier,
  };
};
