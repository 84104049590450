import { connect } from "react-redux";

import WarningModal from "../../views/WarningModal";

import { changeDontShowModalAgainAction } from "../../../modules/init";
import { dontShowModalAgainSelector } from "../../../modules/init";

import {
  openWarningModalAction,
  selectWarningModalStatus,
  deleteAllCamerasFlagAction,
  deleteAllCamerasFlagStatus,
} from "../../../modules/WarningModal";

const mapDispatchToProps = (dispatch: any) => ({
  closeWarningModal: (status: boolean) =>
    dispatch(openWarningModalAction(status)),
  deleteAllCamerasFlag: (status: boolean) =>
    dispatch(deleteAllCamerasFlagAction(status)),
  changeDontShowModalAgain: (dontShowModalAgain: boolean) =>
    dispatch(changeDontShowModalAgainAction(dontShowModalAgain)),
});

const mapStateToProps = (state: any) => ({
  openWarningModal: selectWarningModalStatus(state),
  deleteAllCamerasFlag: deleteAllCamerasFlagStatus(state),
  dontShowModalAgain: dontShowModalAgainSelector(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(WarningModal);
