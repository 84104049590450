import { moduleName } from "./module";
import { IOption } from "@views/search/FCamSimpleSearch/types";
import { ISearchSimpleStart } from "@modules/searchSimple/types/sagaTypes";
import {
  IGetOptionsChainElement,
  ISearchOptionsForNextChainElement,
} from "@globalTypes/simpleSearch";

const appName = process.env.REACT_APP_NAME;

export const actionTypes = {
  /* Для обработки поиска в хедере */
  CHANGE_SEARCH_STRING: `${appName}/${moduleName}/CHANGE_SEARCH_STRING`,
  SIMPLE_SEARCH_START: `${appName}/${moduleName}/SIMPLE_SEARCH_START`,
  SEARCH_UPDATE: `${appName}/${moduleName}/SEARCH_UPDATE`,
  SET_SEARCH_MODE: `${appName}/${moduleName}/SET_SEARCH_MODE`,
  GET_INITIAL_OPTIONS_CHAIN_ELEMENT: `${appName}/${moduleName}/GET_INITIAL_OPTIONS_CHAIN_ELEMENT`,
  CHANGE_CURRENT_CHAIN_ELEMENT: `${appName}/${moduleName}/CHANGE_CURRENT_CHAIN_ELEMENT`,
  SEARCH_CURRENT_CHAIN_ELEMENT: `${appName}/${moduleName}/SEARCH_CURRENT_CHAIN_ELEMENT`,
  SET_BUILDING_ID_ELEMENT: `${appName}/${moduleName}/SET_BUILDING_ID_ELEMENT`,
  SET_CURRENT_SELECT_OPTIONS: `${appName}/${moduleName}/SET_CURRENT_SELECT_OPTIONS`,
  GET_BUILDING_ID_ELEMENT: `${appName}/${moduleName}/GET_BUILDING_ID_ELEMENT`,
  GET_MORE_OPTIONS_ON_SCROLL: `${appName}/${moduleName}/GET_MORE_OPTIONS_ON_SCROLL`,
  SET_CURRENT_PAGE: `${appName}/${moduleName}/SET_CURRENT_PAGE`,
  SET_LAST_OPTIONS: `${appName}/${moduleName}/SET_LAST_OPTIONS`,
  RESET_PARAMS_OPTION: `${appName}/${moduleName}/RESET_PARAMS_OPTION`,
  SET_LOADING_SCROLL: `${appName}/${moduleName}/SET_LOADING_SCROLL`,
};

/**
 * Изменение строки поиска
 * @param {string} searchString Строка поиска
 * @param {boolean} searchStart Начать поиск
 */
export const changeSearchStringAction = (
  searchString: string,
  searchStart: boolean = true
) => ({
  type: actionTypes.CHANGE_SEARCH_STRING,
  payload: {
    searchString,
    searchStart,
  },
});

/**
 * Выполнить поиск по элементам
 * @param {string} type
 * @param {string} id
 */
export const searchSimpleStartAction = ({
  type,
  objectId,
}: ISearchSimpleStart) => ({
  type: actionTypes.SIMPLE_SEARCH_START,
  payload: {
    type,
    objectId,
  },
});

/**
 * Получить начальные опции элемент цепи
 */
export const getInitialOptionsChainElementAction = () => ({
  type: actionTypes.GET_INITIAL_OPTIONS_CHAIN_ELEMENT,
});

/**
 * Установить текущие опции select элемента поиска по зданию
 */
export const setCurrentSelectOptionsAction = (data: IOption[]) => ({
  type: actionTypes.SET_CURRENT_SELECT_OPTIONS,
  payload: {
    currentSelectOptionsState: data,
  },
});

/**
 * Сменить текущий элемент цепи
 */
export const changeCurrentChainElementAction = (
  currentChainElementState: IOption
) => ({
  type: actionTypes.CHANGE_CURRENT_CHAIN_ELEMENT,
  payload: {
    currentChainElementState,
  },
});

/**
 * Установить текущий id здания
 */
export const getMoreOptionsOnScrollAction = ({
  value,
  id,
}: IGetOptionsChainElement) => ({
  type: actionTypes.GET_MORE_OPTIONS_ON_SCROLL,
  payload: {
    value,
    id,
  },
});

/**
 * Искать текущий элемент цепи
 */
export const searchCurrentChainElementAction = (
  searchChainElementState: ISearchOptionsForNextChainElement
) => ({
  type: actionTypes.SEARCH_CURRENT_CHAIN_ELEMENT,
  payload: {
    searchChainElementState,
  },
});

export const setCurrentPageAction = (currentPage: number) => ({
  type: actionTypes.SET_CURRENT_PAGE,
  payload: {
    currentPage,
  },
});

export const setLastOptionsAction = (lastOptions: IOption[]) => ({
  type: actionTypes.SET_LAST_OPTIONS,
  payload: {
    lastOptions,
  },
});

export const resetParamsOptionsAction = () => ({
  type: actionTypes.RESET_PARAMS_OPTION,
});

export const setLoadingScrollAction = (statusScroll: boolean) => ({
  type: actionTypes.SET_LOADING_SCROLL,
  payload: {
    statusScroll,
  },
});
