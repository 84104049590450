import React, { useState, useCallback, useEffect } from "react";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";

import {
  HeaderContainer,
  ModalInnnerBody,
  ModalInnerFooter,
  StyledButton,
} from "./styled-components";

import { isAddressStep } from "../../../helpers/mobileAuth";

const MobileAuthAdressesStep = (props: IMobileAuthAdressesStep) => {
  const {
    chooseUserMobileAddress: chooseUserMobileAddressProps,
    userMobileAddressesOptions: userMobileAddressesOptionsProps,
    userMobileAddress: userMobileAddressProps,
    currentStep: currentStepProps,
    statusModal: statusModalProps,
  } = props;

  const [userMobileAddressState, setUserMobileAddressState] = useState<string>(
    userMobileAddressProps
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUserMobileAddressState(event.target.value);
  };

  const handleAuthCode = useCallback(() => {
    chooseUserMobileAddressProps(userMobileAddressState);
  }, [userMobileAddressState, chooseUserMobileAddressProps]);

  /**
   * Обработка нажатия enter
   */
  const handleEnterPressed = useCallback(
    (e: KeyboardEvent) => {
      e.stopPropagation();
      if (e.key === "Enter" && isAddressStep(currentStepProps)) {
        handleAuthCode();
      }
    },
    [handleAuthCode, currentStepProps]
  );

  //Вешаем обработчик для клавиши enter
  useEffect(() => {
    if (isAddressStep(currentStepProps) && statusModalProps) {
      document.addEventListener("keypress", handleEnterPressed);
    }
    return () => document.removeEventListener("keypress", handleEnterPressed);
  }, [statusModalProps, handleEnterPressed, currentStepProps]);

  return (
    <>
      <HeaderContainer className="mobile-auth-code__header-container">
        Выберите адрес
      </HeaderContainer>

      <ModalInnnerBody className="mobile-auth-code__modal-body">
        <FormControl component="fieldset">
          <RadioGroup
            aria-label="gender"
            name="gender1"
            value={userMobileAddressState}
            onChange={handleChange}
          >
            {userMobileAddressesOptionsProps.length &&
              userMobileAddressesOptionsProps.map(
                (el: IUserMobileAddressesOptionsProps, i: number) => (
                  <FormControlLabel
                    key={el.ADDRESS + i}
                    value={el.ADDRESS}
                    control={<Radio />}
                    label={el.ADDRESS}
                  />
                )
              )}
          </RadioGroup>
        </FormControl>
      </ModalInnnerBody>
      <ModalInnerFooter>
        <StyledButton
          className="modal-auth-code__btn"
          height={42}
          disabled={false}
          onClick={() => {
            handleAuthCode();
          }}
        >
          Готово
        </StyledButton>
      </ModalInnerFooter>
    </>
  );
};

MobileAuthAdressesStep.displayName = "MobileAuthAdressesStep";

export default MobileAuthAdressesStep;
