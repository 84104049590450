import { moduleName } from "./module";
import { createSelector } from "reselect";

/**
 * селекторы
 */
export const fListState = state => state[moduleName];

const forwardIdState = (state, { id }) => id;

export const loadingProgressSelector = createSelector(
  [fListState],
  state => state.loadingProgress
);

export const failedLimitedIdsSelector = createSelector(
  [fListState],
  state => state.failedLimitedIds
);

export const loadingLimitedIdsSelector = createSelector(
  [fListState],
  state => state.loadingLimitedIdsForSelect
);

export const camerasSelector = createSelector(
  [fListState],
  state => state.cameras
);

export const selectedObjectsSelector = createSelector(
  [fListState],
  state => state.selectedObjects
);

export const rootIsLoadedSelector = createSelector(
  [fListState],
  state => state.rootIsLoaded
);

export const fullSelectedGroupsSelector = createSelector(
  [fListState],
  state => state.fullSelectedGroups
);

export const searchStringSelector = createSelector(
  [fListState],
  state => state.searchString
);

export const groupsSelector = createSelector(
  [fListState],
  state => state.groups
);

export const isInitSelector = createSelector(
  [fListState],
  state => state.isInit
);

export const groupSelector = createSelector(
  [groupsSelector, forwardIdState],
  (groups, id) => groups.byId[id]
);

export const objectsStateSelector = createSelector(
  [fListState],
  state => state && state.objectsState
);

export const objectStateSelector = createSelector(
  [objectsStateSelector, forwardIdState],
  (objects, id) => objects.byId[id]
);
