export interface updateUsernameType {
  username: string;
}

export interface authStartActionType {
  username: string;
  password: string;
  days: number;
}

export enum AUTH_TYPES {
  LOGIN = "login",
  MOBILE = "mobile",
}
