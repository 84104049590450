import { AUTH_TYPES } from "./types/userActionsTypes";

export const StateSchema = {
  authorized: false,
  authInProgress: false,
  authErrors: "",
  username: "",
  token: undefined,
  frontendIsReadyStatus: false,
  typeAuthStore: AUTH_TYPES.LOGIN,
  mobileAuthCodeStatusStore: false,
  mobileNumber: "",
  uniqueDeviceIdStore: "",
  currentStepStore: "",
  userMobileAddressesOptions: [],
  userMobileAddress: [],
  authId: "",
  mobileAuthCodeRequestInProgress: false,
  mobileAuthCodeRequestTimeoutError: false,
};
