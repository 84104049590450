import React, { useRef, useState, useEffect, useCallback } from "react";

import { notifyError } from "../../../helpers/toast";
import { isCodeStep } from "../../../helpers/mobileAuth";

import {
  HeaderContainer,
  InputStyled,
  ModalInnnerBody,
  ModalInnerFooter,
  StyledButton,
  ModalInnerFooterSpan,
} from "./styled-components";
import { getLastCodeRequestTime } from "../../../helpers/user";

const MobileAuthCodeStep = (props: MobileAuthCodeStepProps) => {
  const {
    currentStep: currentStepProps,
    sendMobileCode: sendMobileCodeProps,
    statusModal: statusModalProps,
    handleRepeatCallMobileAuth: handleRepeatCallMobileAuthProps,
  } = props;

  const timeoutForRepeatCall =
    120 - Math.ceil((Date.now() - getLastCodeRequestTime()) / 1000) > 0
      ? 120 - Math.ceil((Date.now() - getLastCodeRequestTime()) / 1000)
      : 0;

  const [authCodeState, setAuthCodeState] = useState("");
  const [repeatBtnStatusState, setRepeatBtnStatusState] = useState(true);
  const [timeDisableBtnState, setTimeDisableBtnState] = useState(
    timeoutForRepeatCall
  );

  const codeInputRef = useRef<HTMLDivElement>(null);

  const maxCodeSize = 4;

  /**
   * Отправка кода на завершение авторизации
   */
  const handleSendCode = useCallback(() => {
    if (authCodeState.length === maxCodeSize)
      sendMobileCodeProps(authCodeState);
    else notifyError(`Код не может быть меньше ${maxCodeSize} символов`);
  }, [authCodeState, sendMobileCodeProps]);

  /**
   * Обработчик ввода кода
   */
  const repeatCode = () => {
    handleRepeatCallMobileAuthProps();
    setRepeatBtnStatusState(true);
    setTimeDisableBtnState(timeoutForRepeatCall);
  };

  /**
   * Обработчик ввода кода
   * @param val
   */
  const authCodeInputOnChangeHandler = (val: string) => {
    const authCodePattern = new RegExp(/^[0-9]{0,4}$/gm);

    if (authCodePattern.test(val)) {
      setAuthCodeState(val);
    }
  };

  /**
   * Обработка нажатия enter
   */
  const handleEnterPressed = useCallback(
    (e: KeyboardEvent) => {
      e.stopPropagation();
      if (e.key === "Enter" && isCodeStep(currentStepProps)) {
        handleSendCode();
      }
    },
    [handleSendCode, currentStepProps]
  );

  //Вешаем обработчик для клавиши enter
  useEffect(() => {
    if (isCodeStep(currentStepProps) && statusModalProps) {
      document.addEventListener("keypress", handleEnterPressed);
    }
    return () => document.removeEventListener("keypress", handleEnterPressed);
  }, [statusModalProps, handleEnterPressed, currentStepProps]);

  //Вешаем обработчик для клавиши enter
  useEffect(() => {
    let timeout: number;
    if (
      isCodeStep(currentStepProps) &&
      statusModalProps &&
      timeDisableBtnState > 0 &&
      codeInputRef.current
    ) {
      //@ts-ignore
      timeout = setTimeout(
        () => setTimeDisableBtnState(timeDisableBtnState - 1),
        1000
      );
    }
    return () => clearTimeout(timeout);
  }, [currentStepProps, statusModalProps, timeDisableBtnState]);

  //Вешаем обработчик для клавиши enter
  useEffect(() => {
    if (timeDisableBtnState === 0) {
      setRepeatBtnStatusState(false);
    }
  }, [timeDisableBtnState, setRepeatBtnStatusState]);

  return (
    <>
      <HeaderContainer className="mobile-auth-code__header-container">
        Введите код из SMS 4 цифры
      </HeaderContainer>

      <ModalInnnerBody className="mobile-auth-code__modal-body">
        <InputStyled
          ref={codeInputRef}
          className="mobile-auth-code__input"
          type="tel"
          aria-label="mobile-auth-code-text"
          placeholder="Введите код"
          autoFocus
          value={authCodeState}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            authCodeInputOnChangeHandler(e.target.value)
          }
        />
      </ModalInnnerBody>
      <ModalInnerFooter>
        <StyledButton
          className="modal-auth-code__btn"
          height={42}
          disabled={false}
          onClick={handleSendCode}
        >
          Отправить код
        </StyledButton>
        <StyledButton
          className="repeat-code__btn"
          height={42}
          disabled={repeatBtnStatusState}
          onClick={repeatCode}
        >
          Повторить отправку SMS
        </StyledButton>
        {timeDisableBtnState > 0 && (
          <ModalInnerFooterSpan>
            Повторить отправку SMS можно будет через {timeDisableBtnState}{" "}
            секунд
          </ModalInnerFooterSpan>
        )}
      </ModalInnerFooter>
    </>
  );
};

MobileAuthCodeStep.displayName = "MobileAuthCodeStep";

export default MobileAuthCodeStep;
