import { actionTypes, StateSchema } from "./";
import { NewFlistSearchSchema } from "./types/schemaTypes";

export default (
  state: NewFlistSearchSchema = { ...StateSchema },
  action: Action
) => {
  const { type, payload } = action;

  switch (type) {
    case actionTypes.CHANGE_SEARCH_STRING: {
      const { searchString } = payload;

      return {
        ...state,
        searchString,
      };
    }

    case actionTypes.SET_CURRENT_SELECT_OPTIONS:
      return {
        ...state,
        currentSelectOptionsState: payload.currentSelectOptionsState,
      };

    case actionTypes.SET_CURRENT_PAGE:
      const { currentPage } = payload;

      return {
        ...state,
        currentPage,
      };

    case actionTypes.SET_LAST_OPTIONS:
      const { lastOptions } = payload;

      return {
        ...state,
        lastOptions,
      };
    case actionTypes.SET_LOADING_SCROLL:
      const { statusScroll } = payload;

      return {
        ...state,
        statusScroll,
      };

    default:
      return state;
  }
};
