import React from "react";
//@ts-ignore
import classNames from "classnames";

import {
  SidebarWrapper,
  SidebarIconWrapper,
  SidebarInner,
  SidebarInnerTop,
  SidebarInnerSection,
  StyledHamburgerMenuIcon,
  StyledStreetsOnlineIcon,
  SidebarInnerExtended,
  StyledLogoIcon,
  SidebarIconWrapperExtended,
  SidebarInnerSectionExtended,
  SidebarNameIconExtended,
  StyledArrowDownIcon,
  SubMenuSidebarWrapper,
  SubMenuElement,
  SubMenuLink,
  SidebarInnerTopExtended,
  StyledHamburgerMenuIconExtended,
} from "./styled-components";

import { ymSendAnalytics, yandexEvents } from "@helpers/yandex-analytics";

const SideMenu = ({
  optionsForStreetsMenu: optionsForStreetsMenuProps,
  chooseCity: chooseCityProps,
  showExtendedSidebarStatus: showExtendedSidebarStatusProps,
  setExtendedSidebarStatus: setExtendedSidebarStatusProps,
  showExtendedSubMenuStreetsStatus: showExtendedSubMenuStreetsStatusProps,
  setSubmenuStreetsStatus: setSubmenuStreetsStatusProps,
}: SideMenuProps) => {
  const availableTypes: Record<string, showSubMenuHandlerType> = {
    streets: "streets",
    customGroups: "customGroups",
  };

  /**
   * Обработчик раскрывания/закрытия бокового меню
   */
  const showExtendedMenuHandler = () => {
    ymSendAnalytics(yandexEvents.clickOpenIconSidemenu);
    setExtendedSidebarStatusProps(!showExtendedSidebarStatusProps);
  };

  /**
   * Обработчик раскрывания подменю
   */
  const showSubMenuHandler = (type: showSubMenuHandlerType) => {
    setExtendedSidebarStatusProps(true);
    if (type === availableTypes.streets) {
      setSubmenuStreetsStatusProps(true);
    }
  };

  /**
   * Обработчик для streets online в нераскрытом меню
   */
  const showSubMenuStreetsOnlineHandler = () => {
    ymSendAnalytics(yandexEvents.clickSidemenuStreetsOpenIcon);
    setSubmenuStreetsStatusProps(!showExtendedSubMenuStreetsStatusProps);
  };

  /**
   * Обработчик нажатия по скрытой иконке улицы онлайн
   */
  const streetsIconSidemenuHideHandler = () => {
    ymSendAnalytics(yandexEvents.clickStreetsIconSidemenu);
    showSubMenuHandler(availableTypes.streets);
  };

  /**
   * Обработчик нажатия по городу в боковом меню в улицах онлайн
   */
  const chooseCityHandler = (id: number, name: string) => {
    ymSendAnalytics(yandexEvents.clickSidemenuStreetsChooseCity);
    chooseCityProps(id, name);
  };

  return (
    <>
      {showExtendedSidebarStatusProps ? (
        <>
          <SidebarWrapper className="sidebar__wrapper sidebar--opened">
            <SidebarInnerExtended className="sidebar__inner">
              <SidebarInnerTopExtended className="sidebar-inner__top">
                <SidebarIconWrapper
                  className="sidebar__icon-wrapper"
                  onClick={() => showExtendedMenuHandler()}
                >
                  <StyledHamburgerMenuIconExtended />
                </SidebarIconWrapper>
                <StyledLogoIcon />
              </SidebarInnerTopExtended>
              <SidebarInnerSectionExtended className="sidebar-inner__section">
                <SidebarIconWrapperExtended
                  className="sidebar__icon-wrapper"
                  onClick={() => showSubMenuStreetsOnlineHandler()}
                >
                  <StyledStreetsOnlineIcon />
                  <SidebarNameIconExtended>
                    Улицы Онлайн
                  </SidebarNameIconExtended>
                  <StyledArrowDownIcon
                    opened={showExtendedSubMenuStreetsStatusProps.toString()}
                  />
                </SidebarIconWrapperExtended>
                <SubMenuSidebarWrapper
                  className={classNames({
                    "sub-menu__wrapper": true,
                    opened: showExtendedSubMenuStreetsStatusProps === true,
                  })}
                >
                  {optionsForStreetsMenuProps.map((el: any, index: number) => {
                    return (
                      <React.Fragment key={index}>
                        <SubMenuElement className="sub-menu__element">
                          <SubMenuLink
                            key={el.id + index}
                            className="sub-menu__link"
                            onClick={() => chooseCityHandler(+el.id, el.name)}
                          >
                            {el.name}
                          </SubMenuLink>
                        </SubMenuElement>
                      </React.Fragment>
                    );
                  })}
                </SubMenuSidebarWrapper>
              </SidebarInnerSectionExtended>
            </SidebarInnerExtended>
          </SidebarWrapper>
        </>
      ) : (
        <SidebarWrapper className="sidebar__wrapper sidebar--closed">
          <SidebarInner className="sidebar__inner">
            <SidebarInnerTop className="sidebar-inner__top">
              <SidebarIconWrapper
                className="sidebar__icon-wrapper"
                onClick={() => showExtendedMenuHandler()}
              >
                <StyledHamburgerMenuIcon />
              </SidebarIconWrapper>
            </SidebarInnerTop>
            <SidebarInnerSection className="sidebar-inner__section">
              <SidebarIconWrapper className="sidebar__icon-wrapper">
                <StyledStreetsOnlineIcon
                  onClick={() => streetsIconSidemenuHideHandler()}
                />
              </SidebarIconWrapper>
              <SidebarIconWrapper className="sidebar__icon-wrapper"></SidebarIconWrapper>
            </SidebarInnerSection>
          </SidebarInner>
        </SidebarWrapper>
      )}
    </>
  );
};

SideMenu.displayName = "SideMenu";
export default SideMenu;
