import { actionTypes, StateSchema } from "./";
import {CameraEventsSettingsActionPayload, CameraEventsSettingsSchema} from "./types";

export default (
  state: CameraEventsSettingsSchema = { ...StateSchema },
  action: {type: keyof typeof actionTypes, payload: CameraEventsSettingsActionPayload}
): CameraEventsSettingsSchema => {
  const { type, payload } = action;
  switch (type) {
    case actionTypes.SET_VALUE: {
      const { newState } = payload;
      if (!newState) {
        throw new Error();
      }
      console.log('newState ', newState);
      return {
        eventTypes: newState.eventTypes.slice(),
        disabledCameraIds: newState.disabledCameraIds.slice()
      };
    }
    default:
      return state;
  }
};
