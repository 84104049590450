import localStorageKeys from "./localStorageKeys";
// import LocalStorage from './LocalStorage';
import IStorage from "./IStorage";
import sendErrorToSentry from "../sentry";

import { checkIsObjEmpty, validateStrForLs } from "../utilsFunc";

class LocalStorage extends IStorage {
  /**
   * Получить  ключ из LS
   * @param  {string} key
   */

  get(key) {
    try {
      const res = localStorage.getItem(key);
      if (res) {
        return JSON.parse(res);
      }
      return res;
    } catch (error) {
      //Очистить ключ в случае неудачи
      localStorage.setItem(key, "");
      sendErrorToSentry(`Ошибка получения ключа ${error}`);
    }
  }
  /**
   * Установить новый ключ в LS
   * @param  {string} key
   * @param  {mixed} data
   */
  set(key, data) {
    try {
      localStorage.setItem(key, JSON.stringify(data));
    } catch (e) {
      sendErrorToSentry(`Error set data ${key} into storage ${e}`);
    }
  }
  /**
   * Удалить ключ
   * @param  {string} key
   */
  remove(key) {
    localStorage.removeItem(key);
  }
  /**
   *
   * Очистить весь localStorage с того сайта где вы находитесь
   */
  clear() {
    localStorage.clear();
  }
}

//Валидирование LS данных
class CheckLocalStorage extends LocalStorage {
  /**
   * Проверить ключ LS SELECTED_CAM_IDS
   */
  checkSelectedCamIdsKey() {
    const selectedCamIdsKeyLs = super.get(localStorageKeys.SELECTED_CAM_IDS);

    //Проверяем что это массив
    if (!Array.isArray(selectedCamIdsKeyLs)) {
      return false;
    }

    /*
			Проверяем что каждый объект имеет структуру типа "groupId_ParentObjectId"
			если хотя бы у одного объект нет этой структуры то структура неверна вся
		*/
    //const regExpToMatch = new RegExp('^[0-9]{1,10}_[0-9]{1,10}$');
    for (let i = 0; i < selectedCamIdsKeyLs.length - 1; i++) {
      if (validateStrForLs(selectedCamIdsKeyLs[i])) {
        return false;
      }
    }

    return true;
  }

  /**
   * Проверить ключ LS SELECTED_GROUP
   */
  checkSelectedGroupKey() {
    const selectedGroupLs = super.get(localStorageKeys.SELECTED_GROUP);

    //Сначала проверяем что объект не пустой
    if (checkIsObjEmpty(selectedGroupLs)) {
      return false;
    }

    //если нет ключей ID и NAME в объекте то LS неверен
    if (selectedGroupLs.ID && selectedGroupLs.NAME) {
      return false;
    }

    //если ключи пустые то LS неверен
    if (selectedGroupLs["ID"] === "" && selectedGroupLs["NAME"] === "") {
      return false;
    }

    return true;
  }

  /**
   * Проверить ключ LS ALL_SELECTED_GROUP
   */
  checkAllSelectedGroupKey() {
    const allSelectedGroupLs = super.get(localStorageKeys.ALL_SELECTED_GROUPS);

    //Сначала проверяем что объект не пустой
    if (checkIsObjEmpty(allSelectedGroupLs)) {
      return false;
    }

    for (let i = 0; i < allSelectedGroupLs.length - 1; i++) {
      if (!this.checkAllSelectedGroupKey(allSelectedGroupLs[i])) {
        return false;
      }
    }

    return true;
  }

  /**
   * Проверить ключ LS ACTIVE_PARENT_ID
   */
  checkActiveParentIdKey() {
    const activeParentIdLs = super.get(localStorageKeys.ACTIVE_PARENT_ID);
    if (!validateStrForLs(activeParentIdLs)) {
      return false;
    }
    return true;
  }

  /**
   * Проверить все необходимые нам ключи на валидность
   */
  checkAllLsKeys() {
    //все проверю складываем в массив проверок
    let arrOfChecks = [];
    arrOfChecks.push(this.checkSelectedCamIdsKey());
    arrOfChecks.push(this.checkSelectedGroupKey());
    arrOfChecks.push(this.checkAllSelectedGroupKey());
    arrOfChecks.push(this.checkActiveParentIdKey());
    arrOfChecks.forEach(el => {
      if (el === false) {
        return false;
      }
    });

    return true;
  }
}

export default new CheckLocalStorage();
