import styled from "styled-components";

import { ReactComponent as ScrollTopIcon } from "../../../../assets/svgs/streetsOnline/RawSvg/scroll-top-icon.svg";
import { ReactComponent as ScrollBottomIcon } from "../../../../assets/svgs/streetsOnline/RawSvg/scroll-bottom-icon.svg";

export const Button = styled.div<any>`
  position: fixed;
  z-index: 1;
  bottom: 40px;
  left: 5px;
  display: ${(props: any) => (props.visible ? "flex" : "none")};
  justify-content: center;
  align-items: center;
  width: 76px;
  height: 60px;
  cursor: pointer;
  color: white;
  opacity: ${(props: any) => (props.visible ? 1 : 0)};
  transition: all 0.3s ease-out, -webkit-all 0.3s ease-out;
`;

export const ScrollTopIconStyled = styled(ScrollTopIcon)`
  width: 20px;
  height: 20px;
`;

export const ScrollBottomIconStyled = styled(ScrollBottomIcon)`
  width: 20px;
  height: 20px;
`;
